/* eslint-disable no-console */
import constate from 'constate';
import { useCallback, useState } from 'react';

import { ISignInProps, useAuthSession, useSignIn, useSignOut } from './apis';

export function useAuthState() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { mutateAsync: signInMutation } = useSignIn();
  const { mutateAsync: signOutMutation } = useSignOut();

  const { isLoading: sessionIsInitializing } = useAuthSession({
    retry: false,
    onSuccess: () => {
      setIsAuthenticated(true);
    },
    onError: () => {
      // TODO: Clear auth cookies if there was an error authenticating the session
      setIsAuthenticated(false);
    },
  });

  const signIn = useCallback(
    async (props: ISignInProps) => {
      const { email, password } = props;
      try {
        await signInMutation({ email, password });
        setIsAuthenticated(true);
      } catch (error) {
        // Could not sign in for some reason
        setIsAuthenticated(false);
        throw error;
      }
    },
    [signInMutation]
  );

  const signOut = useCallback(async () => {
    await signOutMutation();
    setIsAuthenticated(false);
  }, [signOutMutation]);

  return {
    isAuthenticated,
    sessionIsInitializing,
    signIn,
    signOut,
  };
}

export const [AuthProvider, useAuthContext] = constate(useAuthState);
