import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';

import { CURRENT_STEP_INDEX, CURRENT_STEP_STATUS } from './types';

enum BILL_STEPS {
  INTRODUCED = 'Introduced',
  SENATE = 'Senate',
  ASSEMBLY = 'Assembly',
  GOVERNER = 'Governor',
}

interface IProps {
  billName: string;
  progress: [CURRENT_STEP_INDEX, CURRENT_STEP_STATUS];
}

// TODO Add proper styling to the progress stepper
export const ProgressStepper = (props: IProps) => {
  const { progress, billName } = props;
  let steps = [BILL_STEPS.INTRODUCED, BILL_STEPS.SENATE, BILL_STEPS.ASSEMBLY, BILL_STEPS.GOVERNER];

  if (billName.startsWith('AB')) {
    // Overwrite column order if is AB bill
    steps = [BILL_STEPS.INTRODUCED, BILL_STEPS.ASSEMBLY, BILL_STEPS.SENATE, BILL_STEPS.GOVERNER];
  }

  const [activeStepIndex, currentStepStatus] = progress;

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStepIndex} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { error?: boolean; sx?: Record<string, any> } = {};

          const stepIsCompleted = currentStepStatus === 1;
          const stepHasError = currentStepStatus < 0;

          if (activeStepIndex === index && stepIsCompleted) {
            stepProps.completed = true;
          }

          if (activeStepIndex === index && stepHasError) {
            labelProps.error = true;
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <Typography>{label}</Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
