import NorthEastIcon from '@mui/icons-material/NorthEast';
import { Box, Link, useTheme } from '@mui/material';

interface IProps {
  link: string;
  displayText: string;
}

export const StyledLinkBox = (props: IProps) => {
  const theme = useTheme();
  const { link, displayText } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 16px',
        borderRadius: '6px',
        background: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`,
        color: 'black',
        textDecoration: 'none',
        maxWidth: 'fit-content', // Add maxWidth here
      }}
    >
      <Link
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ color: 'inherit', textDecoration: 'none', marginRight: 2 }}
      >
        {displayText}
      </Link>
      <NorthEastIcon />
    </Box>
  );
};
