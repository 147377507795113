import { truncate } from 'lodash';

import {
  ActionGroup,
  AnalysesGroup,
  IActionData,
  IAnalysesData,
  IVersionData,
  IVoteData,
  VersionGroup,
  VoteGroup,
} from './types';

interface IGetTimelineProps {
  versionIsChecked: boolean;
  voteIsChecked: boolean;
  actionIsChecked: boolean;
  analysesIsChecked: boolean;
  versions: IVersionData[];
  votes: IVoteData[];
  actions: IActionData[];
  analyses: IAnalysesData[];
}

const CONCAT_LIMIT_CHARS = 35;

const BACKGROUND_COLORS = {
  ACTION: '#5a2544',
  VOTE: '#1aa412',
  INTRO: '#1aa412',
  VERSION: '#EECC87',
  ANALYSIS: '#FFA500',
};

const TEXT_COLORS = {
  ACTION: 'white',
  VOTE: 'white',
  INTRO: 'white',
  VERSION: '#5a2544',
  ANALYSIS: 'black',
};

/**
 *
 * Builds the object required to display the timeline component
 */
export const getTimelineItems = (props: IGetTimelineProps) => {
  const {
    versionIsChecked,
    voteIsChecked,
    actionIsChecked,
    analysesIsChecked,
    versions,
    votes,
    actions,
    analyses,
  } = props;
  const groups = [];
  const timelineItems = [];

  if (versionIsChecked) {
    groups.push({
      id: VersionGroup.ID,
      content: 'Versions',
      value: 1,
      style: 'font-size: 15px; font-weight: bold;',
    });
    const versionItems = versions.map((version) => {
      const backgroundColor = version.title.includes('Intro')
        ? BACKGROUND_COLORS.INTRO
        : BACKGROUND_COLORS.VERSION;
      const textColor = version.title.includes('Intro') ? TEXT_COLORS.INTRO : TEXT_COLORS.VERSION;
      return {
        id: version.id,
        group: VersionGroup.ID,
        content: version.title,
        start: version.date,
        style: `background-color: ${backgroundColor}; color: ${textColor}; border-radius: 16px; font-size: 14px;`, // Apply mapped colors
      };
    });
    timelineItems.push(...versionItems);
  }

  if (voteIsChecked) {
    groups.push({
      id: VoteGroup.ID,
      content: 'Votes',
      value: 2,
      style: 'font-size: 15px; font-weight: bold;',
    });
    const voteItems = votes.map((vote, index) => {
      const backgroundColor = vote.result === -1 ? 'red' : BACKGROUND_COLORS.VOTE;
      const textColor = TEXT_COLORS.VOTE;
      const resultString = vote.result === -1 ? 'FAIL' : 'PASS';
      return {
        id: vote.vote_id,
        group: VoteGroup.ID,
        content: `${resultString}, ${vote.location}`,
        start: vote.date,
        style: `background-color: ${backgroundColor}; color: ${textColor}; border-radius: 16px; font-size: 14px;`, // Apply mapped colors
      };
    });
    timelineItems.push(...voteItems);
  }

  if (actionIsChecked) {
    groups.push({
      id: ActionGroup.ID,
      content: 'Actions',
      value: 3,
      style: 'font-size: 15px; font-weight: bold;',
    });
    const actionItems = actions.map((action, index) => {
      const backgroundColor = BACKGROUND_COLORS.ACTION;
      const textColor = TEXT_COLORS.ACTION;
      const desc = action.desc || '';

      const content = truncate(desc, { length: CONCAT_LIMIT_CHARS });

      return {
        id: action.id,
        group: ActionGroup.ID,
        content: `<div style="font-size:12px">${content}</div>`,
        start: action.date,
        style: `background-color: ${backgroundColor}; color: ${textColor}; border-radius: 16px; font-size: 14px;`, // Apply mapped colors
      };
    });
    timelineItems.push(...actionItems);
  }

  if (analysesIsChecked) {
    groups.push({
      id: AnalysesGroup.ID,
      content: 'Analyses',
      value: 3,
      style: 'font-size: 15px; font-weight: bold;',
    });
    const analysesItems = analyses.map((analysis) => {
      const backgroundColor = BACKGROUND_COLORS.ANALYSIS;
      const textColor = TEXT_COLORS.ANALYSIS;
      const title = analysis.meta_data.title || '';

      const content = truncate(title, { length: CONCAT_LIMIT_CHARS });

      return {
        id: analysis.id,
        group: AnalysesGroup.ID,
        content: `<div style="font-size:12px">${content}</div>`,
        start: analysis.date,
        style: `background-color: ${backgroundColor}; color: ${textColor}; border-radius: 16px; font-size: 14px;`, // Apply mapped colors
      };
    });

    timelineItems.push(...analysesItems);
  }
  return {
    groups,
    timelineItems,
  };
};
