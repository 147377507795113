/* eslint-disable no-console */
import { useMutation, useQuery } from 'react-query';

import { ApiError, IChatRequestData, IChatResponseData } from './types'; // Adjust the import path as necessary
import { apiClient } from '../../lib/apiClient'; // Ensure this exists and is correctly implemented

export const useChatResponse = () => {
  return useMutation(async (payload: IChatRequestData) => {
    return apiClient.post('/api/v1/get_chat_response', { json: payload });
  });
};
