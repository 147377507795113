/* eslint-disable no-console */
import { Box, Button, Card, CircularProgress, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalize } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useProceedingsByProcNumberQuery } from './apis';
import { ProceedingSummaryPanel } from './ProceedingSummaryPanel';
import { ProceedingTimeline } from './ProceedingTimeline';
import { ProgressStepper } from './ProgressStepper';
import { formatDate } from './utils';
import { BookmarkIcon } from '../bookmark/BookmarkIcon';
import { ShowMoreHTML } from '../common/ShowMoreHTML';
import { StyledLinkBox } from '../common/StyledLinkBox';

export const ProceedingPage = () => {
  const theme = useTheme();
  const { proceedingNumber } = useParams();
  const { data, isLoading, error } = useProceedingsByProcNumberQuery(proceedingNumber);
  const [detailsAreHidden, setDetailsAreHidden] = useState(false);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography>There was an error loading proceeding: {proceedingNumber}</Typography>;
  }

  if (!data) {
    return <Typography>No data available for proceeding: {proceedingNumber}</Typography>;
  }

  const {
    details,
    number,
    headline,
    purpose,
    latest_filing,
    progress_step_index: progressStepIndex,
    decisions,
    rulings,
    comments,
    scopings,
    others,
  } = data;

  const {
    service_lists_link: serviceListsLink,
    current_status: currentStatus,
    filing_date: filingDate,
    description,
    staff,
    category,
    link,
  } = details;

  const { date: latestDate, doc_type: latestDocType, summaries: latestSummaries } = latest_filing;
  const formattedLatesetFilingDate = formatDate(latestDate);
  const formattedLatestDocType = capitalize(latestDocType);

  return (
    <Grid container spacing={3}>
      <Grid item sm={8} xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{ color: theme.palette.primary.main, fontFamily: 'sans-serif' }}
            variant="body1"
          >
            Proceedings &gt; CA &gt;{' '}
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
              {proceedingNumber}
            </Typography>
          </Typography>
          <BookmarkIcon proceedingNumber={proceedingNumber} iconStyles={{ fontSize: '48px' }} />
        </Box>
        <Box sx={{ height: 2 }} />
        <Typography
          sx={{ color: theme.palette.primary.main, fontFamily: 'serif', lineHeight: '1.56' }}
          variant="h5"
        >
          {headline}
        </Typography>
        <Box sx={{ height: 24 }} />
        <ProgressStepper progressStepIndex={progressStepIndex} procType={number[0]} />
        <Box sx={{ height: 24 }} />
        {purpose && (
          <Card>
            <Typography fontWeight="bold" variant="h6">
              Purpose
            </Typography>
            <Box sx={{ height: 12 }} />
            <Typography variant="body1">{purpose}</Typography>
          </Card>
        )}
        <Box sx={{ height: 24 }} />
        {latestSummaries && (
          <Card>
            <Typography fontWeight="bold" variant="h6">
              Summary of Latest CPUC Filing: {formattedLatestDocType}
            </Typography>
            <Box sx={{ height: 12 }} />
            {latestSummaries.long ? (
              <ProceedingSummaryPanel
                shortSummary={latestSummaries.short}
                longSummary={latestSummaries.long}
              />
            ) : (
              <Card>
                <Box sx={{ height: 12 }} />
                <ShowMoreHTML html={latestSummaries.short} maxLength={2200} replaceNewLine />
              </Card>
            )}
          </Card>
        )}
      </Grid>

      <Grid item sm={4} xs={12}>
        <Card>
          <Typography fontWeight="bold" variant="h6">
            Details{' '}
            <Button
              onClick={() => setDetailsAreHidden(!detailsAreHidden)}
              variant="text"
              disableRipple
              sx={{
                background: 'none',
                '&:hover': {
                  textDecoration: 'underline', // Underline on hover
                  backgroundColor: 'transparent', // Transparent background on hover
                },
              }}
            >
              {detailsAreHidden ? 'Show details' : 'Hide Details'}
            </Button>
          </Typography>
          <Box sx={{ height: 12 }} />
          <Typography fontWeight="bold" variant="body2">
            Current Status
          </Typography>
          <Box sx={{ height: 4 }} />
          <Typography variant="body2">{currentStatus}</Typography>
          {!detailsAreHidden && (
            <>
              <Box sx={{ height: 12 }} />
              <Typography fontWeight="bold" variant="body2">
                Filing Date
              </Typography>
              <Box sx={{ height: 4 }} />
              <Typography variant="body2">{filingDate}</Typography>
              <Box sx={{ height: 12 }} />
              <Typography fontWeight="bold" variant="body2">
                Category
              </Typography>
              <Box sx={{ height: 4 }} />
              <Typography variant="body2">{category}</Typography>
              <Box sx={{ height: 12 }} />
              <Typography fontWeight="bold" variant="body2">
                Service Lists
              </Typography>
              <Box sx={{ height: 4 }} />
              <Link href={serviceListsLink} target="_blank" rel="noopener noreferrer">
                Explore
              </Link>
              <Box sx={{ height: 12 }} />
              <Typography fontWeight="bold" variant="body2">
                Staff
              </Typography>
              <Box sx={{ height: 4 }} />
              <Typography variant="body2">{staff}</Typography>
              <Box sx={{ height: 12 }} />
              <Typography fontWeight="bold" variant="body2">
                Description
              </Typography>
              <Box sx={{ height: 4 }} />
              <Typography variant="body2">{description}</Typography>
              <Box sx={{ height: 12 }} />
              <StyledLinkBox link={link} displayText="apps.cpuc.ca.gov" />
            </>
          )}
        </Card>
        <Box sx={{ height: 24 }} />
        <Card>
          <Typography fontWeight="bold" variant="h6">
            Latest CPUC Filing
          </Typography>
          <Box sx={{ height: 12 }} />
          <Typography fontWeight="bold" variant="body2">
            Date
          </Typography>
          <Box sx={{ height: 4 }} />
          <Typography variant="body2">{formattedLatesetFilingDate}</Typography>
          <Box sx={{ height: 12 }} />
          <Typography fontWeight="bold" variant="body2">
            Type
          </Typography>
          <Box sx={{ height: 4 }} />
          <Typography variant="body2">{formattedLatestDocType}</Typography>
        </Card>
        <Box sx={{ height: 24 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{ fontWeight: 'bold', color: theme.palette.primary.main, lineHeight: '1.56' }}
          variant="h5"
        >
          History
        </Typography>
        <Box sx={{ height: 12 }} />
        <ProceedingTimeline
          decisions={decisions}
          rulings={rulings}
          comments={comments}
          scopings={scopings}
          others={others}
        />
        <Box sx={{ height: 48 }} />
      </Grid>
    </Grid>
  );
};
