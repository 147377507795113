import { Badge, Box, Chip, Typography } from '@mui/material';

interface IProps {
  isNew: boolean;
  versionsCount: number;
  votesCount: number;
  actionsCount: number;
  newVersionsCount: number;
  newVotesCount: number;
  newActionsCount: number;
}

export const BillCardCounterRow = (props: IProps) => {
  const {
    isNew,
    versionsCount,
    votesCount,
    actionsCount,
    newVersionsCount,
    newVotesCount,
    newActionsCount,
  } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {isNew ? (
        <Chip
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              NEW
            </Typography>
          }
          sx={{ borderRadius: '20%', mx: 2, color: 'primary', backgroundColor: '#EECC87' }}
        />
      ) : (
        <Typography
          sx={{
            mx: 2,
            color: 'black',
            fontSize: 'small',
            fontWeight: 'bold',
          }}
        >
          No Updates
        </Typography>
      )}
      <Box sx={{ display: 'flex', alignItems: 'center', mx: 3 }} />
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
        <Badge badgeContent={newVersionsCount} color="secondary">
          <Typography
            sx={{ marginRight: 0, paddingRight: 0.5, fontSize: 'small', fontWeight: 'bold' }}
          >
            Versions
          </Typography>
        </Badge>
        <Chip
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {versionsCount}
            </Typography>
          }
          color="primary"
          sx={{ borderRadius: '20%', marginLeft: 1.5 }}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
        <Badge badgeContent={newVotesCount} color="secondary">
          <Typography
            sx={{ marginRight: 0, paddingRight: 0.5, fontSize: 'small', fontWeight: 'bold' }}
          >
            Votes
          </Typography>
        </Badge>
        <Chip
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {votesCount}
            </Typography>
          }
          color="primary"
          sx={{ borderRadius: '20%', marginLeft: 1.5 }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Badge badgeContent={newActionsCount} color="secondary">
          <Typography
            sx={{ marginRight: 0, paddingRight: 0.5, fontSize: 'small', fontWeight: 'bold' }}
          >
            Actions
          </Typography>
        </Badge>
        <Chip
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {actionsCount}
            </Typography>
          }
          color="primary"
          sx={{ borderRadius: '20%', marginLeft: 1.5 }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }} />
    </Box>
  );
};
