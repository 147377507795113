import { Grid } from '@mui/material';

import { ProceedingList } from './ProceedingList';
import { ProceedingSearchPanel } from './ProceedingSearchPanel';

export const ProceedingSearchPage = () => {
  return (
    <Grid container spacing={4}>
      <Grid item sm={4}>
        <ProceedingSearchPanel />
      </Grid>

      <Grid item sm={8}>
        <ProceedingList />
      </Grid>
    </Grid>
  );
};
