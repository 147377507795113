import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { truncate } from 'lodash';
import React, { useState } from 'react';

import { ProcAttachmentAccordion } from './ProcAttachmentAccordion';
import { ProceedingSummaryPanel } from './ProceedingSummaryPanel';
import { SubmittedDocNotice } from './SubmittedDocNotice';
import { ICommentData, ICommenterAuthorInfo } from './types';
import { SanitizedHTML } from '../common/SanitizedHTML';

const CONCAT_LIMIT_CHARS = 50;

interface IProps {
  commentId: number;
  date: string;
  summary: string;
  filed_by: string;
  description: string;
  link: string;
  authors: ICommenterAuthorInfo[];
  long?: string;
  medium?: string;
  short?: string;
  expanded?: boolean;
  isSubmitted?: boolean;
  attachments?: ICommentData[];
}
const AuthorDetailsTable = ({ author }: { author: ICommenterAuthorInfo }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>Role</TableCell>
          <TableCell>{author.role}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Organization</TableCell>
          <TableCell>{author.organization}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>{author.email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Phone</TableCell>
          <TableCell>{author.phone}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Address</TableCell>
          <TableCell>{author.address}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);
export const ProcCommentItem = (props: IProps) => {
  const {
    commentId,
    date,
    summary = '',
    short: shortSummary = '',
    medium: mediumSummary = '',
    long: longSummary = '',
    filed_by: filedBy,
    description,
    isSubmitted = false,
    authors,
    link,
    expanded = false,
    attachments = [],
  } = props;
  const useTabbedSummaries = shortSummary || mediumSummary || longSummary;
  const [open, setOpen] = useState(false);
  const [selectedAuthor, setSelectedAuthor] = useState<ICommenterAuthorInfo | null>(null);

  const handleClickOpen = (author: ICommenterAuthorInfo) => {
    setSelectedAuthor(author);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAuthor(null);
  };
  return (
    <>
      <Accordion
        disableGutters
        sx={{
          marginLeft: '1px',
          marginRight: '1px',
          bgcolor: '#e7e3e6',
          boxShadow: 'none',
          borderTop: 'none',
          '&:before': {
            display: 'none',
          },
        }}
        elevation={0}
        key={commentId}
        defaultExpanded={expanded}
      >
        <AccordionSummary
          sx={{ bgcolor: 'white', borderRadius: 2, boxShadow: 'none', border: 'none' }}
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Chip
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessTimeOutlinedIcon sx={{ color: '#e0b768', marginRight: 1 }} />{' '}
                    <Typography variant="body1" fontWeight="bold">
                      {format(parseISO(date), 'MMM d, yyyy')}
                    </Typography>
                  </Box>
                }
              />
              <Box sx={{ display: 'inline', margin: 1 }} />
              <Typography variant="body1" fontWeight="bold" sx={{ display: 'inline' }}>
                {isSubmitted ? '(Submitted)' : ''}{' '}
                {truncate(filedBy, { length: CONCAT_LIMIT_CHARS })}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {isSubmitted && <SubmittedDocNotice />}
          <Typography fontWeight="bold" variant="h6">
            {format(parseISO(date), 'MMMM d, yyyy')}
          </Typography>
          <Typography variant="body1" fontWeight="bold">
            {filedBy}
          </Typography>
          <Box sx={{ height: 18 }} />
          {description && (
            <>
              <Typography fontWeight="bold" variant="h6">
                Description
              </Typography>
              <Typography>{description}</Typography>
              <Box sx={{ height: 18 }} />
            </>
          )}
          <Typography fontWeight="bold" variant="h6">
            Authors
          </Typography>
          {authors.map((author: ICommenterAuthorInfo) => (
            <>
              <Typography
                onClick={() => handleClickOpen(author)}
                sx={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                {author.name} - {author.organization}
              </Typography>
            </>
          ))}
          <Box sx={{ height: 18 }} />
          {!useTabbedSummaries && summary && (
            <>
              <Typography fontWeight="bold" variant="h6">
                Summary
              </Typography>
              <SanitizedHTML html={summary.replace(/\n/g, '<br>')} />
              <Box sx={{ height: 18 }} />
            </>
          )}
          {useTabbedSummaries && (
            <ProceedingSummaryPanel
              shortSummary={shortSummary}
              longSummary={longSummary}
              showTitle
            />
          )}
        </AccordionDetails>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold">Show original document</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Link
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none', marginRight: 2 }}
            >
              {link}
            </Link>
            <Box sx={{ height: 10 }} />
            <iframe
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(link)}&embedded=true`}
              width="800"
              height="800"
              style={{ border: 'none' }}
              title="PDF Viewer"
            />
          </AccordionDetails>
        </Accordion>
        <Box sx={{ height: 18 }} />
        {attachments.map((attachment: ICommentData) => (
          <ProcAttachmentAccordion key={attachment.id} attachment={attachment} />
        ))}
      </Accordion>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Author Details - {selectedAuthor?.name}</DialogTitle>
        <DialogContent>
          {selectedAuthor && <AuthorDetailsTable author={selectedAuthor} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
