import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';

import { CURRENT_STEP_INDEX } from './types';

enum PROC_STEPS {
  APP = 'Application',
  OIR = 'OIR',
  IIR = 'IIR',
  SCOPING = 'Scoping',
  PD = 'Proposed Decisions',
  DECISIONS = 'Final Decisions',
  CLOSED = 'Closed',
}

interface IProps {
  progressStepIndex: CURRENT_STEP_INDEX;
  procType?: string;
}

// TODO Add proper styling to the progress stepper
export const ProgressStepper = (props: IProps) => {
  const { progressStepIndex, procType = 'R' } = props;

  let firstStep;
  switch (procType) {
    case 'A':
      firstStep = PROC_STEPS.APP;
      break;
    case 'I':
      firstStep = PROC_STEPS.IIR;
      break;
    default:
      firstStep = PROC_STEPS.OIR;
      break;
  }

  const steps = [
    firstStep,
    PROC_STEPS.SCOPING,
    PROC_STEPS.PD,
    PROC_STEPS.DECISIONS,
    PROC_STEPS.CLOSED,
  ];

  const activeStepIndex = progressStepIndex;

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStepIndex} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { error?: boolean; sx?: Record<string, any> } = {};

          if (activeStepIndex === index) {
            stepProps.completed = true;
          }

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <Typography>{label}</Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
