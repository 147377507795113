/* eslint-disable no-console */
import { Navigate } from 'react-router-dom';

import { useAuthContext } from './AuthContext';

interface IProps {
  children: any;
}

export const RequireAuth: React.FC<IProps> = (props) => {
  const { children } = props;
  const { isAuthenticated, sessionIsInitializing } = useAuthContext();

  if (sessionIsInitializing) {
    return null;
  }

  if (!isAuthenticated) {
    const nextUrlPath = window.location.pathname;

    let signInPath = '/sign-in';

    if (nextUrlPath !== '/') {
      signInPath += `?nextUrl=${nextUrlPath}`;
    }

    return <Navigate to={signInPath} replace />;
  }

  return <>{children}</>;
};
