import { CircularProgress, Link, List, Typography } from '@mui/material';

import { IProceedingListResponseObject, useProceedingsQuery } from './apis';
import { ProceedingCard } from './ProceedingCard';
import { IProceedingCounterRowProps } from './types';

export const ProceedingList = () => {
  const { data: proceedings, isFetching } = useProceedingsQuery();

  if (isFetching) {
    return <CircularProgress />;
  }

  if (!proceedings) {
    return <Typography>Search proceedings...</Typography>;
  }

  return (
    <>
      <Typography>Returned {proceedings.length} proceedings</Typography>
      <List>
        {proceedings.map((proc: IProceedingListResponseObject) => {
          const {
            number: procNumber,
            filed_by: filedBy,
            description: headline,
            active,
            progress,
            scoping_count: scopingCount,
            decisions_count: decisionsCount,
            rulings_count: rulingsCount,
            comments_count: commentsCount,
          } = proc;

          const counterRowProps: IProceedingCounterRowProps = {
            isNew: false,
            scopingCount,
            decisionsCount,
            rulingsCount,
            commentsCount,
          };

          return (
            <ProceedingCard
              procNumber={procNumber}
              showBookmarkIcon
              filedBy={filedBy}
              headline={headline}
              tags={[]}
              active={active}
              progress={progress}
              counterRowProps={counterRowProps}
              showCounterRow
            />
          );
        })}
      </List>
    </>
  );
};
