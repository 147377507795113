/* eslint-disable no-console */
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { IBillData, IBillListResponseObject } from './types';
import { ApiError } from '../../lib/types';

export const useBillsQuery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryString = searchParams.toString();

  let getBillsUrl = `/api/v1/bills`;
  if (queryString) {
    getBillsUrl += `?${queryString}`;
  }

  return useQuery<IBillListResponseObject[], ApiError>([getBillsUrl], {
    enabled: false,
  });
};

export const useBillsBySessionIdQuery = (billSessionId: string | undefined) => {
  const BILL_URL = `/api/v1/bills/${billSessionId}`;
  return useQuery<IBillData, ApiError>([BILL_URL], {
    enabled: Boolean(billSessionId),
  });
};
