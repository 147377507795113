import { Autocomplete, Box, Link, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { set } from 'lodash';
import { useState } from 'react';

import { filterBookmarkedProcsByTags, sortProcs } from './utils';
import { useBookmarkContext } from '../bookmark/BookmarkContext';
import { BookmarkType, IBookmarkedProceedingInfo } from '../bookmark/types';
import { ProceedingCard } from '../proceedings/ProceedingCard';
import { IProceedingCounterRowProps } from '../proceedings/types';

export const BookmarkedProceedingsList = () => {
  const theme = useTheme();
  const { bookmarkedProceedings = {} } = useBookmarkContext();

  const initialBookmarkedProcs = sortProcs(Object.values(bookmarkedProceedings));
  const [bookmarkedProcsToShow, setBookmarkedProcsToShow] =
    useState<IBookmarkedProceedingInfo[]>(initialBookmarkedProcs);

  const allTagsSet = new Set(
    Object.values(bookmarkedProceedings).flatMap((bookmarkedProc) => bookmarkedProc.tags)
  );

  const allTagOptions = Array.from(allTagsSet);

  return (
    <>
      <Typography
        sx={{ color: theme.palette.primary.main, fontWeight: 'bold', display: 'inline-block' }}
        variant="h5"
      >
        Bookmarked Proceedings ({Object.values(bookmarkedProceedings).length})
      </Typography>
      <Link
        sx={{ fontWeight: 'bold', float: 'right' }}
        href={`bookmarks?bookmarkType=${BookmarkType.PROCEEDING}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Team proceedings
      </Link>
      <Box sx={{ height: 24 }} />
      <Autocomplete
        onChange={(e, tagsToFilterBy) => {
          const filteredBookmarkedProcs = filterBookmarkedProcsByTags(
            initialBookmarkedProcs,
            tagsToFilterBy
          );
          setBookmarkedProcsToShow(sortProcs(filteredBookmarkedProcs));
        }}
        multiple
        size="small"
        id="proc-tags"
        options={allTagOptions}
        getOptionLabel={(option) => option}
        defaultValue={[]}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} placeholder="Filter Proceedings by tags" />}
      />
      <Box sx={{ height: 24 }} />
      {bookmarkedProcsToShow.map((procInfo) => {
        const {
          number: procNumber,
          tags,
          last_view: lastView,
          description: headline,
          active,
          progress,
          bookmark_id: bookmarkId,
          scoping_count: scopingCount,
          decisions_count: decisionsCount,
          rulings_count: rulingsCount,
          comments_count: commentsCount,
          new_scoping_count: newScopingCount,
          new_decisions_count: newDecisionsCount,
          new_rulings_count: newRulingsCount,
          new_comments_count: newCommentsCount,
        } = procInfo;
        const lastViewText = lastView ? `Last viewed: ${lastView}` : '';
        const counterRowProps: IProceedingCounterRowProps = {
          isNew: false,
          showNewIndicator: true,
          scopingCount,
          decisionsCount,
          rulingsCount,
          commentsCount,
          newScopingCount,
          newDecisionsCount,
          newRulingsCount,
          newCommentsCount,
        };
        return (
          <>
            <ProceedingCard
              key={procNumber}
              procNumber={procNumber}
              tags={tags}
              showBookmarkIcon={false}
              bookmarkId={bookmarkId}
              tagOptions={allTagOptions}
              headline={headline}
              active={active}
              progress={progress}
              tlCornerText={lastViewText}
              showCounterRow
              counterRowProps={counterRowProps}
            />
            <Box sx={{ height: 8 }} />
          </>
        );
      })}
      <Box sx={{ height: 24 }} />
    </>
  );
};
