import { QueryClient } from 'react-query';

import { apiClient } from './apiClient';
import { ApiRequestOptions, ApiResponse } from './types';

export type ApiQueryKey = [endpoint: string, options?: ApiRequestOptions];

interface IQueryFnParams {
  queryKey: readonly unknown[];
}

const defaultQueryFn = async (params: IQueryFnParams) => {
  const { queryKey } = params;
  const [endpoint, options] = queryKey as ApiQueryKey;
  const response: ApiResponse = await apiClient(endpoint, options);
  return response.payload;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
