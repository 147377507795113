import NorthEastIcon from '@mui/icons-material/NorthEast';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Card, CardActions, Chip, Link, Typography } from '@mui/material';

import { BillCardCounterRow } from './BillCardCounterRow';
import { BookmarkIcon } from '../bookmark/BookmarkIcon';
import { UpdateBookmarkTagsContainer } from '../bookmark/UpdateBookmarkTagsContainer';

interface IBillCounterRowProps {
  versionsCount?: number;
  votesCount?: number;
  actionsCount?: number;
  newVersionsCount?: number;
  newVotesCount?: number;
  newActionsCount?: number;
}
interface IBillCardProps {
  billSessionId: string;
  billName: string;
  headline: string;
  active: boolean;
  leadAuthors?: string[];
  progress: number[];
  tlCornerText: string;
  bookmarkId?: number;
  tags?: string[];
  tagOptions?: string[];
  showBookmarkIcon?: boolean;
  showCounterRow?: boolean;
  counterRowProps?: IBillCounterRowProps;
}

enum ProgressStatus {
  Vetoed = -2,
  Failed = -1,
  InProgress = 0,
  Passed = 1,
}

const progressStatusColorLookup: Record<number, string> = {
  [ProgressStatus.Vetoed]: '#FF0000', // Red color
  [ProgressStatus.Failed]: '#FF0000', // Red color
  [ProgressStatus.InProgress]: '#555555',
  [ProgressStatus.Passed]: '#008800', // Green color
};

const progressTextLookup: Record<number, string> = {
  0: 'Introduced',
  1: 'Chamber 1',
  2: 'Chamber 2',
  3: 'Governor',
};

export const BillCard = (props: IBillCardProps) => {
  const {
    billName,
    active,
    billSessionId,
    headline,
    leadAuthors,
    progress,
    tlCornerText,
    bookmarkId,
    tags,
    tagOptions,
    showBookmarkIcon = false,
    showCounterRow = false,
    counterRowProps,
  } = props;

  const activeChipBackgroundColor = active ? '#008800' : '#555555';
  const progressStatusColor = progressStatusColorLookup[progress[1]];
  const progressText = progressTextLookup[progress[0]];
  const billNameText = `CA > ${billName}`;

  return (
    <Card key={billSessionId} sx={{ p: 2, mb: 1 }}>
      <Box>
        {showBookmarkIcon && (
          <BookmarkIcon billSessionId={billSessionId} iconStyles={{ fontSize: '28px' }} />
        )}
        <Typography sx={{ fontWeight: 'bold', display: 'inline-block' }}>{billNameText}</Typography>
        <Chip
          sx={{
            backgroundColor: activeChipBackgroundColor,
            color: 'white',
            borderRadius: '7px',
            marginLeft: '1em',
          }}
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {active ? 'Active' : 'Inactive'}
            </Typography>
          }
        />
        <Chip
          sx={{
            backgroundColor: progressStatusColor,
            color: 'white',
            borderRadius: '7px',
            marginLeft: '1em',
          }}
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {progressText}
            </Typography>
          }
        />

        <Typography sx={{ display: 'inline-block', float: 'right' }}>
          {tlCornerText || ''}
        </Typography>
      </Box>

      <Box sx={{ height: 8 }} />
      {(!tags || !bookmarkId || !tagOptions) && <Box sx={{ my: 1 }} />}
      {tags && bookmarkId && tagOptions && (
        <UpdateBookmarkTagsContainer bookmarkId={bookmarkId} tags={tags} tagOptions={tagOptions} />
      )}
      <Typography
        sx={{
          my: '-10px',
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
        }}
      >
        {headline || 'Missing headline'}
      </Typography>
      <Box sx={{ height: 10 }} />
      {(!tags || !bookmarkId || !tagOptions) && <Box sx={{ my: 1 }} />}
      <CardActions sx={{ justifyContent: 'space-between', padding: 0.5 }}>
        {leadAuthors && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PersonOutlineOutlinedIcon sx={{ marginRight: 1, color: '#eecc87' }} />
            <Typography fontWeight="bold">{leadAuthors.join(', ')}</Typography>
          </Box>
        )}
        {showCounterRow && (
          <BillCardCounterRow
            isNew={
              !!(
                counterRowProps?.newVersionsCount ||
                counterRowProps?.newVotesCount ||
                counterRowProps?.newActionsCount
              )
            }
            versionsCount={counterRowProps?.versionsCount || 0}
            votesCount={counterRowProps?.votesCount || 0}
            actionsCount={counterRowProps?.actionsCount || 0}
            newVersionsCount={counterRowProps?.newVersionsCount || 0}
            newActionsCount={counterRowProps?.newActionsCount || 0}
            newVotesCount={counterRowProps?.newVotesCount || 0}
          />
        )}
        <Link
          sx={{ fontWeight: 'bold' }}
          href={`bills/${billSessionId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box display="flex" alignItems="center">
            View <NorthEastIcon sx={{ marginLeft: 0.25, paddingTop: 0 }} />
          </Box>
        </Link>
      </CardActions>
    </Card>
  );
};
