/* eslint-disable no-console */
import { useQuery } from 'react-query';

import { ApiError } from '../../lib/types';

interface ILandingPageStats {
  bills_count: number;
  proceedings_count: number;
}

export const useGetLandingPageStatsQuery = () => {
  const getLandingPageStatsUrl = `/api/v1/landing_page_stats`;
  return useQuery<ILandingPageStats, ApiError>([getLandingPageStatsUrl], {
    enabled: true,
  });
};
