import { isWithinInterval, parseISO, startOfDay, subDays } from 'date-fns';
import { capitalize, truncate } from 'lodash';

import {
  CommentGroup,
  DecisionGroup,
  ICommentData,
  IDecisionData,
  IOtherData,
  IRulingData,
  IScopingData,
  OtherGroup,
  RulingGroup,
  ScopingGroup,
} from './types';
import theme from '../../theme';

interface ITimelineContentInfo {
  content: string;
  docBackgroundColor: string;
  docTextColor: string;
}

const RULING_COLORS = {
  SEEKING_COMMENTS: '#FFBF00',
  STAFF_PAPER: '#FF7518',
  DEADLINE: '#FFAC1C',
  OTHER: '#FFD580',
  DEFAULT: '#FAD5A5',
  TBD: '#FBCEB1',
};
const DECISION_COLORS = {
  DECISION: '#1aa412',
  PROPOSED_DECISION: theme.palette.secondary.main,
  PETITION_FOR_MODIFICATION: theme.palette.primary.main,
};
const DECISION_TEXT_COLORS = {
  DECISION: 'white',
  PROPOSED_DECISION: 'black',
  PETITION_FOR_MODIFICATION: 'white',
};
const SCOPING_COLORS = {
  APPLICATION: '#1aa412',
  OIR: '#1aa412',
  OII: '#1aa412',
  SCOPING_RULING: theme.palette.secondary.main,
};
const SCOPING_TEXT_COLORS = {
  APPLICATION: 'white',
  OIR: 'white',
  OII: 'white',
  SCOPING_RULING: 'black',
};
const SUBMITTED_DOC_COLORS = {
  BACKGROUND: 'gray',
  TEXT: 'white',
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
export function titleCaseExceptPrepositions(text: string): string {
  const PREPOSITIONS = [
    'of',
    'in',
    'to',
    'for',
    'with',
    'on',
    'at',
    'from',
    'by',
    'as',
    'and',
    'the',
  ];
  const KNOWN_ABBREVIATIONS = [
    'CAISO',
    'CPUC',
    'CEC',
    'CEQA',
    'DWR',
    'FERC',
    'PUC',
    'PG&E',
    'SDG&E',
    'TPP',
    'IRP',
    'AM',
    'PM',
    'OC:',
    'RC:',
    'CO:',
    'LLC',
    'PD',
    'PFM',
    'OIR',
    'LLC',
    'OII',
  ];
  return text
    .split(' ')
    .map((word, index) => {
      if (KNOWN_ABBREVIATIONS.includes(word.toUpperCase())) {
        return word.toUpperCase();
      }
      if (index === 0 || !PREPOSITIONS.includes(word.toLowerCase())) {
        return capitalize(word);
      }
      return word.toLowerCase();
    })
    .join(' ');
}

const formatTimelineRulingContent = (ruling: IRulingData): ITimelineContentInfo => {
  const content = ruling.subtype
    ? titleCaseExceptPrepositions(ruling.subtype)
    : titleCaseExceptPrepositions(ruling.type);

  if (content === 'Seeking Comments') {
    const formattedSeekingContent = ruling.description.toLowerCase().includes('ruling')
      ? ruling.description.toLowerCase().split('ruling')[1].trim()
      : ruling.description.toLowerCase();

    return {
      content: titleCaseExceptPrepositions(
        formattedSeekingContent.includes('.')
          ? formattedSeekingContent.split('.')[0]
          : formattedSeekingContent
      ),
      docBackgroundColor: RULING_COLORS.SEEKING_COMMENTS,
      docTextColor: 'black',
    };
  }

  if (content === 'Staff Paper') {
    let paper_desc: string;
    if (ruling.description.toLowerCase().includes('staff paper on')) {
      paper_desc = ruling.description.toLowerCase().split('staff paper on')[1].trim();
    } else {
      paper_desc = ruling.description.toLowerCase();
    }
    let formattedContent = `Staff Paper: ${paper_desc.split(' ').slice(0, 3).join(' ')}`;
    if (paper_desc.split(' ').length > 3) {
      formattedContent += '...';
    }
    formattedContent = formattedContent.includes('.')
      ? formattedContent.split('.')[0]
      : formattedContent;

    return {
      content: titleCaseExceptPrepositions(formattedContent),
      docBackgroundColor: RULING_COLORS.STAFF_PAPER,
      docTextColor: 'black',
    };
  }

  if (content === 'Deadline') {
    let formattedContent = ruling.description.toLowerCase();
    // Determine the midpoint of the string
    const midpoint = Math.floor(formattedContent.length / 2);

    // Check if 'ruling' exists in the first half of the string
    const firstHalf = formattedContent.slice(0, midpoint);
    formattedContent = firstHalf.includes('ruling')
      ? formattedContent.split('ruling')[1].trim()
      : formattedContent;
    // Check if starts with '.' and remove it
    formattedContent = formattedContent.trim().startsWith('.')
      ? formattedContent.trim().slice(1)
      : formattedContent;
    formattedContent = formattedContent.includes('.')
      ? formattedContent.split('.')[0]
      : formattedContent;

    return {
      content: titleCaseExceptPrepositions(formattedContent),
      docBackgroundColor: RULING_COLORS.DEADLINE,
      docTextColor: 'black',
    };
  }

  if (content === 'Other') {
    let formattedContent = ruling.description.toLowerCase();
    if (ruling.description.split(' ').length > 3) {
      formattedContent = formattedContent.includes('ruling')
        ? formattedContent.split('ruling')[1].trim()
        : formattedContent;
      formattedContent = formattedContent.includes('.')
        ? formattedContent.split('.')[0]
        : formattedContent;
    }

    return {
      content: titleCaseExceptPrepositions(formattedContent),
      docBackgroundColor: RULING_COLORS.OTHER,
      docTextColor: 'black',
    };
  }

  return {
    content,
    docBackgroundColor: RULING_COLORS.DEFAULT,
    docTextColor: 'black',
  };
};

const formatTimelineDecisionContent = (decision: IDecisionData): ITimelineContentInfo => {
  const content = decision.type;
  const docType = decision.type.toLowerCase();
  const docBackgroundColor = '#1aa412';
  const docTextColor = 'black';

  if (docType === 'decision') {
    let formattedContent = decision.title.toLowerCase();
    formattedContent = formattedContent.includes('proceeding')
      ? formattedContent.split('proceeding')[0]
      : formattedContent;
    formattedContent = titleCaseExceptPrepositions(formattedContent);

    return {
      content: formattedContent.replace(/D\d+/g, ''),
      docBackgroundColor: DECISION_COLORS.DECISION,
      docTextColor: DECISION_TEXT_COLORS.DECISION,
    };
  }
  if (docType === 'proposed decision') {
    let formattedContent = decision.description;
    formattedContent = formattedContent.includes('.')
      ? formattedContent.split('.')[0]
      : formattedContent;
    formattedContent = titleCaseExceptPrepositions(formattedContent);

    return {
      content: formattedContent.replace(/Proposed Decision/gi, 'PD'),
      docBackgroundColor: DECISION_COLORS.PROPOSED_DECISION,
      docTextColor: DECISION_TEXT_COLORS.PROPOSED_DECISION,
    };
  }

  if (docType === 'petition for modification') {
    let petitionContent = decision.type;

    if (decision?.meta_data && decision?.meta_data?.petitioner) {
      petitionContent = `PFM: ${decision.meta_data.petitioner.replace(/;/g, ',')}`;
    }

    return {
      content: petitionContent,
      docBackgroundColor: DECISION_COLORS.PETITION_FOR_MODIFICATION,
      docTextColor: DECISION_TEXT_COLORS.PETITION_FOR_MODIFICATION,
    };
  }

  return { content: titleCaseExceptPrepositions(content), docBackgroundColor, docTextColor };
};

const formatTimelineScopingContent = (scoping: IScopingData): ITimelineContentInfo => {
  const content = scoping.type;
  const docType = scoping.type.toLowerCase();
  const docBackgroundColor = SCOPING_COLORS.SCOPING_RULING;
  const docTextColor = SCOPING_TEXT_COLORS.SCOPING_RULING;

  if (docType === 'application') {
    return {
      content: titleCaseExceptPrepositions(docType),
      docBackgroundColor: SCOPING_COLORS.APPLICATION,
      docTextColor: SCOPING_TEXT_COLORS.APPLICATION,
    };
  }
  if (docType === 'commission rulemaking') {
    return {
      content: 'OIR',
      docBackgroundColor: SCOPING_COLORS.OIR,
      docTextColor: SCOPING_TEXT_COLORS.OIR,
    };
  }
  if (docType === 'commission investigation') {
    return {
      content: 'OII',
      docBackgroundColor: SCOPING_COLORS.OII,
      docTextColor: SCOPING_TEXT_COLORS.OII,
    };
  }
  if (docType === 'scoping ruling') {
    return {
      content: titleCaseExceptPrepositions(docType),
      docBackgroundColor: SCOPING_COLORS.SCOPING_RULING,
      docTextColor: SCOPING_TEXT_COLORS.SCOPING_RULING,
    };
  }

  return { content: titleCaseExceptPrepositions(content), docBackgroundColor, docTextColor };
};
const formatTimelineOtherContent = (other: IOtherData): ITimelineContentInfo => {
  const content = other.type;
  const docType = other.type.toLowerCase();
  const docBackgroundColor = SCOPING_COLORS.SCOPING_RULING;
  const docTextColor = SCOPING_TEXT_COLORS.SCOPING_RULING;

  if (docType === 'application') {
    return {
      content: titleCaseExceptPrepositions(docType),
      docBackgroundColor: SCOPING_COLORS.APPLICATION,
      docTextColor: SCOPING_TEXT_COLORS.APPLICATION,
    };
  }
  if (docType === 'commission rulemaking') {
    return {
      content: 'OIR',
      docBackgroundColor: SCOPING_COLORS.OIR,
      docTextColor: SCOPING_TEXT_COLORS.OIR,
    };
  }
  if (docType === 'scoping ruling') {
    return {
      content: titleCaseExceptPrepositions(docType),
      docBackgroundColor: SCOPING_COLORS.SCOPING_RULING,
      docTextColor: SCOPING_TEXT_COLORS.SCOPING_RULING,
    };
  }

  return { content: titleCaseExceptPrepositions(content), docBackgroundColor, docTextColor };
};

const checkIsSubmitted = (doc: ICommentData | IRulingData | IDecisionData | IScopingData) => {
  return doc.is_submitted;
};
const CONCAT_LIMIT_CHARS = 35;

interface IGetTimelineProps {
  commentsIsChecked: boolean;
  rulingsIsChecked: boolean;
  decisionsIsChecked: boolean;
  scopingIsChecked: boolean;
  othersIsChecked: boolean;
  comments: ICommentData[];
  rulings: IRulingData[];
  decisions: IDecisionData[];
  scopings: IScopingData[];
  others: IOtherData[];
}
/**
 *
 * Builds the object required to display the timeline component
 */
export const getProceedingTimelineItems = (props: IGetTimelineProps) => {
  const {
    commentsIsChecked,
    rulingsIsChecked,
    decisionsIsChecked,
    scopingIsChecked,
    othersIsChecked,
    comments,
    rulings,
    decisions,
    scopings,
    others,
  } = props;
  const groups = [];
  const timelineItems: any = [];

  const TODAY = startOfDay(new Date());
  const NINETY_DAYS_AGO = subDays(TODAY, 90);

  if (commentsIsChecked) {
    groups.push({
      id: CommentGroup.ID,
      content: 'Comments',
      value: 1,
      style: 'font-size: 15px; font-weight: bold;',
    });

    // One timeline item per group of comments per date and type
    const dateAndTypeMap: Map<string, any[]> = new Map();

    comments.forEach((comment) => {
      const { id: commentId, type, date } = comment;

      // Need to stringify since JS evals arrays by reference
      const key: string = JSON.stringify([date, type]);

      if (!dateAndTypeMap.has(key)) {
        dateAndTypeMap.set(key, []);
      }

      dateAndTypeMap.get(key)?.push({
        ...comment,
      });
    });

    const commentItems: any[] = [];

    // Convert the tuples back into objects
    const dateAndTypes = Array.from(dateAndTypeMap.keys()).map((tupleString) => {
      return JSON.parse(tupleString);
    });

    dateAndTypes.forEach(([date, type]) => {
      const keyString = JSON.stringify([date, type]);
      const itemsForKeyString = dateAndTypeMap.get(keyString);
      let contentString = `${capitalize(type)} (${itemsForKeyString?.length})`;
      let docBackgroundColor = 'black';
      let docTextColor = 'white';
      // If there is only one item in the group, show filed by text
      let isSubmitted = false;
      if (itemsForKeyString?.length === 1) {
        const comment = itemsForKeyString[0];
        const { filed_by, type: commentType } = comment;
        const typeToPrefixString: Record<string, string> = {
          comments: 'CO:',
          'reply comments': 'RC:',
          'opening comments': 'OC:',
        };

        contentString = `${typeToPrefixString[commentType] || ''} ${filed_by}`;
        isSubmitted = checkIsSubmitted(comment);
      } else {
        // check if any comment in the group is submitted
        isSubmitted = itemsForKeyString?.some(checkIsSubmitted) || false;
      }
      if (isSubmitted) {
        contentString = `(SUB) ${contentString}`;
        docBackgroundColor = SUBMITTED_DOC_COLORS.BACKGROUND;
        docTextColor = SUBMITTED_DOC_COLORS.TEXT;
      }
      commentItems.push({
        id: keyString,
        group: CommentGroup.ID,
        content: truncate(contentString, { length: CONCAT_LIMIT_CHARS }),
        start: parseISO(date),
        style: `background-color: ${docBackgroundColor}; color: ${docTextColor}; border-radius: 16px; font-size: 14px;`, // Apply mapped colors
      });
    });

    timelineItems.push(...commentItems);
  }

  if (rulingsIsChecked) {
    groups.push({
      id: RulingGroup.ID,
      content: 'Rulings',
      value: 2,
      style: 'font-size: 15px; font-weight: bold;',
    });
    const rulingItems: any = [];

    rulings.forEach((ruling) => {
      const { date, id: rulingId, description } = ruling;
      const rulingDate = parseISO(date);

      let { content, docBackgroundColor, docTextColor } = formatTimelineRulingContent(ruling);
      if (ruling.is_submitted) {
        content = `(SUB) ${content}`;
        docBackgroundColor = SUBMITTED_DOC_COLORS.BACKGROUND;
        docTextColor = SUBMITTED_DOC_COLORS.TEXT;
      }
      rulingItems.push({
        id: rulingId,
        group: RulingGroup.ID,
        content: truncate(content, { length: CONCAT_LIMIT_CHARS }),
        start: rulingDate,
        style: `background-color: ${docBackgroundColor}; color: ${docTextColor}; border-radius: 16px; font-size: 14px;`, // Apply mapped colors
      });
    });

    timelineItems.push(...rulingItems);
  }

  if (decisionsIsChecked) {
    groups.push({
      id: DecisionGroup.ID,
      content: 'Decisions',
      value: 3,
      style: 'font-size: 15px; font-weight: bold;',
    });

    const decisionItems: any[] = [];

    decisions.forEach((decision) => {
      const { date, id: decisionId, description } = decision;
      const decisionDate = parseISO(date);

      // Temporarily limit to 30 days ago. In the future, add functionality to change this via UI
      // if (!isWithinInterval(decisionDate, { start: NINETY_DAYS_AGO, end: TODAY })) {
      //   return;
      // }
      let { content, docBackgroundColor, docTextColor } = formatTimelineDecisionContent(decision);
      if (decision.is_submitted) {
        content = `(SUB) ${content}`;
        docBackgroundColor = SUBMITTED_DOC_COLORS.BACKGROUND;
        docTextColor = SUBMITTED_DOC_COLORS.TEXT;
      }
      decisionItems.push({
        id: decisionId,
        group: DecisionGroup.ID,
        content: truncate(content, { length: CONCAT_LIMIT_CHARS }),
        start: date,
        style: `background-color: ${docBackgroundColor}; color: ${docTextColor}; border-radius: 16px; font-size: 14px;`, // Apply mapped colors
      });
    });

    timelineItems.push(...decisionItems);
  }

  if (scopingIsChecked) {
    groups.push({
      id: ScopingGroup.ID,
      content: 'Scoping',
      value: 4,
      style: 'font-size: 15px; font-weight: bold;',
    });

    const scopingItems: any[] = [];

    scopings.forEach((scopingItem) => {
      const { date, id: scopingId, description } = scopingItem;
      const scopingDate = parseISO(date);

      // Temporarily limit to 30 days ago. In the future, add functionality to change this via UI
      // if (!isWithinInterval(scopingDate, { start: NINETY_DAYS_AGO, end: TODAY })) {
      //   return;
      // }
      let { content, docBackgroundColor, docTextColor } = formatTimelineScopingContent(scopingItem);
      if (scopingItem.is_submitted) {
        content = `(SUB) ${content}`;
        docBackgroundColor = SUBMITTED_DOC_COLORS.BACKGROUND;
        docTextColor = SUBMITTED_DOC_COLORS.TEXT;
      }
      scopingItems.push({
        id: scopingId,
        group: ScopingGroup.ID,
        content: truncate(content, { length: CONCAT_LIMIT_CHARS }),
        start: scopingDate,
        style: `background-color: ${docBackgroundColor}; color: ${docTextColor}; border-radius: 16px; font-size: 14px;`, // Apply mapped colors
      });
    });

    timelineItems.push(...scopingItems);
  }
  if (othersIsChecked) {
    groups.push({
      id: OtherGroup.ID,
      content: 'Other',
      value: 5,
      style: 'font-size: 15px; font-weight: bold;',
    });
    const otherItems: any = [];

    others.forEach((otherItem) => {
      const { date, id: otherId, description } = otherItem;
      const otherDate = parseISO(date);

      // Temporarily limit to 30 days ago. In the future, add functionality to change this via UI
      //   if (!isWithinInterval(rulingDate, { start: NINETY_DAYS_AGO, end: TODAY })) {
      //     return;
      //   }
      let { content, docBackgroundColor, docTextColor } = formatTimelineOtherContent(otherItem);
      if (otherItem.is_submitted) {
        content = `(SUB) ${content}`;
        docBackgroundColor = SUBMITTED_DOC_COLORS.BACKGROUND;
        docTextColor = SUBMITTED_DOC_COLORS.TEXT;
      }
      otherItems.push({
        id: otherId,
        group: OtherGroup.ID,
        content: truncate(content, { length: CONCAT_LIMIT_CHARS }),
        start: otherDate,
        style: `background-color: ${docBackgroundColor}; color: ${docTextColor}; border-radius: 16px; font-size: 14px;`, // Apply mapped colors
      });
    });

    timelineItems.push(...otherItems);
  }
  return {
    groups,
    timelineItems,
  };
};

interface IFilterItemsByKeywordsProps {
  items: (ICommentData | IRulingData | IDecisionData | IScopingData | IOtherData)[];
  keywordsToFilterBy: string[];
}

export const filterItemsByKeywords = (props: IFilterItemsByKeywordsProps) => {
  const { items, keywordsToFilterBy } = props;

  if (keywordsToFilterBy.length === 0) {
    return items;
  }

  return items.filter((item) => {
    const { meta_data } = item;
    const { keywords_v2 = [] } = meta_data || {};

    // Ensure each keywordToFilterBy is fuzzy matched to at least one item in keywords_v2
    return keywordsToFilterBy.every((keyword) =>
      keywords_v2.some((target) => target.toLowerCase().includes(keyword.toLowerCase()))
    );
  });
};

/**
 * This is  intended to be used to populate the Autocomplete dropdown.
 */
export const getAllKeywordsForItems = (
  items: (ICommentData | IRulingData | IDecisionData | IScopingData | IOtherData)[]
) => {
  if (!items || items.length === 0) {
    return [];
  }

  const keywordsSet = new Set<string>();
  items.forEach((item) => {
    const { meta_data } = item;
    const { keywords_v2 = [] } = meta_data || {};
    keywords_v2.forEach((keyword) => {
      keywordsSet.add(keyword);
    });
  });

  return Array.from(keywordsSet);
};

interface IFilterItemsByDateRangeProps {
  items: (ICommentData | IRulingData | IDecisionData | IScopingData | IOtherData)[];
  fromDate: string;
  toDate: string;
}

export const filterItemsByDateRange = (props: IFilterItemsByDateRangeProps) => {
  const { items, fromDate, toDate } = props;
  return items.filter((item) => {
    const { date } = item;
    if (isWithinInterval(date, { start: fromDate, end: toDate })) {
      return true;
    }
    return false;
  });
};
