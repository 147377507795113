import { SvgIconComponent } from '@mui/icons-material';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

interface IProps {
  title: string;
  subtitle: string;
  count: number | undefined;
  buttonText: string;
  buttonLink: string;
  icon: SvgIconComponent;
}

export const HeadingCard = ({
  title,
  subtitle,
  count,
  buttonText,
  buttonLink,
  icon: Icon,
}: IProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Card sx={{ background: `linear-gradient(to right, #845C76, ${theme.palette.primary.main})` }}>
      <CardContent>
        <Grid container spacing={1} sx={{ my: '-1.5em' }}>
          <Grid item xs={8}>
            <Icon sx={{ color: theme.palette.secondary.main, fontSize: 60 }} />
            <Typography
              sx={{
                color: 'white',
                fontWeight: 'normal',
                fontFamily: 'serif',
                fontSize: '2em',
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                color: 'white',
                fontWeight: 'normal',
                fontFamily: 'sans-serif',
                fontSize: '0.9em',
                marginTop: '-0.5em',
              }}
            >
              {subtitle}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            container
            direction="column"
            alignItems="flex-end"
            justifyContent="center"
          >
            <Typography sx={{ color: 'white', fontWeight: 'normal', fontSize: '2.5em' }}>
              {count}
            </Typography>
            <Typography
              sx={{ color: 'white', fontWeight: 'normal', fontSize: '.9em', marginTop: '-1em' }}
            >
              records
            </Typography>
            <Button
              sx={{
                mt: '2em',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.7)', // Light semi-transparent white for hover
                  borderColor: 'white',
                },
              }}
              variant="outlined"
              onClick={() => navigate(buttonLink)}
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
