import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  List,
  ListItem,
  Modal,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { Dispatch, SetStateAction } from 'react';

import { IAnalysesData } from './types';

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  analysis?: IAnalysesData;
}

function SanitizedHTML({ html }: { html: string }) {
  const sanitizedHTML = DOMPurify.sanitize(html);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
}

export const BillAnalysesModal = (props: IProps) => {
  const { modalIsOpen = false, setModalIsOpen, analysis } = props;
  if (!analysis) {
    return null;
  }

  const { meta_data, date } = analysis;
  const {
    title,
    summary,
    support = [],
    opposition = [],
    arguments_in_support: argumentsInSupport = '',
    arguments_in_opposition: argumentsInOpposition = '',
    text: originalText = '',
  } = meta_data;

  const summaryDisplay = summary.replace(/\n/g, '<br>');

  return (
    <Modal
      open={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false);
      }}
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: '#e7e3e6',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          outline: 'none',
          maxHeight: 'calc(100vh - 100px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
          scrollbarWidth: 'thin', // For Firefox
          scrollbarColor: '#888 #f1f1f1', // For Firefox
        }}
      >
        <Card>
          <Typography variant="h6" fontWeight="bold">
            {format(date, 'MMMM d, yyyy')}
          </Typography>
        </Card>
        <Box sx={{ height: 18 }} />
        <Card>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Card>
        <Box sx={{ height: 18 }} />
        <Card>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Summary
          </Typography>
          <Box sx={{ height: 18 }} />
          <SanitizedHTML html={summaryDisplay} />
        </Card>
        <Box sx={{ height: 18 }} />
        {support.length > 0 && (
          <>
            <Card>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Support
              </Typography>
              <List component="ol" sx={{ listStyleType: 'decimal', pl: 4 }}>
                {support.map((item) => (
                  <ListItem sx={{ display: 'list-item' }}>{item}</ListItem>
                ))}
              </List>
            </Card>
            <Box sx={{ height: 18 }} />
          </>
        )}
        {opposition.length > 0 && (
          <>
            <Card>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Opposition
              </Typography>
              <List component="ol" sx={{ listStyleType: 'decimal', pl: 4 }}>
                {opposition.map((item) => (
                  <ListItem sx={{ display: 'list-item' }}>{item}</ListItem>
                ))}
              </List>
            </Card>
            <Box sx={{ height: 18 }} />
          </>
        )}
        {argumentsInSupport && (
          <>
            <Card>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Arguments in Support
              </Typography>
              <Box sx={{ height: 6 }} />
              <Typography>{argumentsInSupport}</Typography>
            </Card>
            <Box sx={{ height: 18 }} />
          </>
        )}
        {argumentsInOpposition && (
          <Card>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Arguments in Opposition
            </Typography>
            <Box sx={{ height: 6 }} />
            <Typography>{argumentsInOpposition}</Typography>
          </Card>
        )}
        <Box sx={{ height: 6 }} />
        {originalText && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h6" fontWeight="bold">
                View Original Text
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SanitizedHTML html={originalText.replace(/\n/g, '<br>')} />
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </Modal>
  );
};
