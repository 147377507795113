export enum CommentGroup {
  ID = 'commentGroupId',
}

export enum RulingGroup {
  ID = 'rulingGroupId',
}

export enum DecisionGroup {
  ID = 'decisionGroupId',
}

export enum ScopingGroup {
  ID = 'scopingGroupId',
}
export enum OtherGroup {
  ID = 'otherGroupId',
}

export interface IProceedingCounterRowProps {
  isNew: boolean;
  showNewIndicator?: boolean;
  scopingCount: number;
  decisionsCount: number;
  rulingsCount: number;
  commentsCount: number;
  newScopingCount?: number;
  newDecisionsCount?: number;
  newRulingsCount?: number;
  newCommentsCount?: number;
}

export interface IProceedingCardProps {
  procNumber: string;
  headline?: string;
  active?: boolean;
  filedBy?: string;
  progress?: number;
  tlCornerText?: string;
  bookmarkId?: number;
  tags?: string[];
  tagOptions?: string[];
  showBookmarkIcon?: boolean;
  showCounterRow?: boolean;
  counterRowProps?: IProceedingCounterRowProps;
}

export type CURRENT_STEP_INDEX = 0 | 1 | 2 | 3 | 4;

export interface IDecisionData {
  date: string;
  id: number;
  title: string;
  type: string;
  link: string;
  description: string;
  is_attachment: boolean;
  filed_by: string;
  is_submitted: boolean;
  parent_doc_id: number;
  meta_data: {
    summary: string;
    title?: string;
    petitioner?: string;
    keywords_v2: string[];
    long?: string;
    medium?: string;
    short?: string;
  };
}

export interface IScopingData {
  date: string;
  id: number;
  title: string;
  type: string;
  subtype?: string;
  link: string;
  filed_by: string;
  description: string;
  is_attachment: boolean;
  is_submitted: boolean;
  parent_doc_id: number;
  meta_data: {
    background?: string;
    title?: string;
    purpose: string;
    issues?: string;
    phases?: string;
    hearings?: string;
    issue_date: string;
    out_scope?: string;
    comment_period?: string;
    schedule_table?: string;
    keywords_v2: string[];
    summary?: string; // For attachments
    long?: string; // For attachments
    medium?: string; // For attachments
    short?: string; // For attachments
  };
}

export interface IRulingData {
  date: string;
  id: number;
  title: string;
  type: string;
  subtype: string;
  description: string;
  is_attachment: boolean;
  is_submitted: boolean;
  parent_doc_id: number;
  link: string;
  filed_by: string;
  meta_data: {
    keywords?: string[];
    keywords_v2: string[];
    summary?: string; // Markdown text
    title?: string;
    long?: string;
    medium?: string;
    short?: string;
  };
}

export interface ICommenterAuthorInfo {
  address: string;
  email: string;
  name: string;
  organization: string;
  phone: string;
  role: string;
}

export interface ICommentData {
  id: number;
  date: string;
  description: string;
  is_attachment: boolean;
  is_submitted: boolean;
  parent_doc_id: number;
  type: string;
  subtype: string;
  filed_by: string;
  link: string;
  meta_data: {
    summary: string;
    title?: string;
    keywords_v2: string[];
    commenter_info: {
      authors: ICommenterAuthorInfo[];
    };
    long?: string;
    medium?: string;
    short?: string;
  };
}

export interface IOtherData {
  date: string;
  id: number;
  title: string;
  type: string;
  subtype: string;
  filed_by: string;
  description: string;
  is_attachment: boolean;
  is_submitted: boolean;
  parent_doc_id: number;
  link: string;
  meta_data?: {
    keywords?: string[];
    keywords_v2?: string[];
    summary?: string; // Markdown text
    title?: string;
    long?: string;
    medium?: string;
    short?: string;
  };
}
