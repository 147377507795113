import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Link,
  Modal,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { ProcAttachmentAccordion } from './ProcAttachmentAccordion';
import { SubmittedDocNotice } from './SubmittedDocNotice';
import { IScopingData } from './types';
import { SanitizedHTML } from '../common/SanitizedHTML';
import '../../styles/markdown-styles.css';
// Import the custom styles
interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  scoping?: IScopingData | undefined;
  attachments?: IScopingData[] | undefined;
}
const boldLeftEntriesWithoutRight = (markdownTable: string): string => {
  const rows = markdownTable.trim().split('\n');
  const processedRows = rows.map((row) => {
    const columns = row.split('|').map((col) => col.trim());
    if (columns.length === 3 && !columns[2]) {
      columns[1] = `**${columns[1]}**`;
    }
    return columns.join(' | ');
  });
  return processedRows.join('\n');
};

export const ProcScopingModal = (props: IProps) => {
  const { modalIsOpen = false, setModalIsOpen, scoping, attachments = [] } = props;

  if (!scoping) {
    return null;
  }

  const { date, description, meta_data, link, is_submitted: isSubmitted = false } = scoping;
  const {
    purpose = '',
    issues = '',
    background = '',
    phases = '',
    hearings = '',
    out_scope = '',
    issue_date: issueDate = '',
    comment_period: commentPeriod,
    schedule_table: scheduleMarkdownTable = '',
  } = meta_data;
  // Check each item from meta_data for 'i don't know' and assign empty if found
  const sanitizedMetaData = Object.fromEntries(
    Object.entries(meta_data).map(([key, value]) => {
      if (typeof value === 'string') {
        return [key, value.toLowerCase().includes("i don't know") ? '' : value];
      }
      if (Array.isArray(value)) {
        return [
          key,
          value.map((item) => (item.toLowerCase().includes("i don't know") ? '' : item)),
        ];
      }
      return [key, value];
    })
  );

  const {
    purpose: sanitizedPurpose = '',
    issues: sanitizedIssues = '',
    background: sanitizedBackground = '',
    phases: sanitizedPhases = '',
    hearings: sanitizedHearings = '',
    out_scope: sanitizedOutScope = '',
    issue_date: sanitizedIssueDate = '',
    comment_period: sanitizedCommentPeriod,
    schedule_table: sanitizedScheduleMarkdownTable = '',
  } = sanitizedMetaData;
  const processedTable = boldLeftEntriesWithoutRight(sanitizedScheduleMarkdownTable);

  const showAtAGlance =
    sanitizedPhases ||
    sanitizedHearings ||
    sanitizedIssueDate ||
    sanitizedCommentPeriod ||
    sanitizedIssues;
  return (
    <Modal
      open={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false);
      }}
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 840,
          bgcolor: '#e7e3e6',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          outline: 'none',
          maxHeight: '85vh', // Set the maximum height to the viewport height
          overflowY: 'auto', // Enable vertical scrolling
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
          scrollbarWidth: 'thin', // For Firefox
          scrollbarColor: '#888 #f1f1f1', // For Firefox
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {format(parseISO(date), 'MMMM d, yyyy')}
        </Typography>
        <Box sx={{ height: 10 }} />
        {isSubmitted && <SubmittedDocNotice />}
        <Typography fontWeight="bold" variant="h6">
          {description}
        </Typography>
        <Box sx={{ height: 18 }} />
        {showAtAGlance && (
          <>
            <Card>
              <Typography fontWeight="bold" variant="h6">
                At a Glance
              </Typography>
            </Card>

            <Box sx={{ height: 6 }} />
            <Card>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                  {sanitizedPhases && (
                    <tr style={{ borderBottom: '1px solid #ddd' }}>
                      <td style={{ padding: '8px', borderRight: '1px solid #ddd' }}>
                        <Typography fontWeight="bold" variant="h6">
                          Phases or Tracks?
                        </Typography>
                      </td>
                      <td style={{ padding: '8px' }}>
                        <Markdown>{sanitizedPhases}</Markdown>
                      </td>
                    </tr>
                  )}
                  {sanitizedHearings && (
                    <tr style={{ borderBottom: '1px solid #ddd' }}>
                      <td style={{ padding: '8px', borderRight: '1px solid #ddd' }}>
                        <Typography fontWeight="bold" variant="h6">
                          Hearings?
                        </Typography>
                      </td>
                      <td style={{ padding: '8px' }}>
                        <Markdown>{sanitizedHearings}</Markdown>
                      </td>
                    </tr>
                  )}
                  {sanitizedIssueDate && (
                    <tr style={{ borderBottom: '1px solid #ddd' }}>
                      <td style={{ padding: '8px', borderRight: '1px solid #ddd' }}>
                        <Typography fontWeight="bold" variant="h6">
                          Date Issued
                        </Typography>
                      </td>
                      <td style={{ padding: '8px' }}>
                        <SanitizedHTML html={sanitizedIssueDate.replace(/\n/g, '<br>')} />
                      </td>
                    </tr>
                  )}
                  {sanitizedCommentPeriod && (
                    <tr style={{ borderBottom: '1px solid #ddd' }}>
                      <td style={{ padding: '8px', borderRight: '1px solid #ddd' }}>
                        <Typography fontWeight="bold" variant="h6">
                          Comment Period
                        </Typography>
                      </td>
                      <td style={{ padding: '8px' }}>
                        <Markdown>{sanitizedCommentPeriod}</Markdown>
                      </td>
                    </tr>
                  )}
                  {sanitizedIssues && (
                    <tr style={{ borderBottom: '1px solid #ddd' }}>
                      <td style={{ padding: '8px', borderRight: '1px solid #ddd' }}>
                        <Typography fontWeight="bold" variant="h6">
                          Issues to be Considered
                        </Typography>
                      </td>
                      <td style={{ padding: '8px' }}>
                        <Markdown>{sanitizedIssues}</Markdown>
                      </td>
                    </tr>
                  )}
                  {sanitizedOutScope && (
                    <tr style={{ borderBottom: '1px solid #ddd' }}>
                      <td style={{ padding: '8px', borderRight: '1px solid #ddd' }}>
                        <Typography fontWeight="bold" variant="h6">
                          Out of Scope
                        </Typography>
                      </td>
                      <td style={{ padding: '8px' }}>
                        <Markdown>{sanitizedOutScope}</Markdown>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Card>
            <Box sx={{ height: 18 }} />
          </>
        )}
        <Box sx={{ height: 18 }} />
        {sanitizedScheduleMarkdownTable && (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontWeight="bold">Show Schedule</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Markdown className="markdown-table" remarkPlugins={[remarkGfm]}>
                  {processedTable}
                </Markdown>
              </AccordionDetails>
            </Accordion>
            <Box sx={{ height: 18 }} />
          </>
        )}
        {sanitizedPurpose && (
          <Card>
            <Typography fontWeight="bold" variant="h6">
              Overview
            </Typography>
            <Box sx={{ height: 10 }} />
            <Typography>{sanitizedPurpose}</Typography>
          </Card>
        )}
        <Box sx={{ height: 18 }} />
        {sanitizedBackground && (
          <Card>
            <Typography fontWeight="bold" variant="h6">
              Background
            </Typography>
            <Box sx={{ height: 10 }} />
            <Typography>{sanitizedBackground}</Typography>
          </Card>
        )}
        <Box sx={{ height: 18 }} />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold">Show original document</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Link
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none', marginRight: 2 }}
            >
              {link}
            </Link>
            <Box sx={{ height: 10 }} />
            <iframe
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(link)}&embedded=true`}
              width="800"
              height="800"
              style={{ border: 'none' }}
              title="PDF Viewer"
            />
          </AccordionDetails>
        </Accordion>
        <Box sx={{ height: 18 }} />
        {attachments.map((attachment: IScopingData) => (
          <ProcAttachmentAccordion key={attachment.id} attachment={attachment} />
        ))}
      </Box>
    </Modal>
  );
};
