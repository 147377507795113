import { Box, Modal } from '@mui/material';
import { capitalize } from 'lodash';
import { Dispatch, SetStateAction } from 'react';

import { ProcCommentItem } from './ProcCommentItem';
import { ICommentData } from './types';

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  comments?: ICommentData[] | undefined;
  attachments?: ICommentData[] | undefined;
}

export const ProcCommentsModal = (props: IProps) => {
  const { modalIsOpen = false, setModalIsOpen, comments = [], attachments = [] } = props;

  if (!comments?.length) {
    return null;
  }

  return (
    <Modal
      open={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false);
      }}
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 840,
          bgcolor: '#e7e3e6',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          outline: 'none',
          maxHeight: '85vh', // Set the maximum height to the viewport height
          overflowY: 'auto', // Enable vertical scrolling
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
          scrollbarWidth: 'thin', // For Firefox
          scrollbarColor: '#888 #f1f1f1', // For Firefox
        }}
      >
        <Box sx={{ height: 10 }} />
        {comments.map((comment: ICommentData) => {
          const {
            id: commentId,
            date,
            filed_by,
            description = '',
            meta_data,
            link,
            is_submitted: isSubmitted = false,
          } = comment;
          const { summary, short, long, medium } = meta_data;

          const authors = meta_data?.commenter_info?.authors || [];

          // Capitalize only first letter of description text
          const descriptionDisplay = capitalize(description);

          const commentAttachments = attachments?.filter(
            (attachment) => attachment.parent_doc_id === commentId
          );
          return (
            <>
              <ProcCommentItem
                key={commentId}
                commentId={commentId}
                date={date}
                summary={summary}
                filed_by={filed_by}
                description={descriptionDisplay}
                authors={authors}
                link={link}
                short={short}
                medium={medium}
                long={long}
                expanded={comments.length === 1}
                attachments={commentAttachments}
                isSubmitted={isSubmitted}
              />
              <Box sx={{ height: 4 }} />
            </>
          );
        })}
      </Box>
    </Modal>
  );
};
