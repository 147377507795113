/* eslint-disable no-console */
import { Grid } from '@mui/material';

import { BillSearchPanel } from './BillSearchPanel';
import { BillsList } from './BillsList';

export const BillSearchPage = () => {
  return (
    <Grid container spacing={4}>
      <Grid item sm={4}>
        <BillSearchPanel />
      </Grid>

      <Grid item sm={8}>
        <BillsList />
      </Grid>
    </Grid>
  );
};
