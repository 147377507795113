import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { AppPortal } from './components/AppPortal';
import { AuthProvider } from './components/auth/AuthContext';
import { queryClient } from './lib/query-client';
import theme from './theme';

/**
 *
 * LocalizationProvider is necessary for using MUI's date picker components
 * https://mui.com/x/react-date-pickers/getting-started/#setup-your-date-library-adapter
 */
export const App: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <AppPortal />
            </AuthProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
