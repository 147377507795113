import GavelIcon from '@mui/icons-material/Gavel';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Box, CircularProgress, Grid } from '@mui/material';

import { useGetLandingPageStatsQuery } from './api';
import { BookmarkedBillsList } from './BookmarkedBillsList';
import { BookmarkedProceedingsList } from './BookmarkedProceedingsList';
import { HeadingCard } from './HeadingCard';

export const LandingPage = () => {
  const { data: landingPageStats, isLoading: landingPageStatsAreLoading } =
    useGetLandingPageStatsQuery();

  if (landingPageStatsAreLoading) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <HeadingCard
          title="Bills"
          subtitle="CA State Legislature"
          count={landingPageStats?.bills_count}
          buttonText="Explore All"
          buttonLink="/bills"
          icon={LibraryBooksIcon}
        />
        <Box sx={{ height: 24 }} />
        <BookmarkedBillsList />
      </Grid>
      <Grid item xs={12} md={6}>
        <HeadingCard
          title="Proceedings"
          subtitle="CA Public Utilities Commission"
          count={landingPageStats?.proceedings_count}
          buttonText="Explore All"
          buttonLink="/proceedings"
          icon={GavelIcon}
        />
        <Box sx={{ height: 24 }} />
        <BookmarkedProceedingsList />
      </Grid>
    </Grid>
  );
};
