import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

type LegendItem = {
  icon: JSX.Element;
  text: string;
};

export const BillVersionChangeLegend: React.FC = () => {
  const items: LegendItem[] = [
    {
      icon: <FiberManualRecordIcon sx={{ color: 'blue' }} />,
      text: 'added',
    },
    {
      icon: <FiberManualRecordIcon sx={{ color: '#cd4c51' }} />,
      text: 'deleted',
    },
    {
      icon: <FiberManualRecordIcon sx={{ color: 'black' }} />,
      text: 'unchanged',
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
        mx: '16px',
        backgroundColor: '#d7ccd2',
        borderRadius: '6px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      }}
    >
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
            ':last-child': {
              marginRight: '0',
            },
          }}
        >
          {item.icon}
          <Typography sx={{ marginLeft: '4px' }}>- {item.text}</Typography>
        </Box>
      ))}
    </Box>
  );
};
