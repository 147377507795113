import { Box, Button } from '@mui/material';
import { useState } from 'react';

import { SanitizedHTML } from './SanitizedHTML';

interface IProps {
  html: string;
  maxLength: number;
  replaceNewLine?: boolean;
}

export const ShowMoreHTML = (props: IProps) => {
  const { html, maxLength, replaceNewLine } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const truncatedHTML = html.length > maxLength ? `${html.substring(0, maxLength)}...` : html;

  return (
    <Box>
      <SanitizedHTML html={isExpanded ? html : truncatedHTML} replaceNewLine={replaceNewLine} />
      {html.length > maxLength && (
        <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
          <Button
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </Button>
        </Box>
      )}
    </Box>
  );
};
