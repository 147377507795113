import { Box, Card, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';

import { ShowMoreHTML } from '../common/ShowMoreHTML';

interface ITabPanelProps {
  value: number;
  index: number;
  children: React.ReactNode;
}

function TabPanel(props: ITabPanelProps) {
  const { value, index, children } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface IProps {
  shortSummary: string;
  longSummary: string;
  showTitle?: boolean;
}

export const ProceedingSummaryPanel = (props: IProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { shortSummary = '', longSummary = '', showTitle = false } = props;

  if (!shortSummary && !longSummary) {
    return null;
  }

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={(_, newIndex) => setTabIndex(newIndex)}
        aria-label="doc summaries"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label="short" sx={{ textTransform: 'lowercase' }} />
        <Tab label="long" sx={{ textTransform: 'lowercase' }} />
      </Tabs>

      {shortSummary.trim() && (
        <TabPanel value={tabIndex} index={0}>
          <Card>
            {showTitle && (
              <Typography fontWeight="bold" variant="h6">
                Short Summary
              </Typography>
            )}
            <Box sx={{ height: 6 }} />
            <ShowMoreHTML html={shortSummary} maxLength={1300} replaceNewLine />
          </Card>
        </TabPanel>
      )}

      {longSummary.trim() && (
        <TabPanel value={tabIndex} index={1}>
          <Card>
            {showTitle && (
              <Typography fontWeight="bold" variant="h6">
                Long Summary
              </Typography>
            )}
            <Box sx={{ height: 6 }} />
            <ShowMoreHTML html={longSummary} maxLength={1300} replaceNewLine />
          </Card>
        </TabPanel>
      )}
    </>
  );
};
