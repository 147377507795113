import { Box, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import React, { useState } from 'react';

import { DigestBillsList } from './DigestBillsList';
import { DigestProceedingsList } from './DigestProceedingsList';
import { useAuthContext } from '../auth/AuthContext';
import { useGetDigestBookmarks } from '../bookmark/apis';
import { TabPanel } from '../common/TabPanel';

const defaultDate = new Date();
defaultDate.setDate(defaultDate.getDate() - defaultDate.getDay() - 7);
const defaultEndDate = new Date(defaultDate);
defaultEndDate.setDate(defaultEndDate.getDate() + 6);

export const DigestPage = () => {
  const theme = useTheme();
  // const [tabIndex, setTabIndex] = useState(1);

  const [selectedDate, setSelectedDate] = useState<Date>(defaultDate);

  const [digestEndDate, setDigestEndDate] = useState<Date | null>(defaultEndDate);
  const { signOut, isAuthenticated } = useAuthContext();
  const { data: bookmarkData, isLoading: bookmarksAreLoading } =
    useGetDigestBookmarks(isAuthenticated);
  // Determine initial tab index based on bookmark data
  const initialTabIndexFromBookmarkData = (numBills: number, numProcs: number) => {
    if (numBills > 0) {
      return 0;
    }
    if (numProcs > 0) {
      return 1;
    }
    return 3;
  };
  const initialTabIndex = bookmarkData
    ? initialTabIndexFromBookmarkData(
        Object.values(bookmarkData.bookmarked_bills).length,
        Object.values(bookmarkData.bookmarked_proceedings).length
      )
    : 3;

  const [tabIndex, setTabIndex] = useState(initialTabIndex);
  const handleDateChange = (date: Date | null) => {
    if (!date) {
      return;
    }
    const dayOfWeek = date.getDay();
    const startDate = new Date(date);
    startDate.setDate(date.getDate() - dayOfWeek); // Set to the previous Sunday
    setSelectedDate(startDate);

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Set to the following Saturday
    setDigestEndDate(endDate);
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{
        backgroundColor: theme.palette.primary.light,
        py: { xs: 0, sm: 0, md: 5, lg: 5 },
        borderRadius: 2,
      }}
    >
      {/* Left Column */}
      <Grid
        item
        xs={0}
        sm={0}
        md={0.5}
        lg={2}
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
      />
      {/* Main Content */}
      <Grid item xs={12} sm={12} md={11} lg={8}>
        <Box
          sx={{
            mx: '0%',
            border: '6px solid',
            borderColor: theme.palette.primary.main,
            padding: 2,
            borderRadius: 2,
            backgroundColor: '#e7e3e6',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '90%',
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ fontSize: '1em', fontWeight: '400', textAlign: 'left', mx: 1 }}>
                Select Week
              </Typography>
              <Box style={{ minWidth: '150px' }}>
                <DatePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  defaultValue={defaultDate}
                  maxDate={defaultEndDate}
                />
              </Box>
            </Box>
            <Box sx={{ flex: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography
                sx={{
                  fontSize: '2em',
                  fontWeight: '700',
                  textAlign: 'center',
                  fontFamily: 'serif',
                }}
              >
                Your Weekly Digest
              </Typography>
              <Typography variant="h6" sx={{ textAlign: 'center' }}>
                {`${selectedDate?.toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                })} - ${digestEndDate?.toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                })}`}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ height: 24 }} />
          {bookmarksAreLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Tabs
                value={tabIndex}
                onChange={(_, newValue: number) => setTabIndex(newValue)}
                aria-label="digest tabs"
              >
                <Tab label="Your Bills" />
                <Tab label="Your Proceedings" />
                <Tab label="Policy Pulse Bills" />
                <Tab label="Policy Pulse Proceedings" />
              </Tabs>

              <TabPanel value={tabIndex} index={0}>
                <DigestBillsList startDate={selectedDate.toLocaleDateString('en-CA')} />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <DigestProceedingsList startDate={selectedDate.toLocaleDateString('en-CA')} />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <DigestBillsList
                  startDate={selectedDate.toLocaleDateString('en-CA')}
                  selectedBills={bookmarkData?.bookmarked_bills}
                />
              </TabPanel>
              <TabPanel value={tabIndex} index={3}>
                <DigestProceedingsList
                  startDate={selectedDate.toLocaleDateString('en-CA')}
                  selectedProceedings={bookmarkData?.bookmarked_proceedings}
                />
              </TabPanel>
            </>
          )}
        </Box>
      </Grid>
      {/* Right Column */}
      <Grid
        item
        xs={0}
        sm={0}
        md={0.5}
        lg={2}
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
      />
    </Grid>
  );
};
