import { Grid, Typography } from '@mui/material';

const LABELS = [
  { text: 'Simple Majority', key: 'simpleMajority' },
  { text: 'Appropriation', key: 'appropriation' },
  { text: 'Fiscal Committee', key: 'fiscalCommittee' },
  { text: 'Local Program', key: 'localProgram' },
  { text: 'Urgency', key: 'urgency' },
  { text: 'Tax Levy', key: 'taxLevy' },
];

interface IProps {
  status: {
    simpleMajority: boolean;
    appropriation: boolean;
    fiscalCommittee: boolean;
    localProgram: boolean;
    urgency: boolean;
    taxLevy: boolean;
  };
}

export const BillTypeRow = (props: IProps) => {
  const { status } = props;
  return (
    <Grid container spacing={2} justifyContent="space-around">
      {LABELS.map((label) => (
        <Grid item xs key={label.key} style={{ flexGrow: 1 }}>
          <Typography
            variant="body1"
            sx={{
              color: status[label.key as keyof typeof status] ? 'primary.main' : 'grey.500',
              fontWeight: status[label.key as keyof typeof status] ? 'bold' : 'normal',
              textAlign: 'center',
            }}
          >
            {label.text}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
