import { Autocomplete, Box, Link, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { parse } from 'date-fns';
import { useState } from 'react';

import { filterBookmarkedBillsByTags, sortBills } from './utils';
import { BillCard } from '../bills/BillCard';
import { ISearchActionData, ISearchVersionData, ISearchVoteData } from '../bills/types';
import { useBookmarkContext } from '../bookmark/BookmarkContext';
import { BookmarkType, IBookmarkedBillInfo } from '../bookmark/types';

export const BookmarkedBillsList = () => {
  const { bookmarkedBills = {} } = useBookmarkContext();

  // TODO: Move this functionality to db rpc function like proceeding side
  const addCountsToBillInfo = (billInfo: IBookmarkedBillInfo): IBookmarkedBillInfo => {
    const {
      bill_session_id: billSessionId,
      bill_name,
      tags,
      bookmark_id: bookmarkId,
      headline,
      active,
      progress,
      last_view: lastView,
      versions,
      votes,
      actions,
    } = billInfo;

    const versionsCount = versions?.length || 0;
    const votesCount = votes?.length || 0;
    const actionsCount = actions?.length || 0;
    const lastViewDate = lastView ? parse(lastView, 'yyyy-MM-dd', new Date()) : new Date();

    const newVersionsCount =
      versions?.filter((version: ISearchVersionData) => {
        const versionDate = parse(version.date, 'yyyy-MM-dd', new Date());
        return versionDate > lastViewDate;
      }).length || 0;

    const newVotesCount =
      votes?.filter((vote: ISearchVoteData) => {
        const voteDate = parse(vote.date, 'yyyy-MM-dd', new Date());
        return voteDate > lastViewDate;
      }).length || 0;

    const newActionsCount =
      actions?.filter((action: ISearchActionData) => {
        const actionDate = parse(action.date, 'yyyy-MM-dd', new Date());
        return actionDate > lastViewDate;
      }).length || 0;

    return {
      bill_session_id: billSessionId,
      bill_name,
      tags,
      bookmark_id: bookmarkId,
      headline,
      active,
      progress,
      last_view: lastView,
      versions_count: versionsCount,
      votes_count: votesCount,
      actions_count: actionsCount,
      new_actions_count: newActionsCount,
      new_versions_count: newVersionsCount,
      new_votes_count: newVotesCount,
    };
  };
  const initialBookmarkedBills = Object.values(bookmarkedBills);
  const [bookmarkedBillsToShow, setBookmarkedBillsToShow] = useState<IBookmarkedBillInfo[]>(
    sortBills(initialBookmarkedBills.map(addCountsToBillInfo))
  );
  const theme = useTheme();

  const allTagsSet = new Set(
    Object.values(bookmarkedBills).flatMap((bookmarkedBill) => bookmarkedBill.tags)
  );
  const allTagOptions = Array.from(allTagsSet);

  return (
    <>
      <Typography
        sx={{ color: theme.palette.primary.main, fontWeight: 'bold', display: 'inline-block' }}
        variant="h5"
      >
        Bookmarked Bills ({bookmarkedBillsToShow.length})
      </Typography>
      <Link
        sx={{ fontWeight: 'bold', float: 'right' }}
        href={`bookmarks?bookmarkType=${BookmarkType.BILL}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Team bills
      </Link>
      <Box sx={{ height: 24 }} />
      <Autocomplete
        onChange={(e, tagsToFilterBy) => {
          const filteredBookmarkedBills = filterBookmarkedBillsByTags(
            initialBookmarkedBills,
            tagsToFilterBy
          );
          setBookmarkedBillsToShow(sortBills(filteredBookmarkedBills.map(addCountsToBillInfo)));
        }}
        multiple
        size="small"
        id="bill-tags"
        options={allTagOptions}
        getOptionLabel={(option) => option}
        defaultValue={[]}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} placeholder="Filter Bills by tags" />}
      />
      <Box sx={{ height: 24 }} />
      {bookmarkedBillsToShow.map((billInfo) => {
        const {
          bill_session_id: billSessionId,
          bill_name,
          tags,
          bookmark_id: bookmarkId,
          headline,
          active,
          progress,
          last_view: lastView,
          versions_count: versionsCount,
          votes_count: votesCount,
          actions_count: actionsCount,
          new_versions_count: newVersionsCount,
          new_votes_count: newVotesCount,
          new_actions_count: newActionsCount,
        } = billInfo;
        const lastViewText = lastView ? `Last viewed: ${lastView}` : '';
        const counterRowProps = {
          versionsCount,
          votesCount,
          actionsCount,
          newVersionsCount,
          newVotesCount,
          newActionsCount,
        };
        return (
          <>
            <BillCard
              bookmarkId={bookmarkId}
              key={billSessionId}
              billSessionId={billSessionId}
              billName={bill_name}
              tags={tags}
              tagOptions={allTagOptions}
              headline={headline}
              active={active}
              progress={progress}
              tlCornerText={lastViewText}
              showCounterRow
              counterRowProps={counterRowProps}
            />
            <Box sx={{ height: 8 }} />
          </>
        );
      })}
      <Box sx={{ height: 24 }} />
    </>
  );
};
