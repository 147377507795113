import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';

type LegendItem = {
  action: string;
  result: string;
};

export const TimelineLegend: React.FC = () => {
  const items: LegendItem[] = [
    {
      action: 'scroll',
      result: 'zoom in/out',
    },
    {
      action: 'click + drag',
      result: 'pan',
    },
    {
      action: 'click on label',
      result: 'open',
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '5px',
        paddingLeft: '10px',
        paddingRight: '10px',
        mx: '16px',
        backgroundColor: '#d7ccd2',
        borderRadius: '6px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
      }}
    >
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
            ':last-child': {
              marginRight: '0',
            },
          }}
        >
          <Typography sx={{ mx: '4px' }}>
            <span style={{ fontWeight: 'bold' }}>{item.action}</span> - {item.result}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
