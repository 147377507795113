import { ISearchActionData, ISearchVersionData, ISearchVoteData } from '../bills/types';

export interface ICreateBookmarkProps {
  billSessionId?: string;
  proceedingNumber?: string;
}

export enum BookmarkType {
  BILL = 'bill',
  PROCEEDING = 'proceeding',
}

export interface IBookmarkedBillInfo {
  bookmark_id: number;
  last_view: string; // date
  bill_name: string;
  bill_session_id: string;
  tags: string[];
  headline: string;
  active: boolean;
  progress: number[];
  versions?: ISearchVersionData[];
  votes?: ISearchVoteData[];
  actions?: ISearchActionData[];
  versions_count?: number;
  votes_count?: number;
  actions_count?: number;
  new_versions_count?: number;
  new_votes_count?: number;
  new_actions_count?: number;
}

interface IBookmarkedBillResults {
  [bill_session_id: string]: IBookmarkedBillInfo;
}

export interface IBookmarkedProceedingInfo {
  id: number;
  bookmark_id: number;
  last_view: string; // date
  number: string;
  filed_by: string;
  description: string;
  active: boolean;
  progress: number;
  tags: string[];
  scoping_count: number;
  decisions_count: number;
  rulings_count: number;
  comments_count: number;
  new_comments_count: number;
  new_decisions_count: number;
  new_rulings_count: number;
  new_scoping_count: number;
  // TODO fill this out as we add more properties to the response payload
}

interface IBookmarkedProceedingResults {
  [proceeding_number: string]: IBookmarkedProceedingInfo;
}

// Response object from GET /api/v1/bookmarks
export interface IAllBookmarkedData {
  bookmarked_bills: IBookmarkedBillResults;
  bookmarked_proceedings: IBookmarkedProceedingResults;
}

interface IBookmarksByTeamMember {
  [key: string]: {
    bill_ids: number[];
    proceeding_ids: number[];
  };
}

export interface ITeamBookmarkData {
  team_member_emails: string[];
  bookmarks_by_team_member: IBookmarksByTeamMember;
}
