/* eslint-disable no-console */
import { Box, Card, CircularProgress, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useGetBookmarkedBillsOrProcsByIds } from './apis';
import { useBookmarkContext } from './BookmarkContext';
import { BookmarkType } from './types';
import { BillCard } from '../bills/BillCard';
import { ProceedingCard } from '../proceedings/ProceedingCard';

export const TeamBookmarksPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bookmarkType = searchParams.get('bookmarkType') || BookmarkType.BILL;

  const { teamBookmarkData } = useBookmarkContext();
  const { team_member_emails: teamMemberEmails, bookmarks_by_team_member: bookmarksByTeamMember } =
    teamBookmarkData;
  const [billOrProcIds, setBillOrProcIds] = useState<number[]>([]);

  const {
    refetch,
    data: billOrProcData,
    isLoading: bookmarkDataIsLoading,
  } = useGetBookmarkedBillsOrProcsByIds(bookmarkType, billOrProcIds);

  return (
    <Grid container spacing={4}>
      <Grid item sm={4}>
        <Card sx={{ p: 3, mb: 3, backgroundColor: 'transparent' }}>
          <InputLabel>Team member</InputLabel>
          <Select
            sx={{ height: 40 }}
            defaultValue=""
            onChange={(e) => {
              const email = e.target.value;
              const bookmarksForTeamMember = bookmarksByTeamMember?.[email];
              // Get the bills or proceedings for the user
              if (bookmarkType === BookmarkType.BILL) {
                setBillOrProcIds(bookmarksForTeamMember?.bill_ids);
              }

              if (bookmarkType === BookmarkType.PROCEEDING) {
                setBillOrProcIds(bookmarksForTeamMember?.proceeding_ids);
              }

              refetch();
            }}
          >
            {teamMemberEmails?.map((email) => {
              return (
                <MenuItem key={email} value={email}>
                  {email}
                </MenuItem>
              );
            })}
          </Select>
          <Box sx={{ height: 16 }} />
        </Card>
      </Grid>
      <Grid item sm={8}>
        {bookmarkDataIsLoading ? (
          <CircularProgress />
        ) : (
          <>
            {bookmarkType === BookmarkType.BILL && (
              <>
                {billOrProcData?.map((billData: any) => {
                  // TODO: use proper types
                  const {
                    bill_name: billName,
                    bill_session_id: billSessionId,
                    lead_authors: leadAuthors,
                    headline,
                    active,
                    progress,
                    tl_corner_text: tlCornerText,
                  } = billData;
                  return (
                    <BillCard
                      key={billSessionId}
                      billName={billName}
                      billSessionId={billSessionId}
                      leadAuthors={leadAuthors}
                      headline={headline}
                      active={active}
                      progress={progress}
                      tlCornerText={tlCornerText}
                    />
                  );
                })}
              </>
            )}

            {bookmarkType === BookmarkType.PROCEEDING && (
              <>
                {billOrProcData.map((procData: any) => {
                  // TODO: use proper types
                  const {
                    number: procNumber,
                    filed_by: filedBy,
                    description: headline,
                    active,
                    progress,
                    scoping_count: scopingCount,
                    decisions_count: decisionsCount,
                    rulings_count: rulingsCount,
                    comments_count: commentsCount,
                  } = procData;
                  return (
                    <ProceedingCard
                      procNumber={procNumber}
                      showBookmarkIcon
                      key={procNumber}
                      filedBy={filedBy}
                      headline={headline}
                      tags={[]}
                      active={active}
                      progress={progress}
                      showCounterRow
                      counterRowProps={{
                        isNew: false,
                        showNewIndicator: false,
                        scopingCount,
                        decisionsCount,
                        rulingsCount,
                        commentsCount,
                      }}
                    />
                  );
                })}
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
