import { Autocomplete, Box, Button, Chip, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { useGetBookmarks } from './apis';
import { updateTagsForBookmark } from '../tags/api';

interface IProps {
  bookmarkId: number;
  tags: string[];
  tagOptions: string[];
}

export const UpdateBookmarkTagsContainer: React.FC<IProps> = (props) => {
  const { tags: initialTags = [], tagOptions, bookmarkId } = props;
  const [tags, setTags] = useState(initialTags);
  const [tagEditingIsEnabled, setTagEditingIsEnabled] = useState(false);
  const [addTagInputValue, setAddTagInputValue] = useState('');
  const buttonText = tags.length > 0 ? 'Edit tags' : 'Add tags';
  const { refetch: refetchBookmarks } = useGetBookmarks();
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          {tags.map((currentTag) => (
            <Chip
              onDelete={
                tagEditingIsEnabled
                  ? () => {
                      const updatedTags = tags.filter((tag) => tag !== currentTag);
                      setTags(updatedTags);
                      updateTagsForBookmark(updatedTags, bookmarkId);
                      refetchBookmarks();
                    }
                  : undefined
              }
              key={currentTag}
              sx={{
                backgroundColor: '#5a2544',
                color: 'white',
                borderRadius: '7px',
                marginLeft: '.5em',
                marginTop: '.5em',
                height: '24px',
              }}
              label={
                <Typography variant="caption" fontWeight="bold">
                  {currentTag}
                </Typography>
              }
            />
          ))}
        </Box>
        <Button
          sx={{ height: '3' }}
          onClick={() => {
            setTagEditingIsEnabled(!tagEditingIsEnabled);
          }}
        >
          {tagEditingIsEnabled ? 'Done' : buttonText}
        </Button>
      </Box>

      <Box sx={{ height: 24 }} />
      <Box>
        {tagEditingIsEnabled && (
          <>
            <Autocomplete
              onInputChange={(_, value) => {
                setAddTagInputValue(value);
              }}
              value={addTagInputValue}
              freeSolo
              size="small"
              disableClearable
              disablePortal
              id="add-tag-input"
              options={tagOptions}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} />}
            />
            <Button
              sx={{ my: '10px' }}
              onClick={() => {
                if (addTagInputValue === '' || tags.includes(addTagInputValue)) {
                  return;
                }
                const newTags = [...tags, addTagInputValue];
                setTags(newTags);
                setAddTagInputValue('');
                updateTagsForBookmark(newTags, bookmarkId);
                refetchBookmarks();
              }}
            >
              Add tag
            </Button>
          </>
        )}
      </Box>
    </>
  );
};
