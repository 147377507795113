import NorthEastIcon from '@mui/icons-material/NorthEast';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Box, Card, CardActions, Chip, Link, Typography } from '@mui/material';

import { ProceedingCardCounterRow } from './ProceedingCardCounterRow';
import { IProceedingCardProps } from './types';
import { BookmarkIcon } from '../bookmark/BookmarkIcon';
import { UpdateBookmarkTagsContainer } from '../bookmark/UpdateBookmarkTagsContainer';

export const ProceedingCard = (props: IProceedingCardProps) => {
  const {
    procNumber,
    active,
    headline,
    filedBy,
    progress,
    tlCornerText,
    bookmarkId,
    tags,
    tagOptions,
    showBookmarkIcon,
    showCounterRow,
    counterRowProps,
  } = props;
  const activeChipBackgroundColor = active ? '#008800' : '#555555';
  const progressTextLookup: Record<number, string> = {
    0: 'Not Started',
    1: 'OIR',
    2: 'Scoping',
    3: 'Proposed Decisions',
    4: 'Final Decisions',
    5: 'Closed',
  };

  const progressStatusColor = '#555555';
  const progressText = progressTextLookup[progress?.valueOf() || 0];
  const proceedingNumberText = `CA > ${procNumber}`;
  return (
    <Card key={procNumber} sx={{ p: 2, mb: 1 }}>
      <Box>
        {showBookmarkIcon && (
          <BookmarkIcon proceedingNumber={procNumber} iconStyles={{ fontSize: '28px' }} />
        )}
        <Typography sx={{ fontWeight: 'bold', display: 'inline-block' }}>
          {proceedingNumberText}
        </Typography>
        <Chip
          sx={{
            backgroundColor: activeChipBackgroundColor,
            color: 'white',
            borderRadius: '7px',
            marginLeft: '1em',
          }}
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {active ? 'Active' : 'Inactive'}
            </Typography>
          }
        />
        <Chip
          sx={{
            backgroundColor: progressStatusColor,
            color: 'white',
            borderRadius: '7px',
            marginLeft: '1em',
          }}
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {progressText}
            </Typography>
          }
        />

        <Typography sx={{ display: 'inline-block', float: 'right' }}>
          {tlCornerText || ''}
        </Typography>
      </Box>

      <Box sx={{ height: 8 }} />
      {(!tags || !bookmarkId || !tagOptions) && <Box sx={{ my: 1 }} />}
      {tags && bookmarkId && tagOptions && (
        <UpdateBookmarkTagsContainer bookmarkId={bookmarkId} tags={tags} tagOptions={tagOptions} />
      )}
      <Typography
        sx={{
          my: '-10px',
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
        }}
      >
        {headline || 'Missing headline'}
      </Typography>
      <Box sx={{ height: 10 }} />
      {(!tags || !bookmarkId || !tagOptions) && <Box sx={{ my: 1 }} />}
      <CardActions sx={{ justifyContent: 'space-between', padding: 0.5 }}>
        {filedBy && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <PersonOutlineOutlinedIcon sx={{ marginRight: 1, color: '#eecc87' }} />
            <Typography fontWeight="bold">{filedBy}</Typography>
          </Box>
        )}
        {showCounterRow && (
          <ProceedingCardCounterRow
            isNew={
              !!(
                counterRowProps?.newScopingCount ||
                counterRowProps?.newDecisionsCount ||
                counterRowProps?.newRulingsCount ||
                counterRowProps?.newCommentsCount
              )
            }
            showNewIndicator={counterRowProps?.showNewIndicator || false}
            scopingCount={counterRowProps?.scopingCount || 0}
            decisionsCount={counterRowProps?.decisionsCount || 0}
            rulingsCount={counterRowProps?.rulingsCount || 0}
            commentsCount={counterRowProps?.commentsCount || 0}
            newScopingCount={counterRowProps?.newScopingCount || 0}
            newDecisionsCount={counterRowProps?.newDecisionsCount || 0}
            newRulingsCount={counterRowProps?.newRulingsCount || 0}
            newCommentsCount={counterRowProps?.newCommentsCount || 0}
          />
        )}
        <Link
          sx={{ fontWeight: 'bold' }}
          href={`proceedings/${procNumber}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box display="flex" alignItems="center">
            View <NorthEastIcon sx={{ marginLeft: 0.25, paddingTop: 0 }} />
          </Box>
        </Link>
      </CardActions>
    </Card>
  );
};
