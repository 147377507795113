/* eslint-disable no-console */

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {
  Alert,
  Box,
  Button,
  Container,
  Drawer,
  Fab,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import remarkGfm from 'remark-gfm';

import { useChatResponse } from './apis';
import { IChatHistoryItem, IChatRequestData } from './types';

export const AssistantButton = () => {
  const { billSessionId } = useParams();
  const [aiChatIsOpen, setAiChatIsOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [chatHistory, setChatHistory] = useState<IChatHistoryItem[]>([]);
  const {
    mutateAsync: chatResponseMutation,
    isLoading: questionIsBeingProcessed,
    isError,
  } = useChatResponse();

  const chatEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Scroll to bottom
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const handleSendQuestion = async () => {
    if (!currentQuestion.trim()) {
      return; // Prevent sending empty questions
    }
    const questionData: IChatRequestData = {
      question: currentQuestion,
      bill_session_id: billSessionId,
      history: chatHistory,
    };
    try {
      const response = await chatResponseMutation(questionData);
      const responseObject = JSON.parse(JSON.stringify(response));
      const responseString = responseObject?.payload?.response;
      setChatHistory([{ question: currentQuestion, response: responseString }, ...chatHistory]); // Update chat history
      setCurrentQuestion(''); // Clear the current question
    } catch {
      // no-op, show error alert to user via mutation prop
    }
  };

  return (
    <>
      <Fab
        variant="extended"
        color="primary"
        aria-label="ai-assistant"
        style={{
          position: 'fixed',
          bottom: 48,
          right: 48,
          textTransform: 'none',
        }}
        onClick={() => {
          setAiChatIsOpen(!aiChatIsOpen);
        }}
      >
        <Typography variant="body1">✨ Try Our AI Assistant</Typography>
      </Fab>
      <Drawer
        sx={{
          width: 480,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 480,
          },
        }}
        variant="persistent"
        anchor="right"
        open={aiChatIsOpen}
        onClose={() => setAiChatIsOpen(false)}
      >
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            padding: '16px',
          }}
        >
          {isError && <Alert severity="error">Sorry, something went wrong.</Alert>}
          <Box sx={{ height: 24 }} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '16px',
            }}
          >
            <Typography variant="h5">AI Assistant</Typography>
            <IconButton
              color="primary"
              sx={{
                color: 'black',
              }}
              onClick={() => setAiChatIsOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ height: 2 }} />
          <Box
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              marginBottom: '16px',
            }}
          >
            {chatHistory
              .slice()
              .reverse()
              .map((element, idx) => {
                return (
                  <div
                    key={idx}
                    style={{ display: 'flex', flexDirection: 'column', marginBottom: '16px' }}
                  >
                    <Box
                      sx={{
                        alignSelf: 'flex-start',
                        backgroundColor: '#e7e3e6',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        maxWidth: '85%',
                        marginBottom: '8px',
                      }}
                    >
                      <Typography key={`${element}-${idx.toString()}-q`} variant="body1">
                        {element.question}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        alignSelf: 'flex-end',
                        backgroundColor: '#FFDAB9',
                        borderRadius: '10px',
                        padding: '8px 16px',
                        maxWidth: '85%',
                      }}
                    >
                      <Markdown key={`${element}-${idx.toString()}-a`} remarkPlugins={[remarkGfm]}>
                        {element.response}
                      </Markdown>
                    </Box>
                  </div>
                );
              })}
            <div ref={chatEndRef} />
          </Box>
          <Button
            variant="contained"
            sx={{
              color: 'black',
              margin: '12px',
              '&:hover': {
                backgroundColor: 'primary', // Change this to your desired hover color
                color: 'white',
              },
            }}
            onClick={() => setChatHistory([])}
          >
            Clear History
          </Button>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Ask a question"
              value={currentQuestion}
              onChange={(e) => setCurrentQuestion(e.target.value)}
              disabled={questionIsBeingProcessed}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendQuestion();
                }
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    disabled={questionIsBeingProcessed}
                    color="primary"
                    onClick={() => {
                      handleSendQuestion();
                    }}
                  >
                    {questionIsBeingProcessed ? 'Thinking...' : <SendIcon />}
                  </IconButton>
                ),
              }}
            />
            <Box sx={{ height: 24 }} />
          </Box>
        </Container>
      </Drawer>
    </>
  );
};
