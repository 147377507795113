import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { parse } from 'date-fns';
import { useState } from 'react';

import { addCountsToBillInfo, sortAndFilterDigestBills } from './utils';
import { BillCard } from '../bills/BillCard';
import { useBookmarkContext } from '../bookmark/BookmarkContext';
import { IBookmarkedBillInfo } from '../bookmark/types';
import { filterBookmarkedBillsByTags } from '../landing/utils';

interface IProps {
  startDate: string;
  selectedBills?: Record<string, IBookmarkedBillInfo>;
}

export const DigestBillsList = (props: IProps) => {
  const { startDate, selectedBills } = props;
  const { bookmarkedBills = {} } = useBookmarkContext();
  const initialBookmarkedBills = selectedBills
    ? Object.values(selectedBills)
    : Object.values(bookmarkedBills);
  const digestStartDate = parse(startDate, 'yyyy-MM-dd', new Date());
  const digestEndDate = new Date(digestStartDate);
  digestEndDate.setDate(digestEndDate.getDate() + 6);

  const updatedBookmarkedBills = sortAndFilterDigestBills(
    initialBookmarkedBills.map((bill) => addCountsToBillInfo(bill, digestStartDate, digestEndDate))
  );

  const [tagsToFilterBy, setTagsToFilterBy] = useState<string[]>([]);
  const allTagsSet = new Set(
    Object.values(bookmarkedBills).flatMap((bookmarkedBill) => bookmarkedBill.tags)
  );
  const allTagOptions = Array.from(allTagsSet);
  let message = null;
  if (selectedBills) {
    if (updatedBookmarkedBills.length === 0) {
      message = (
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          No Updates to Display for Selected Week
        </Typography>
      );
    }
  } else if (Object.values(initialBookmarkedBills).length === 0) {
    message = (
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        No Bookmarked Bills Yet
      </Typography>
    );
  } else if (updatedBookmarkedBills.length === 0) {
    message = (
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        No Updates to Display for Selected Week
      </Typography>
    );
  }

  return (
    <>
      {/* List of hyperlinks to each bill */}
      <Box>
        {message}
        {(tagsToFilterBy.length > 0
          ? filterBookmarkedBillsByTags(updatedBookmarkedBills, tagsToFilterBy)
          : updatedBookmarkedBills
        ).map((billInfo) => {
          const {
            bill_session_id: billSessionId,
            bill_name: billName,
            headline,
            new_versions_count: newVersionsCount,
            new_votes_count: newVotesCount,
            new_actions_count: newActionsCount,
          } = billInfo;

          // Construct the counts text
          const countsText = `${(newActionsCount ?? 0) > 0 ? `Actions: ${newActionsCount} ` : ''}${
            (newVersionsCount ?? 0) > 0 ? `Versions: ${newVersionsCount} ` : ''
          }${(newVotesCount ?? 0) > 0 ? `Votes: ${newVotesCount}` : ''}`;

          return (
            <Box sx={{ mx: '5%' }}>
              <Link
                key={billSessionId}
                href={`${billSessionId}`}
                sx={{
                  display: 'block',
                  mb: 1,
                  backgroundColor: 'white',
                  padding: 2,
                  color: 'black',
                  borderRadius: 1,
                  boxShadow: 1,
                  transition: 'background-color 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    backgroundColor: 'lightgray',
                    boxShadow: 3,
                  },
                  cursor: 'pointer',
                }}
                style={{ fontSize: '20px', textDecoration: 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  const element = document.getElementById(`${billSessionId}`);
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                <Typography sx={{ fontSize: '.85em', fontWeight: 'bold' }}>
                  {billName} - {countsText}
                </Typography>
                <Typography sx={{ fontFamily: 'serif', fontSize: '.75em' }}>{headline}</Typography>
              </Link>
              <Box sx={{ height: 6 }} />
            </Box>
          );
        })}
      </Box>
      <Box sx={{ height: 24 }} />
      <Autocomplete
        onChange={(e, _tagsToFilterBy) => {
          setTagsToFilterBy(_tagsToFilterBy);
        }}
        multiple
        size="small"
        id="bill-tags"
        options={allTagOptions}
        getOptionLabel={(option) => option}
        defaultValue={[]}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} placeholder="Filter Bills by tags" />}
      />
      <Box sx={{ height: 24 }} />
      <Grid container spacing={2}>
        {(tagsToFilterBy.length > 0
          ? filterBookmarkedBillsByTags(updatedBookmarkedBills, tagsToFilterBy)
          : updatedBookmarkedBills
        ).map((billInfo) => {
          const {
            bill_session_id: billSessionId,
            bill_name,
            tags,
            bookmark_id: bookmarkId,
            headline,
            active,
            progress,
            actions,
            versions_count: versionsCount,
            votes_count: votesCount,
            actions_count: actionsCount,
            new_versions_count: newVersionsCount,
            new_votes_count: newVotesCount,
            new_actions_count: newActionsCount,
          } = billInfo;

          const counterRowProps = {
            versionsCount,
            votesCount,
            actionsCount,
            newVersionsCount,
            newVotesCount,
            newActionsCount,
          };

          return (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              key={billSessionId}
              id={billSessionId}
              sx={{ display: 'flex' }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  // TODO: Fix this to be responsive in BillCard
                  minWidth: '600px',
                }}
              >
                <BillCard
                  bookmarkId={bookmarkId}
                  billSessionId={billSessionId}
                  billName={bill_name}
                  tags={tags}
                  tagOptions={allTagOptions}
                  headline={headline}
                  active={active}
                  progress={progress}
                  tlCornerText=""
                  showCounterRow
                  counterRowProps={counterRowProps}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  style={{ minHeight: '210px' }}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Date</b>
                          </TableCell>
                          <TableCell>
                            <b>Description</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {actions?.map((action) => (
                          <TableRow>
                            <TableCell>{action.date}</TableCell>
                            <TableCell>{action.desc_ai}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Button
                      variant="contained"
                      // color="primary"
                      sx={{
                        color: 'white', // Text color
                        backgroundColor: 'primary.main', // Background color
                        '&:hover': {
                          backgroundColor: 'primary.light', // Background color on hover
                        },
                        '&:active': {
                          backgroundColor: 'primary.light', // Background color on active
                        },
                      }}
                      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    >
                      Back to Top
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Box sx={{ height: 24 }} />
    </>
  );
};
