import { UseQueryOptions, useMutation, useQuery } from 'react-query';

import { apiClient } from '../../lib/apiClient';
import { ApiError } from '../../lib/types';

export interface ISignInProps {
  email: string;
  password: string;
}

export const useSignIn = () => {
  return useMutation(async (payload: ISignInProps) => {
    return apiClient.post('/api/v1/sign_in', { json: payload });
  });
};

export const useSignOut = () => {
  return useMutation(async () => {
    return apiClient.delete('/api/v1/sign_out');
  });
};

export const useAuthSession = (queryOptions: UseQueryOptions<undefined, ApiError>) => {
  return useQuery<undefined, ApiError>(['/api/v1/accounts'], {
    ...queryOptions,
  });
};
