import { Box, Modal, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';

import { IActionData } from './types';

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  action: IActionData | undefined;
}

export const BillActionModal = (props: IProps) => {
  const { modalIsOpen = false, setModalIsOpen, action } = props;
  if (!action) {
    return null;
  }

  return (
    <Modal
      open={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false);
      }}
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: '#e7e3e6',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {format(action.date, 'MMMM d, yyyy')}
        </Typography>
        <Box sx={{ height: 18 }} />
        {action.desc}
      </Box>
    </Modal>
  );
};
