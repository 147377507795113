import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Typography,
} from '@mui/material';

import { IVoteData, VoteResult } from './types';

interface IProps {
  vote: IVoteData;
  defaultExpanded?: boolean;
}

export const BillVoteHistoryItem = (props: IProps) => {
  const { vote, defaultExpanded = false } = props;
  const {
    vote_id,
    date,
    result,
    location,
    ayes_count,
    noes_count,
    nvr_count,
    motion,
    ayes_names_array,
    noes_names_array,
    nvr_names_array,
  } = vote;

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      disableGutters
      sx={{ marginLeft: '16px', marginRight: '16px' }}
      elevation={0}
      key={`vote-${vote_id.toString()}`}
    >
      <AccordionSummary>
        <Grid container justifyContent="space-between">
          <Grid item>
            {result === VoteResult.PASS && (
              <Chip
                sx={{ backgroundColor: '#1aa412', color: 'white' }}
                label={
                  <Typography variant="body1" fontWeight="bold">
                    Pass
                  </Typography>
                }
              />
            )}
            {result === VoteResult.FAIL && (
              <Chip
                sx={{ backgroundColor: '#cd4c51', color: 'white' }}
                label={
                  <Typography variant="body1" fontWeight="bold">
                    Fail
                  </Typography>
                }
              />
            )}
            <Box sx={{ display: 'inline', margin: 2 }} />
            <Chip
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccessTimeOutlinedIcon sx={{ color: '#e0b768', marginRight: 1 }} />{' '}
                  <Typography variant="body1" fontWeight="bold">
                    {date}
                  </Typography>
                </Box>
              }
            />
            <Box sx={{ display: 'inline', margin: 2 }} />
            <Typography variant="body1" fontWeight="bold" sx={{ display: 'inline' }}>
              {location}
            </Typography>
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckIcon sx={{ color: '#1aa412' }} />
              <Typography variant="body1">{ayes_count}</Typography>
              <CloseIcon sx={{ color: '#cd4c51', marginLeft: 3 }} />
              <Typography variant="body1">{noes_count}</Typography>
              <BlockIcon sx={{ color: '#e0b768', marginLeft: 3 }} />
              <Typography variant="body1">{nvr_count}</Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <Typography component="span" fontWeight="bold">
            Motion:
          </Typography>
          &nbsp;{motion}
        </Typography>
        <Box sx={{ height: 12 }} />
        <Typography>
          <Typography component="span" fontWeight="bold">
            Ayes:
          </Typography>
          &nbsp; {ayes_count ? ayes_names_array.join(', ') : 'None'}
        </Typography>
        <Box sx={{ height: 12 }} />
        <Typography>
          <Typography component="span" fontWeight="bold">
            Noes:
          </Typography>
          &nbsp; {noes_count ? noes_names_array.join(', ') : 'None'}
        </Typography>
        <Box sx={{ height: 12 }} />
        <Typography>
          <Typography component="span" fontWeight="bold">
            NVR:
          </Typography>
          &nbsp; {nvr_count ? nvr_names_array.join(', ') : 'None'}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
