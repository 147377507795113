import { orderBy, sumBy } from 'lodash';

import { IBookmarkedBillInfo, IBookmarkedProceedingInfo } from '../bookmark/types';

// TODO: Combine since this is repeated code
export const filterBookmarkedBillsByTags = (
  bookmarkedBills: IBookmarkedBillInfo[],
  tags: string[]
): IBookmarkedBillInfo[] => {
  if (!tags || tags.length === 0) {
    return bookmarkedBills;
  }

  return bookmarkedBills.filter((bill) => {
    return tags.some((tag) => bill.tags.includes(tag));
  });
};

export const filterBookmarkedProcsByTags = (
  bookmarkedProcs: IBookmarkedProceedingInfo[],
  tags: string[]
): IBookmarkedProceedingInfo[] => {
  if (!tags || tags.length === 0) {
    return bookmarkedProcs;
  }

  return bookmarkedProcs.filter((proc) => {
    return tags.some((tag) => proc.tags.includes(tag));
  });
};

export const sortProcs = (procs: IBookmarkedProceedingInfo[]) => {
  return orderBy(
    procs,
    [
      (proc) =>
        sumBy(
          [
            proc.new_scoping_count,
            proc.new_decisions_count,
            proc.new_rulings_count,
            proc.new_comments_count,
          ],
          (count) => count ?? 0
        ),
      (proc) => new Date(proc.last_view).getTime(),
    ],
    ['desc', 'desc']
  );
};

export const sortBills = (bills: IBookmarkedBillInfo[]) => {
  return orderBy(
    bills,
    [
      (bill) =>
        sumBy(
          [bill.new_versions_count, bill.new_votes_count, bill.new_actions_count],
          (count) => count ?? 0
        ), // Total new counts
      (bill) => new Date(bill.last_view).getTime(), // Last view time
    ],
    ['desc', 'desc'] // Sort both in descending order
  );
};
