import FeedIcon from '@mui/icons-material/Feed';
import GavelIcon from '@mui/icons-material/Gavel';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { AppBar, Box, Button, CircularProgress, Container, Divider, Toolbar } from '@mui/material';
import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useAuthContext } from './auth/AuthContext';
import { RequireAuth } from './auth/RequireAuth';
import { SignInPage } from './auth/SignInPage';
import { BillPage } from './bills/BillPage';
import { BillSearchPage } from './bills/BillSearchPage';
import { useGetBookmarks, useGetTeamBookmarks } from './bookmark/apis';
import { BookmarkProvider } from './bookmark/BookmarkContext';
import { TeamBookmarksPage } from './bookmark/TeamBookmarksPage';
import { DigestPage } from './digests/DigestPage';
import { LandingPage } from './landing/LandingPage';
import { LogoImage } from './LogoImage';
import { ProceedingPage } from './proceedings/ProceedingPage';
import { ProceedingSearchPage } from './proceedings/ProceedingSearchPage';

const SELECTED_NAV_BUTTON_STYLES = {
  mr: 2,
  backgroundColor: '#5a2544',
  color: 'white',
  paddingX: 3,
  paddingY: 1,
  borderRadius: '7px',
  '&:hover': {
    backgroundColor: '#5a2544',
    color: 'white',
    boxShadow: 'none',
  },
};

const NAV_BUTTON_STYLES = {
  mr: 2,
  backgroundColor: 'transparent',
  color: '#5a2544',
  paddingX: 3,
  paddingY: 1,
  borderRadius: '7px',
  '&:hover': {
    backgroundColor: 'transparent',
    color: '#5a2544',
    boxShadow: 'none',
  },
};

export const AppPortal: React.FC = () => {
  const { signOut, isAuthenticated } = useAuthContext();
  const { data: bookmarkData, isLoading: bookmarksAreLoading } = useGetBookmarks(isAuthenticated);
  const { data: teamBookmarkData, isLoading: teamBookmarksAreLoading } =
    useGetTeamBookmarks(isAuthenticated);

  const navigate = useNavigate();

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Box
      sx={{
        backgroundColor: '#e7e3e6',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl">
        <AppBar
          position="static"
          sx={{ boxShadow: 'none', border: 'none', paddingBottom: 3, paddingTop: 4 }}
        >
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <LogoImage />
            {isAuthenticated && (
              <>
                <Box display="flex" alignItems="center" sx={{ flexGrow: 1, ml: 5 }}>
                  {location.pathname !== '/' && ( // Show the nav bar items everywhere but landing page
                    <>
                      <Button
                        onClick={() => navigate('/bills')}
                        sx={
                          currentPath.includes('/bills')
                            ? SELECTED_NAV_BUTTON_STYLES
                            : NAV_BUTTON_STYLES
                        }
                      >
                        <LibraryBooksIcon sx={{ marginRight: 1 }} />
                        Bills
                      </Button>
                      <Button
                        onClick={() => navigate('/proceedings')}
                        sx={
                          currentPath.includes('/proceedings')
                            ? SELECTED_NAV_BUTTON_STYLES
                            : NAV_BUTTON_STYLES
                        }
                      >
                        <GavelIcon sx={{ marginRight: 1 }} />
                        Proceedings
                      </Button>
                    </>
                  )}
                  <Button
                    onClick={() => navigate('/digests')}
                    sx={
                      currentPath.includes('/digests')
                        ? SELECTED_NAV_BUTTON_STYLES
                        : NAV_BUTTON_STYLES
                    }
                  >
                    <FeedIcon sx={{ marginRight: 1 }} />
                    Digests
                  </Button>
                </Box>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={async () => {
                    await signOut();
                  }}
                >
                  Log out
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>
        <Divider />
        <Box sx={{ height: 36 }} />
        {bookmarksAreLoading || teamBookmarksAreLoading ? (
          <CircularProgress />
        ) : (
          <BookmarkProvider bookmarkData={bookmarkData} teamBookmarkData={teamBookmarkData}>
            <Routes>
              <Route path="/sign-in" element={<SignInPage />} />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <LandingPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/bills"
                element={
                  <RequireAuth>
                    <BillSearchPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/bills/:billSessionId"
                element={
                  <RequireAuth>
                    <BillPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/proceedings"
                element={
                  <RequireAuth>
                    <ProceedingSearchPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/proceedings/:proceedingNumber"
                element={
                  <RequireAuth>
                    <ProceedingPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/bookmarks"
                element={
                  <RequireAuth>
                    <TeamBookmarksPage />
                  </RequireAuth>
                }
              />
              <Route
                path="/digests"
                element={
                  <RequireAuth>
                    <DigestPage />
                  </RequireAuth>
                }
              />
            </Routes>
          </BookmarkProvider>
        )}
      </Container>
    </Box>
  );
};
