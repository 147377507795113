import { parse } from 'date-fns';
import { orderBy, sumBy } from 'lodash';

import { ISearchActionData, ISearchVersionData, ISearchVoteData } from '../bills/types';
import { IBookmarkedBillInfo } from '../bookmark/types';

export const sortAndFilterDigestBills = (bills: IBookmarkedBillInfo[]) => {
  // Filter out bills where the sum of new counts is zero
  const filteredBills = bills.filter((bill) => {
    const totalNewCounts = sumBy(
      [bill.new_versions_count, bill.new_votes_count, bill.new_actions_count],
      (count) => count ?? 0
    );
    return totalNewCounts > 0;
  });

  // Sort the remaining bills
  return orderBy(
    filteredBills,
    [
      (bill) =>
        sumBy(
          [bill.new_versions_count, bill.new_votes_count, bill.new_actions_count],
          (count) => count ?? 0
        ), // Total new counts
    ],
    ['desc'] // Sort in descending order
  );
};

export const addCountsToBillInfo = (
  billInfo: IBookmarkedBillInfo,
  digestStartDate: Date,
  digestEndDate: Date
): IBookmarkedBillInfo => {
  const {
    bill_session_id: billSessionId,
    bill_name,
    tags,
    bookmark_id: bookmarkId,
    headline,
    active,
    progress,
    versions,
    votes,
    actions,
  } = billInfo;

  const versionsCount = versions?.length || 0;
  const votesCount = votes?.length || 0;
  const actionsCount = actions?.length || 0;
  const newVersionsCount =
    versions?.filter((version: ISearchVersionData) => {
      const versionDate = parse(version.date, 'yyyy-MM-dd', new Date());
      return (
        versionDate >= digestStartDate &&
        versionDate <= digestEndDate &&
        version.title !== 'Enrolled' &&
        version.title !== 'Chaptered'
      );
    }).length || 0;

  const newVotesCount =
    votes?.filter((vote: ISearchVoteData) => {
      const voteDate = parse(vote.date, 'yyyy-MM-dd', new Date());
      return voteDate >= digestStartDate && voteDate <= digestEndDate;
    }).length || 0;

  const filteredActions = actions?.filter((action: ISearchActionData) => {
    const actionDate = parse(action.date, 'yyyy-MM-dd', new Date());
    return actionDate >= digestStartDate && actionDate <= digestEndDate;
  });

  const newActionsCount = filteredActions?.length || 0;

  return {
    bill_session_id: billSessionId,
    bill_name,
    tags,
    bookmark_id: bookmarkId,
    headline,
    active,
    actions: filteredActions,
    progress,
    last_view: '',
    versions_count: versionsCount,
    votes_count: votesCount,
    actions_count: actionsCount,
    new_actions_count: newActionsCount,
    new_versions_count: newVersionsCount,
    new_votes_count: newVotesCount,
  };
};
