import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { capitalize, set } from 'lodash';
import { useState } from 'react';

import { useGetProcDigests } from './apis';
import { IProcDigestItem } from './types';
import { useBookmarkContext } from '../bookmark/BookmarkContext';
import { IBookmarkedProceedingInfo } from '../bookmark/types';
import { ShowMoreHTML } from '../common/ShowMoreHTML';
import { filterBookmarkedProcsByTags } from '../landing/utils';
import { ProceedingCard } from '../proceedings/ProceedingCard';
import { IProceedingCounterRowProps } from '../proceedings/types';

const cleanDescription = (description: string): string => {
  const cleaned = description.replace(/^Order Instituting Rulemaking to /i, '');
  return cleaned.length > 200 ? `${cleaned.substring(0, 200)}...` : cleaned;
};

const sortProcs = (proc: IBookmarkedProceedingInfo[], digests: IProcDigestItem[]) => {
  // filter out procs with no digests
  const procWithDigests = proc.filter((p) => digests.some((d) => d.proc_id === p.id));
  // sort by doc counts from digests
  return procWithDigests.sort((a, b) => {
    const aDocCount = digests
      .filter((d) => d.proc_id === a.id)
      .reduce((acc, d) => acc + d.doc_count, 0);
    const bDocCount = digests
      .filter((d) => d.proc_id === b.id)
      .reduce((acc, d) => acc + d.doc_count, 0);
    return bDocCount - aDocCount;
  });
};
interface IProps {
  startDate: string;
  selectedProceedings?: Record<string, IBookmarkedProceedingInfo>;
}
export const DigestProceedingsList = (props: IProps) => {
  const { startDate, selectedProceedings } = props;
  const { bookmarkedProceedings = {} } = useBookmarkContext();
  const initialBookmarkedProcs = selectedProceedings
    ? Object.values(selectedProceedings)
    : Object.values(bookmarkedProceedings);

  // Extract all "id" field values from initialBookmarkedProcs
  const procIds = Object.values(initialBookmarkedProcs).map((proc) => proc.id);
  const { data, isLoading, error } = useGetProcDigests(startDate, procIds);
  const [bookmarkedProcsToShow, setBookmarkedProcsToShow] =
    useState<IBookmarkedProceedingInfo[]>(initialBookmarkedProcs);
  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <Typography>There was an error loading your digests</Typography>;
  }
  if (!data) {
    return <Typography>No digests are available for the selected dates</Typography>;
  }

  const allTagsSet = new Set(
    Object.values(bookmarkedProceedings).flatMap((bookmarkedProc) => bookmarkedProc.tags)
  );

  const allTagOptions = Array.from(allTagsSet);

  // Group procDigests by proc_id
  const procDigestsById = data.reduce(
    (acc, digest) => {
      if (!acc[digest.proc_id]) {
        acc[digest.proc_id] = [];
      }
      acc[digest.proc_id].push(digest);
      return acc;
    },
    {} as Record<string, IProcDigestItem[]>
  );
  const displayedProcs = sortProcs(bookmarkedProcsToShow, data);
  let message = null;

  if (selectedProceedings) {
    if (displayedProcs.length === 0) {
      message = (
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          No Updates to Display for Selected Week
        </Typography>
      );
    }
  } else if (Object.values(bookmarkedProceedings).length === 0) {
    message = (
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        No Bookmarked Proceedings Yet
      </Typography>
    );
  } else if (displayedProcs.length === 0) {
    message = (
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        No Updates to Display for Selected Week
      </Typography>
    );
  }

  return (
    <>
      {/* List of proceeding names with links */}
      <Box sx={{ mb: 2 }}>
        {message}
        {displayedProcs.map((procInfo) => {
          const { id: procId, number, description } = procInfo;
          // Get associated digests for this proceeding
          const associatedDigests = procDigestsById[procId] || [];

          // Aggregate docTypes and docCounts
          const docTypesAndCounts = associatedDigests
            .map((digest) => `${capitalize(digest.doc_type)}: ${digest.doc_count}`)
            .join(', ');
          if (associatedDigests.length === 0) {
            return null;
          }
          return (
            <Box sx={{ mx: '5%' }}>
              <Link
                key={procId + number}
                href={`#proc-${procId}`}
                sx={{
                  display: 'block',
                  mb: 1,
                  backgroundColor: 'white',
                  padding: 1.5,
                  color: 'black',
                  borderRadius: 1,
                  boxShadow: 1,
                  transition: 'background-color 0.3s, box-shadow 0.3s',
                  '&:hover': {
                    backgroundColor: 'lightgray',
                    boxShadow: 3,
                  },
                  cursor: 'pointer',
                }}
                style={{ fontSize: '20px', textDecoration: 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  const element = document.getElementById(`proc-${procId}`);
                  if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                <Typography sx={{ fontSize: '.85em', fontWeight: 'bold' }}>
                  {number} - {docTypesAndCounts}
                </Typography>
                <Typography sx={{ fontFamily: 'serif', fontSize: '.75em' }}>
                  {cleanDescription(description)}
                </Typography>
              </Link>
              <Box sx={{ height: 6 }} />
            </Box>
          );
        })}
      </Box>
      <Autocomplete
        onChange={(e, tagsToFilterBy) => {
          const filteredBookmarkedProcs = filterBookmarkedProcsByTags(
            initialBookmarkedProcs,
            tagsToFilterBy
          );
          setBookmarkedProcsToShow(filteredBookmarkedProcs);
        }}
        multiple
        size="small"
        id="proc-tags"
        options={allTagOptions}
        getOptionLabel={(option) => option}
        defaultValue={[]}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} placeholder="Filter Proceedings by tags" />}
      />
      <Box sx={{ height: 24 }} />
      <Grid container spacing={2}>
        {displayedProcs.map((procInfo) => {
          const {
            id: procId,
            number: procNumber,
            tags,
            description: headline,
            active,
            progress,
            bookmark_id: bookmarkId,
            scoping_count: scopingCount,
            decisions_count: decisionsCount,
            rulings_count: rulingsCount,
            comments_count: commentsCount,
          } = procInfo;
          const associatedDigests = procDigestsById[procId] || [];

          if (associatedDigests.length === 0) {
            return null;
          }
          // get doc_counts from associatedDigests
          const newScopingCount = associatedDigests
            .filter((digest) => digest.doc_type === 'scoping memo(s)')
            .reduce((acc, digest) => acc + digest.doc_count, 0);
          const newDecisionsCount = associatedDigests
            .filter((digest) => digest.doc_type === 'decision(s)')
            .reduce((acc, digest) => acc + digest.doc_count, 0);
          const newRulingsCount = associatedDigests
            .filter((digest) => digest.doc_type === 'ruling(s)')
            .reduce((acc, digest) => acc + digest.doc_count, 0);
          const newCommentsCount = associatedDigests
            .filter((digest) => digest.doc_type === 'comment(s)')
            .reduce((acc, digest) => acc + digest.doc_count, 0);
          const counterRowProps: IProceedingCounterRowProps = {
            isNew: false,
            showNewIndicator: true,
            scopingCount,
            decisionsCount,
            rulingsCount,
            commentsCount,
            newScopingCount,
            newDecisionsCount,
            newRulingsCount,
            newCommentsCount,
          };

          return (
            <Grid
              container
              item
              xs={12}
              spacing={2}
              key={procNumber}
              id={`proc-${procId}`}
              sx={{ display: 'flex' }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{
                  // TODO: Fix this to be responsive in ProceedingCard
                  minWidth: '675px',
                }}
              >
                <ProceedingCard
                  procNumber={procNumber}
                  tags={tags}
                  showBookmarkIcon={false}
                  bookmarkId={bookmarkId}
                  tagOptions={allTagOptions}
                  headline={headline}
                  active={active}
                  progress={progress}
                  tlCornerText=""
                  showCounterRow
                  counterRowProps={counterRowProps}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {associatedDigests.map((digest, index) => (
                  <>
                    <Box
                      sx={{
                        minHeight: '100px',
                        backgroundColor: 'white',
                        padding: 2,
                        borderRadius: 2,
                      }}
                    >
                      <Typography
                        sx={{ fontSize: '1.2em', fontFamily: 'sans-serif', fontWeight: '700' }}
                      >
                        {/* Bandaid until actual decision sub-type is natively in the digest content */}
                        {digest.doc_type === 'decision(s)'
                          ? 'Decision/Proposed Decision/Petition for Modification'
                          : capitalize(digest.doc_type)}
                        : {digest.doc_count}
                      </Typography>
                      <Box sx={{ height: 10 }} />
                      <ShowMoreHTML html={digest.digest} maxLength={1200} />
                    </Box>
                    {index < associatedDigests.length - 1 && <Box sx={{ height: 20 }} />}
                  </>
                ))}
                <Box sx={{ mt: 2, textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    // color="primary"
                    sx={{
                      color: 'white', // Text color
                      backgroundColor: 'primary.main', // Background color
                      '&:hover': {
                        backgroundColor: 'primary.light', // Background color on hover
                      },
                      '&:active': {
                        backgroundColor: 'primary.light', // Background color on active
                      },
                    }}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                  >
                    Back to Top
                  </Button>
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Box sx={{ height: 24 }} />
    </>
  );
};
