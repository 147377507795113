import { Badge, Box, Chip, Typography } from '@mui/material';

import { IProceedingCounterRowProps } from './types';

export const ProceedingCardCounterRow = (props: IProceedingCounterRowProps) => {
  const {
    isNew,
    showNewIndicator,
    scopingCount,
    decisionsCount,
    rulingsCount,
    commentsCount,
    newScopingCount,
    newDecisionsCount,
    newRulingsCount,
    newCommentsCount,
  } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {showNewIndicator &&
        (isNew ? (
          <Chip
            label={
              <Typography variant="subtitle2" fontWeight="bold">
                NEW
              </Typography>
            }
            sx={{
              borderRadius: '20%',
              mx: 2,
              color: 'primary',
              backgroundColor: '#EECC87',
            }}
          />
        ) : (
          <Typography
            sx={{
              mx: 2,
              color: 'black',
              fontSize: 'small',
              fontWeight: 'bold',
            }}
          >
            No Updates
          </Typography>
        ))}
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
        <Badge badgeContent={newScopingCount} color="secondary">
          <Typography
            sx={{ marginRight: 0, paddingRight: 0.5, fontSize: 'small', fontWeight: 'bold' }}
          >
            Scoping
          </Typography>
        </Badge>
        <Chip
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {scopingCount}
            </Typography>
          }
          color="primary"
          sx={{ borderRadius: '20%', marginLeft: 1.5 }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
        <Badge badgeContent={newDecisionsCount} color="secondary">
          <Typography
            sx={{ marginRight: 0, paddingRight: 0.5, fontSize: 'small', fontWeight: 'bold' }}
          >
            PD/D
          </Typography>
        </Badge>
        <Chip
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {decisionsCount}
            </Typography>
          }
          color="primary"
          sx={{ borderRadius: '20%', marginLeft: 1.5 }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Badge badgeContent={newRulingsCount} color="secondary">
          <Typography
            sx={{ marginRight: 0, paddingRight: 0.5, fontSize: 'small', fontWeight: 'bold' }}
          >
            Rulings
          </Typography>
        </Badge>
        <Chip
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {rulingsCount}
            </Typography>
          }
          color="primary"
          sx={{ borderRadius: '20%', marginLeft: 1.5 }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Badge badgeContent={newCommentsCount} color="secondary">
          <Typography
            sx={{ marginRight: 0, paddingRight: 0.5, fontSize: 'small', fontWeight: 'bold' }}
          >
            Comments
          </Typography>
        </Badge>
        <Chip
          label={
            <Typography variant="subtitle2" fontWeight="bold">
              {commentsCount}
            </Typography>
          }
          color="primary"
          sx={{ borderRadius: '20%', marginLeft: 1 }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }} />
    </Box>
  );
};
