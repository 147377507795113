import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Modal,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { Dispatch, SetStateAction } from 'react';

import { BillSummaryPanel } from './BillSummaryPanel';
import { BillVersionChangeLegend } from './BillVersionChangeLegend';
import { IVersionData } from './types';

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  version?: IVersionData | undefined;
}
function SanitizedHTML({ html }: { html: string }) {
  const sanitizedHTML = DOMPurify.sanitize(html);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
}
export const BillVersionModal = (props: IProps) => {
  const { modalIsOpen = false, setModalIsOpen, version } = props;
  if (!version) {
    return null;
  }
  const {
    concise: conciseSummary = '',
    medium: mediumSummary = '',
    long: longSummary = '',
  } = version.summaries;
  const { prev_bill_text_based: changesFromPreviousVersion = '' } = version.deltas;
  return (
    <Modal
      open={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false);
      }}
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: '#e7e3e6',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          outline: 'none',
          maxHeight: '85vh', // Set the maximum height to the viewport height
          overflowY: 'auto', // Enable vertical scrolling
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
          scrollbarWidth: 'thin', // For Firefox
          scrollbarColor: '#888 #f1f1f1', // For Firefox
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {version && (
            <>
              {format(version.date, 'MMMM d, yyyy')} - {version?.title}
            </>
          )}
        </Typography>
        <Box sx={{ height: 18 }} />
        <BillSummaryPanel
          conciseSummary={conciseSummary}
          mediumSummary={mediumSummary}
          longSummary={longSummary}
        />
        <Box sx={{ height: 18 }} />
        {changesFromPreviousVersion && (
          <>
            <Card sx={{ mx: 3 }}>
              <Typography fontWeight="bold" variant="h6">
                Main Changes from Previous Version
              </Typography>
              <Box sx={{ marginInlineEnd: 4 }}>
                <SanitizedHTML html={changesFromPreviousVersion} />
              </Box>
            </Card>
            <Box sx={{ height: 18 }} />
          </>
        )}
        {version.delta_previous_html && (
          <Accordion slotProps={{ transition: { timeout: 650 } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Redline from Previous Version</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end', // Align to the right
                  width: '100%', // Full width of the parent
                }}
              >
                <Box
                  sx={{
                    width: '50%', // Half the width of the parent
                  }}
                >
                  <BillVersionChangeLegend />
                </Box>
              </Box>
              <Box sx={{ height: 10 }} />
              <SanitizedHTML html={version.delta_previous_html} />
            </AccordionDetails>
          </Accordion>
        )}
        <Box sx={{ height: 18 }} />
        {version.amends_law_html && (
          <Accordion slotProps={{ transition: { timeout: 650 } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Today&apos;s Law as Amended</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end', // Align to the right
                  width: '100%', // Full width of the parent
                }}
              >
                <Box
                  sx={{
                    width: '50%', // Half the width of the parent
                  }}
                >
                  <BillVersionChangeLegend />
                </Box>
              </Box>
              <Box sx={{ height: 10 }} />
              <SanitizedHTML html={version.amends_law_html} />
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </Modal>
  );
};
