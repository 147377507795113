import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { truncate } from 'lodash';
import React from 'react';

import { ProceedingSummaryPanel } from './ProceedingSummaryPanel';
import { ICommentData, IDecisionData, IOtherData, IRulingData, IScopingData } from './types';
import { SanitizedHTML } from '../common/SanitizedHTML';

interface AttachmentAccordionProps {
  attachment: IRulingData | ICommentData | IDecisionData | IScopingData | IOtherData | undefined;
}

const CONCAT_LIMIT_CHARS = 50;

export const ProcAttachmentAccordion: React.FC<AttachmentAccordionProps> = ({ attachment }) => {
  if (!attachment) {
    return null;
  }
  const {
    meta_data: {
      summary: attachmentSummary = '',
      short: attachmentShortSummary = '',
      long: attachmentLongSummary = '',
      medium: attachmentMediumSummary = '',
    } = {},
    link: attachmentLink,
  } = attachment;

  const attachmentUseTabbedSummaries =
    attachmentShortSummary || attachmentMediumSummary || attachmentLongSummary;

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="body1" fontWeight="bold" sx={{ display: 'inline' }}>
                Attachment:{' '}
                {truncate(attachment.meta_data?.title || attachment.description, {
                  length: CONCAT_LIMIT_CHARS * 1.5,
                })}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {!attachmentUseTabbedSummaries && attachmentSummary && (
            <>
              <Typography fontWeight="bold" variant="h6">
                Summary
              </Typography>
              <SanitizedHTML html={attachmentSummary.replace(/\n/g, '<br>')} />
              <Box sx={{ height: 18 }} />
            </>
          )}
          {attachmentUseTabbedSummaries && (
            <ProceedingSummaryPanel
              shortSummary={attachmentShortSummary}
              longSummary={attachmentLongSummary}
              showTitle
            />
          )}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight="bold">Show original document</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Link
                href={attachmentLink}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: 'none', marginRight: 2 }}
              >
                {attachmentLink}
              </Link>
              <Box sx={{ height: 10 }} />
              <iframe
                src={`https://docs.google.com/viewer?url=${encodeURIComponent(attachmentLink)}&embedded=true`}
                width="750"
                height="800"
                style={{ border: 'none' }}
                title="PDF Viewer"
              />
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
      <Box sx={{ height: 18 }} />
    </>
  );
};
