import { Box, Button, Card, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router-dom';

import { useBillsBySessionIdQuery } from './apis';
import { BillSummaryPanel } from './BillSummaryPanel';
import { BillTimeline } from './BillTimeline';
import { BillTypeRow } from './BillTypeRow';
import { BillVoteHistory } from './BillVoteHistory';
import { ProgressStepper } from './ProgressStepper';
import { AssistantButton } from '../assistant/AssistantButton';
import { BookmarkIcon } from '../bookmark/BookmarkIcon';
import { StyledLinkBox } from '../common/StyledLinkBox';

function SanitizedHTML({ html }: { html: string }) {
  const sanitizedHTML = DOMPurify.sanitize(html);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
}

export const BillPage = () => {
  const theme = useTheme();
  const { billSessionId } = useParams();
  const { data, isLoading, error } = useBillsBySessionIdQuery(billSessionId);
  const [detailsAreHidden, setDetailsAreHidden] = useState(false);
  const [legislativeDigestIsHidden, setLegislativeDigestIsHidden] = useState(true);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography>There was an error loading bill: {billSessionId}</Typography>;
  }

  if (!data) {
    return <Typography>No data available for bill: {billSessionId}</Typography>;
  }

  const {
    headline,
    name: billName,
    concise: conciseSummary,
    medium: mediumSummary,
    long: longSummary,
    bill_status: billStatus,
    lead_authors: leadAuthors,
    coauthors,
    house_location: houseLocation,
    topic,
    digest,
    votes,
    actions,
    leg_analyses: analyses,
    link,
    versions,
    progress,
    simple_majority: simpleMajority,
    appropriation,
    fiscal_committee: fiscalCommittee,
    local_program: localProgram,
    urgency,
    tax_levy: taxLevy,
    deltas,
  } = data;
  // replace \n with double space \n in digest for display
  const digestDisplay = digest.replace(/\n/g, '  \n');
  const latestActionDesc = actions ? actions[0].desc : 'None';
  const latestVersionTitle = versions ? versions[0].title : '';

  const changesFromIntroduced = deltas?.from_intro_summary_based ?? null;

  return (
    <Grid container spacing={3}>
      <Grid item sm={8} xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            sx={{ color: theme.palette.primary.main, fontFamily: 'sans-serif' }}
            variant="body1"
          >
            Bills &gt; CA &gt;{' '}
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
              {billName}
            </Typography>
          </Typography>
          <BookmarkIcon billSessionId={billSessionId} iconStyles={{ fontSize: '48px' }} />
        </Box>
        <Box sx={{ mx: '16px' }}>
          <BillTypeRow
            status={{
              simpleMajority,
              appropriation,
              fiscalCommittee,
              localProgram,
              urgency,
              taxLevy,
            }}
          />
        </Box>
        <Box sx={{ height: '16px' }} />
        <Typography
          sx={{
            color: theme.palette.primary.main,
            lineHeight: '1.56',
            fontFamily: 'serif',
            fontWeight: '500',
          }}
          variant="h5"
        >
          {headline}
        </Typography>
        <Box sx={{ height: 24 }} />
        <ProgressStepper progress={progress} billName={billName} />
        <Box sx={{ height: 24 }} />
        <BillSummaryPanel
          conciseSummary={conciseSummary}
          mediumSummary={mediumSummary}
          longSummary={longSummary}
        />
        <Divider sx={{ my: 0 }} />
        <Box sx={{ height: 24 }} />
        {changesFromIntroduced && (
          <Card>
            <Typography fontWeight="bold" variant="h6">
              Changes from Introduced
            </Typography>
            <Box sx={{ height: 10 }} />
            <SanitizedHTML html={changesFromIntroduced} />
          </Card>
        )}
        <Box sx={{ height: 24 }} />
        <Card>
          <Typography fontWeight="bold" variant="h6">
            Legislative Digest
            <Button
              onClick={() => setLegislativeDigestIsHidden(!legislativeDigestIsHidden)}
              variant="text"
              disableRipple
              sx={{
                background: 'none',
                '&:hover': {
                  textDecoration: 'underline', // Underline on hover
                  backgroundColor: 'transparent', // Transparent background on hover
                },
              }}
            >
              {legislativeDigestIsHidden ? 'Show' : 'Hide'}
            </Button>
          </Typography>
          {!legislativeDigestIsHidden && (
            <>
              <Box sx={{ height: 12 }} />
              <Markdown>{digestDisplay}</Markdown>
            </>
          )}
        </Card>
      </Grid>

      <Grid item sm={4} xs={12}>
        <Card>
          <Typography fontWeight="bold" variant="h6">
            Details{' '}
            <Button
              onClick={() => setDetailsAreHidden(!detailsAreHidden)}
              variant="text"
              disableRipple
              sx={{
                background: 'none',
                '&:hover': {
                  textDecoration: 'underline', // Underline on hover
                  backgroundColor: 'transparent', // Transparent background on hover
                },
              }}
            >
              {detailsAreHidden ? 'Show details' : 'Hide Details'}
            </Button>
          </Typography>
          <Box sx={{ height: 12 }} />
          <Typography fontWeight="bold" variant="body2">
            Status
          </Typography>
          <Box sx={{ height: 4 }} />
          <Typography variant="body2">{billStatus}</Typography>
          {!detailsAreHidden && (
            <>
              <Box sx={{ height: 12 }} />
              <Typography fontWeight="bold" variant="body2">
                Lead Authors
              </Typography>
              <Box sx={{ height: 4 }} />
              <Typography variant="body2">{leadAuthors.join(', ')}</Typography>
              <Box sx={{ height: 12 }} />
              <Typography fontWeight="bold" variant="body2">
                Coauthors
              </Typography>
              <Box sx={{ height: 4 }} />
              <Typography variant="body2">{coauthors.join(', ')}</Typography>
              <Box sx={{ height: 12 }} />
              <Typography fontWeight="bold" variant="body2">
                Topic
              </Typography>
              <Box sx={{ height: 4 }} />
              <Typography variant="body2">{topic}</Typography>
              <Box sx={{ height: 12 }} />
              <Typography fontWeight="bold" variant="body2">
                House Location
              </Typography>
              <Box sx={{ height: 4 }} />
              <Typography variant="body2">{houseLocation}</Typography>
            </>
          )}
        </Card>
        <Box sx={{ height: 24 }} />
        <Card>
          <Typography fontWeight="bold" variant="h6">
            Latest
          </Typography>
          <Box sx={{ height: 12 }} />
          <Typography fontWeight="bold" variant="body2">
            Action
          </Typography>
          <Box sx={{ height: 4 }} />
          <Typography variant="body2">{latestActionDesc}</Typography>
          <Box sx={{ height: 12 }} />
          <Typography fontWeight="bold" variant="body2">
            Bill Version
          </Typography>
          <Box sx={{ height: 4 }} />
          <Typography variant="body2">{latestVersionTitle}</Typography>
          <Box sx={{ height: 12 }} />
          <StyledLinkBox link={link} displayText="leginfo.legislature.ca.gov" />
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Typography
          sx={{ fontWeight: 'bold', color: theme.palette.primary.main, lineHeight: '1.56' }}
          variant="h5"
        >
          History
        </Typography>
        <Box sx={{ height: 12 }} />
        <BillTimeline
          billData={data}
          versions={versions}
          votes={votes}
          actions={actions}
          analyses={analyses}
        />
      </Grid>
      <Grid item xs={12}>
        <BillVoteHistory votes={votes} />
      </Grid>
      <Grid item xs={12}>
        {/* TODO: Add back in when feature implemented */}
        {/* <Typography
          sx={{ fontWeight: 'bold', color: theme.palette.primary.main, lineHeight: '1.56' }}
          variant="h5"
        >
          Version Change History
        </Typography> */}
        <Box sx={{ height: 24 }} />
      </Grid>
      <AssistantButton />
    </Grid>
  );
};
