import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import logo from '../policy-pulse-app-logo.svg';

export const LogoImage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box
      component="img"
      src={logo}
      alt="policy pulse logo"
      sx={{ width: '200px', cursor: 'pointer' }}
      onClick={() => navigate('/')}
    />
  );
};
