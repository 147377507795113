export interface IActionData {
  id: number;
  date: string;
  desc: string;
}

export interface IAnalysesData {
  id: number;
  date: string;
  meta_data: {
    arguments_in_opposition: string;
    arguments_in_support: string;
    filename: string;
    markdown: string;
    opposition: string[];
    summary: string;
    support: string[];
    text: string;
    title: string;
  };
}
export interface IVersionSummaryData {
  concise: string;
  medium: string;
  long: string;
}
export interface IVersionDeltaData {
  from_intro_summary_based: string;
  prev_bill_text_based: string;
}

export interface IVersionData {
  id: number;
  bill_text: string;
  date: string;
  digest: string;
  title: string;
  delta_previous_html: string;
  amends_law_html: string;
  summaries: IVersionSummaryData;
  deltas: IVersionDeltaData;
}

export enum VoteResult {
  PASS = 1,
  FAIL = -1,
}

export interface IVoteData {
  vote_id: number;
  date: string;
  result: VoteResult;
  location: string;
  ayes_count: number;
  noes_count: number;
  nvr_count: number;
  motion: string;
  ayes_names_array: string[];
  noes_names_array: string[];
  nvr_names_array: string[];
}

export interface ISearchVersionData {
  version_id: string;
  title: string;
  date: string;
}
export interface ISearchActionData {
  date: string;
  desc_ai: string;
}
export interface ISearchVoteData {
  date: string;
}

export type CURRENT_STEP_INDEX = 0 | 1 | 2 | 3;
export type CURRENT_STEP_STATUS = -1 | 0 | 1;

export enum VersionGroup {
  ID = 'versionGroupId',
}

export enum VoteGroup {
  ID = 'voteGroupId',
}

export enum ActionGroup {
  ID = 'actionGroupId',
}

export enum AnalysesGroup {
  ID = 'analysesGroupId',
}

// Structure of response object from /api/v1/bills/:bill_session_id
export interface IBillData {
  bill_session_id: string;
  name: string;
  link: string;
  bill_status: string;
  lead_authors: string[];
  coauthors: string[];
  topic: string;
  digest: string;
  house_location: string;
  votes: IVoteData[];
  actions: IActionData[];
  versions: IVersionData[];
  leg_analyses: IAnalysesData[];
  progress: [CURRENT_STEP_INDEX, CURRENT_STEP_STATUS];
  // Summary info
  concise: string;
  medium: string;
  long: string;
  headline: string;
  // Bill types
  simple_majority: boolean;
  appropriation: boolean;
  fiscal_committee: boolean;
  local_program: boolean;
  urgency: boolean;
  tax_levy: boolean;
  deltas: Record<string, string>;
}

// Individual object from /api/v1/bills
export interface IBillListResponseObject {
  bill_session_id: string;
  bill_name: string;
  active: boolean;
  lead_authors: string[];
  headline: string;
  progress: [CURRENT_STEP_INDEX, CURRENT_STEP_STATUS];
  tl_corner_text: string;
  versions: ISearchVersionData[];
  votes: ISearchVoteData[];
  actions: ISearchActionData[];
}
