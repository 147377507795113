import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { BillVoteHistoryItem } from './BillVoteHistoryItem';
import { BillVoteLegend } from './BillVoteLegend';
import { IVoteData } from './types';

interface IProps {
  votes: IVoteData[];
}

export const BillVoteHistory = (props: IProps) => {
  const theme = useTheme();
  const { votes } = props;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            lineHeight: '1.56',
            textAlign: 'left',
          }}
          variant="h5"
        >
          Full Vote History
        </Typography>
        <BillVoteLegend />
      </Box>
      <Box sx={{ height: 24 }} />
      {votes.map((vote: IVoteData) => {
        return <BillVoteHistoryItem vote={vote} key={vote.vote_id} />;
      })}
    </>
  );
};
