import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5a2544',
    },
    secondary: {
      main: '#EECC87',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        // TODO
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: '#eecc87',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'unset',
          backgroundColor: '#ece9eb',
        },
        outlined: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#5a254433',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#e7e3e6',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: 4,
        },
      },
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: 4,
          width: '100%',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #5a254433',
          borderRadius: '8px',
          padding: '16px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#5a2544',
          fontSize: '12px',
          fontWeight: '500',
          paddingBottom: 2,
        },
      },
    },
  },
});

export default theme;
