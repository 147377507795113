import { Box, Modal } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { BillVoteHistoryItem } from './BillVoteHistoryItem';
import { IVoteData } from './types';

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  vote: IVoteData | undefined;
}

export const BillVoteModal = (props: IProps) => {
  const { modalIsOpen = false, setModalIsOpen, vote } = props;
  if (!vote) {
    return null;
  }

  return (
    <Modal
      open={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false);
      }}
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: '#e7e3e6',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}
      >
        <BillVoteHistoryItem defaultExpanded vote={vote} />
      </Box>
    </Modal>
  );
};
