import { CircularProgress, List, Typography } from '@mui/material';

import { useBillsQuery } from './apis';
import { BillCard } from './BillCard';
import { IBillListResponseObject } from './types';

export const BillsList = () => {
  const { data: bills, isFetching } = useBillsQuery();
  if (isFetching) {
    return <CircularProgress />;
  }

  if (!bills) {
    return <Typography>Search for bills...</Typography>;
  }
  return (
    <>
      <Typography>Returned {bills.length} bills</Typography>
      <List>
        {bills.map((bill: IBillListResponseObject) => {
          const {
            bill_name: billName,
            bill_session_id: billSessionId,
            lead_authors: leadAuthors,
            headline,
            active,
            progress,
            tl_corner_text: tlCornerText,
          } = bill;

          return (
            <BillCard
              key={billSessionId}
              billName={billName}
              billSessionId={billSessionId}
              leadAuthors={leadAuthors}
              headline={headline}
              active={active}
              progress={progress}
              tlCornerText={tlCornerText}
              showBookmarkIcon
            />
          );
        })}
      </List>
    </>
  );
};
