/* eslint-disable no-console */
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';

import { createBookmark, removeBookmark, useGetBookmarks } from './apis';
import { useBookmarkContext } from './BookmarkContext';
import { BookmarkSnackbar } from './BookmarkSnackbar';

interface IBookmarkProps {
  billSessionId?: string;
  proceedingNumber?: string;
  iconStyles?: any; // this should inherit from MUI sx props
}

interface IOnClickHandlerPayload {
  billSessionId?: string;
  proceedingNumber?: string;
}

interface ISnackbarProps {
  snackbarTextColor: string;
  snackbarMessage: string;
  snackbarBackgroundColor: string;
  snackbarIsOpen: boolean;
}

export const BookmarkIcon = (props: IBookmarkProps) => {
  const theme = useTheme();

  const { billSessionId, proceedingNumber, iconStyles = {} } = props;
  const { bookmarkedBills, bookmarkedProceedings } = useBookmarkContext();
  const { refetch: refetchBookmarks } = useGetBookmarks();

  const [snackbarProps, setSnackbarProps] = useState<ISnackbarProps>({
    snackbarTextColor: 'white',
    snackbarBackgroundColor: theme.palette.primary.main,
    snackbarMessage: '',
    snackbarIsOpen: false,
  });

  const { snackbarTextColor, snackbarBackgroundColor, snackbarMessage, snackbarIsOpen } =
    snackbarProps;

  if (!billSessionId && !proceedingNumber) {
    throw new Error('Missing bill or proceeding ID');
  }
  if (billSessionId && proceedingNumber) {
    throw new Error('Cannot supply both bill and proceeding ID to the bookmark');
  }

  let itemIsBookmarkedDefault = false;
  if (billSessionId && bookmarkedBills && bookmarkedBills[billSessionId]) {
    itemIsBookmarkedDefault = true;
  }

  if (proceedingNumber && bookmarkedProceedings && bookmarkedProceedings[proceedingNumber]) {
    itemIsBookmarkedDefault = true;
  }

  // Set is icon clicked using prefetched bookmark from the landing page provider
  const [itemIsBookmarked, setItemIsBookmarked] = useState(itemIsBookmarkedDefault);

  const onClickHandler = async (payload: IOnClickHandlerPayload) => {
    if (!itemIsBookmarked) {
      // Try to create the bookmark
      try {
        setItemIsBookmarked(true);
        await createBookmark(payload);
        refetchBookmarks();

        setSnackbarProps((prevState) => {
          return {
            ...prevState,
            snackbarMessage: 'Bookmark saved',
            snackbarIsOpen: true,
          };
        });
      } catch {
        setSnackbarProps((prevState) => {
          return {
            ...prevState,
            snackbarMessage: 'Unable to save bookmark',
            snackbarIsOpen: true,
            snackbarBackgroundColor: '#d32f2f',
          };
        });
        setItemIsBookmarked(false);
      }
    } else {
      // Item is bookmarked, so delete it
      try {
        setItemIsBookmarked(false);
        await removeBookmark(payload);
        refetchBookmarks();
        setSnackbarProps((prevState) => {
          return {
            ...prevState,
            snackbarMessage: 'Bookmark removed',
            snackbarIsOpen: true,
          };
        });
      } catch {
        setSnackbarProps((prevState) => {
          return {
            ...prevState,
            snackbarMessage: 'Unable to delete bookmark',
            snackbarIsOpen: true,
            snackbarBackgroundColor: '#d32f2f',
          };
        });
        setItemIsBookmarked(true);
      }
    }
  };

  if (billSessionId) {
    return (
      <>
        <IconButton sx={{ paddingLeft: 0 }} onClick={() => onClickHandler({ billSessionId })}>
          {itemIsBookmarked ? (
            <BookmarkOutlinedIcon sx={iconStyles} />
          ) : (
            <BookmarkBorderOutlinedIcon sx={iconStyles} />
          )}
        </IconButton>
        <BookmarkSnackbar
          snackbarTextColor={snackbarTextColor}
          snackbarMessage={snackbarMessage}
          snackbarBackgroundColor={snackbarBackgroundColor}
          snackbarIsOpen={snackbarIsOpen}
          setSnackbarProps={setSnackbarProps}
        />
      </>
    );
  }

  if (proceedingNumber) {
    return (
      <>
        <IconButton sx={{ paddingLeft: 0 }} onClick={() => onClickHandler({ proceedingNumber })}>
          {itemIsBookmarked ? (
            <BookmarkOutlinedIcon sx={iconStyles} />
          ) : (
            <BookmarkBorderOutlinedIcon sx={iconStyles} />
          )}
        </IconButton>
        <BookmarkSnackbar
          snackbarTextColor={snackbarTextColor}
          snackbarMessage={snackbarMessage}
          snackbarBackgroundColor={snackbarBackgroundColor}
          snackbarIsOpen={snackbarIsOpen}
          setSnackbarProps={setSnackbarProps}
        />
      </>
    );
  }

  return null;
};
