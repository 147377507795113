/* eslint-disable no-console */
import { useQuery } from 'react-query';

import { IAllBookmarkedData, ICreateBookmarkProps, ITeamBookmarkData } from './types';
import { apiClient } from '../../lib/apiClient';
import { ApiError } from '../../lib/types';

export const useGetBookmarks = (shouldRunQuery = true) => {
  const GET_BOOKMARKS_URL = `/api/v1/bookmarks`;
  return useQuery<IAllBookmarkedData, ApiError>([GET_BOOKMARKS_URL], {
    enabled: shouldRunQuery,
  });
};

export const useGetDigestBookmarks = (shouldRunQuery = true) => {
  const GET_BOOKMARKS_URL = `/api/v1/digest_bookmarks`;
  return useQuery<IAllBookmarkedData, ApiError>([GET_BOOKMARKS_URL], {
    enabled: shouldRunQuery,
  });
};

export const useGetBookmarkedBillsOrProcsByIds = (
  bookmarkType: string,
  bookmarkIds: number[] = [],
  shouldRunQuery = true
) => {
  const GET_BOOKMARKS_URL = `/api/v1/bookmarks?bookmark_type=${bookmarkType}&entity_ids=${bookmarkIds.join(',')}`;
  // TODO define the return object
  return useQuery<any, ApiError>([GET_BOOKMARKS_URL], {
    enabled: shouldRunQuery,
  });
};

export const useGetTeamBookmarks = (shouldRunQuery = true) => {
  const GET_TEAM_BOOKMARKS_URL = `/api/v1/get_team_bookmarks`;
  return useQuery<ITeamBookmarkData, ApiError>([GET_TEAM_BOOKMARKS_URL], {
    enabled: shouldRunQuery,
  });
};

export const createBookmark = async (payload: ICreateBookmarkProps) => {
  /**
   * body should include billSessionId, or proceedingId (TODO), not both
   */
  return apiClient.post('/api/v1/bookmarks', { json: payload });
};

export const removeBookmark = async (payload: ICreateBookmarkProps) => {
  /**
   * payload should include billSessionId, or proceedingNumber, not both
   */
  const { billSessionId, proceedingNumber } = payload;

  let url = '/api/v1/bookmarks';
  if (billSessionId) {
    url = `${url}?billSessionId=${billSessionId}`;
  }

  if (proceedingNumber) {
    url = `${url}?proceedingNumber=${proceedingNumber}`;
  }

  return apiClient.delete(url);
};
