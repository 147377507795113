import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Timeline, TimelineOptions } from 'vis-timeline/standalone';

import { BillActionModal } from './BillActionModal';
import { BillAnalysesModal } from './BillAnalysesModal';
import { BillVersionModal } from './BillVersionModal';
import { BillVoteModal } from './BillVoteModal';
import {
  ActionGroup,
  AnalysesGroup,
  IActionData,
  IAnalysesData,
  IBillData,
  IVersionData,
  IVoteData,
  VersionGroup,
  VoteGroup,
} from './types';
import { getTimelineItems } from './utils';
import { TimelineLegend } from '../common/TimelineLegend';

interface IProps {
  versions?: IVersionData[];
  votes?: IVoteData[];
  actions?: IActionData[];
  analyses?: IAnalysesData[];
  billData: IBillData;
}

export const BillTimeline = (props: IProps) => {
  const { versions = [], votes = [], actions = [], analyses = [], billData } = props;
  const timelineRef = useRef(null);
  const [versionIsChecked, setVersionIsChecked] = useState(true);
  const [voteIsChecked, setVoteIsChecked] = useState(true);
  const [actionIsChecked, setActionIsChecked] = useState(false);
  const [analysesIsChecked, setAnalysesIsChecked] = useState(true);

  const [timelineIsVisible, setTimelineIsVisible] = useState(true);

  // Vote modal state handlers
  const [voteModalIsOpen, setVoteModalIsOpen] = useState(false);
  const [selectedVote, setSelectedVote] = useState<IVoteData | undefined>(undefined);

  // Version modal state handlers
  const [versionModalIsOpen, setVersionModalIsOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState<IVersionData | undefined>(undefined);

  // Action modal state handlers
  const [actionModalIsOpen, setActionModalIsOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState<IActionData | undefined>(undefined);

  // Leg analyses modal state handlers
  const [analysesModalIsOpen, setAnalysesModalIsOpen] = useState(false);
  const [selectedAnalysis, setSelectedAnalysis] = useState<IAnalysesData | undefined>(undefined);

  useEffect(() => {
    let timeline: Timeline;
    const { groups, timelineItems } = getTimelineItems({
      versionIsChecked,
      voteIsChecked,
      actionIsChecked,
      analysesIsChecked,
      versions,
      votes,
      actions,
      analyses,
    });

    // Mount timeline component to the DOM once the ref has loaded
    if (timelineRef.current) {
      const options: TimelineOptions = {
        type: 'point',
        showCurrentTime: true,
        horizontalScroll: true,
      };
      timeline = new Timeline(timelineRef.current, timelineItems, groups, options);

      timeline.on('click', (properties) => {
        const itemGroup = properties.group;
        const itemId = properties.item;

        if (!itemId || !itemGroup) {
          return;
        }

        if (itemGroup === VersionGroup.ID) {
          const clickedVersion = versions.find((v) => v.id === itemId);
          setSelectedVersion(clickedVersion);
          setVersionModalIsOpen(true);
        }

        if (itemGroup === VoteGroup.ID) {
          const clickedVote = votes.find((v) => v.vote_id === itemId);
          setSelectedVote(clickedVote);
          setVoteModalIsOpen(true);
        }

        if (itemGroup === ActionGroup.ID) {
          const clickedAction = actions.find((a) => a.id === itemId);
          setSelectedAction(clickedAction);
          setActionModalIsOpen(true);
        }

        if (itemGroup === AnalysesGroup.ID) {
          const clickedAnalysis = analyses.find((a) => a.id === itemId);
          setSelectedAnalysis(clickedAnalysis);
          setAnalysesModalIsOpen(true);
        }
      });
    }

    // Cleanup function to destroy the timeline when the component unmounts
    return () => {
      if (timeline) {
        timeline.destroy();
      }
    };
  }, [
    actionIsChecked,
    actions,
    versionIsChecked,
    versions,
    voteIsChecked,
    votes,
    billData,
    analyses,
    analysesIsChecked,
  ]);

  return (
    <>
      <BillVersionModal
        modalIsOpen={versionModalIsOpen}
        setModalIsOpen={setVersionModalIsOpen}
        version={selectedVersion}
      />
      <BillVoteModal
        vote={selectedVote}
        modalIsOpen={voteModalIsOpen}
        setModalIsOpen={setVoteModalIsOpen}
      />
      <BillActionModal
        action={selectedAction}
        modalIsOpen={actionModalIsOpen}
        setModalIsOpen={setActionModalIsOpen}
      />
      <BillAnalysesModal
        analysis={selectedAnalysis}
        modalIsOpen={analysesModalIsOpen}
        setModalIsOpen={setAnalysesModalIsOpen}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between', // Distribute space between the items
          alignItems: 'center', // Center items vertically
          width: '100%', // Full width of the parent
        }}
      >
        <FormGroup row sx={{ marginLeft: '16px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={versionIsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setTimelineIsVisible(!timelineIsVisible);
                  setVersionIsChecked(event.target.checked);
                }}
              />
            }
            label="Versions"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={voteIsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setVoteIsChecked(event.target.checked);
                }}
              />
            }
            label="Votes"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={actionIsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setActionIsChecked(event.target.checked);
                }}
              />
            }
            label="Actions"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={analysesIsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAnalysesIsChecked(event.target.checked);
                }}
              />
            }
            label="Analyses"
          />
        </FormGroup>
        <TimelineLegend />
      </Box>
      <Box sx={{ height: 18 }} />
      <Box ref={timelineRef} sx={{ marginLeft: '16px', marginRight: '16px' }} />
    </>
  );
};
