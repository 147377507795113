import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Link,
  Modal,
  Typography,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';

import { ProcAttachmentAccordion } from './ProcAttachmentAccordion';
import { ProceedingSummaryPanel } from './ProceedingSummaryPanel';
import { SubmittedDocNotice } from './SubmittedDocNotice';
import { IOtherData, IScopingData } from './types';
import { titleCaseExceptPrepositions } from './utils';
import { SanitizedHTML } from '../common/SanitizedHTML';

interface IProps {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  doc?: IOtherData | undefined;
  attachments?: (IOtherData | IScopingData)[] | undefined;
}

export const ProcOtherModal = (props: IProps) => {
  const { modalIsOpen = false, setModalIsOpen, doc, attachments = [] } = props;
  if (!doc) {
    return null;
  }

  const {
    date,
    description,
    filed_by: filedBy,
    meta_data = {},
    link,
    is_submitted: isSubmitted = false,
  } = doc;
  const {
    summary = '',
    short: shortSummary = '',
    medium: mediumSummary = '',
    long: longSummary = '',
  } = meta_data || {}; // handles if meta_data is undefined

  const useTabbedSummaries = shortSummary || mediumSummary || longSummary;
  return (
    <Modal
      open={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false);
      }}
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 840,
          bgcolor: '#e7e3e6',
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          outline: 'none',
          maxHeight: '85vh', // Set the maximum height to the viewport height
          overflowY: 'auto', // Enable vertical scrolling
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
          scrollbarWidth: 'thin', // For Firefox
          scrollbarColor: '#888 #f1f1f1', // For Firefox
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {format(parseISO(date), 'MMMM d, yyyy')} - {titleCaseExceptPrepositions(filedBy)}
        </Typography>
        <Box sx={{ height: 18 }} />
        {isSubmitted && <SubmittedDocNotice />}
        <Card sx={{ mx: 3 }}>
          <Typography fontWeight="bold" variant="h6">
            Description
          </Typography>
          <Box sx={{ height: 10 }} />
          <SanitizedHTML html={description} />
        </Card>
        <Box sx={{ height: 18 }} />
        {!useTabbedSummaries && summary && (
          <>
            <Card sx={{ mx: 3 }}>
              <Typography fontWeight="bold" variant="h6">
                Summary
              </Typography>
              <Box sx={{ height: 10 }} />
              <SanitizedHTML html={summary.replace(/\n/g, '<br>')} />
            </Card>
            <Box sx={{ height: 18 }} />
          </>
        )}
        {useTabbedSummaries && (
          <ProceedingSummaryPanel shortSummary={shortSummary} longSummary={longSummary} showTitle />
        )}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight="bold">Show original document</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Link
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: 'none', marginRight: 2 }}
            >
              {link}
            </Link>
            <Box sx={{ height: 10 }} />
            <iframe
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(link)}&embedded=true`}
              width="800"
              height="800"
              style={{ border: 'none' }}
              title="PDF Viewer"
            />
          </AccordionDetails>
        </Accordion>
        <Box sx={{ height: 18 }} />
        {attachments.map((attachment: IOtherData | IScopingData) => (
          <ProcAttachmentAccordion key={attachment.id} attachment={attachment} />
        ))}
      </Box>
    </Modal>
  );
};
