/* eslint-disable no-console */
import { Box, Button, Card, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useBillsQuery } from './apis';

enum BillSearchParams {
  BILL_NAMES = 'bill_names',
  KEYWORDS = 'keywords',
  LATEST_DATE_FROM = 'latest_date_from',
  LATEST_DATE_TO = 'latest_date_to',
  STATE = 'state',
  STATUS = 'status',
  SESSION = 'session',
  LEAD_AUTHORS = 'lead_authors',
}

enum BillStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

interface IQueryStringParams {
  [BillSearchParams.BILL_NAMES]?: string[];
  [BillSearchParams.STATE]: string;
  [BillSearchParams.KEYWORDS]?: string[];
  [BillSearchParams.LATEST_DATE_FROM]: string;
  [BillSearchParams.LATEST_DATE_TO]: string;
  [BillSearchParams.SESSION]?: string;
  [BillSearchParams.STATUS]: BillStatus;
  [BillSearchParams.LEAD_AUTHORS]?: string;
}

const today = new Date();
const TODAY_YYYY_MM_DD = format(today, 'yyyy/MM/dd');

export const BillSearchPanel = () => {
  const [queryParams, setQueryParams] = useState<IQueryStringParams>({
    // Set default values
    [BillSearchParams.STATE]: 'CA',
    [BillSearchParams.LATEST_DATE_FROM]: '2024/01/01',
    [BillSearchParams.LATEST_DATE_TO]: TODAY_YYYY_MM_DD,
    [BillSearchParams.STATUS]: BillStatus.ACTIVE,
  });
  const { refetch: searchForBills } = useBillsQuery();
  const [_, setSearchParams] = useSearchParams();

  const handleInputChange = useCallback(
    (fieldName: BillSearchParams, value: any) => {
      setQueryParams((prevState) => {
        const nextState = { ...prevState };
        // Make sure any values that are empty are deleted from the state object
        if (!value) {
          delete nextState[fieldName];
        } else {
          nextState[fieldName] = value;
        }
        return nextState;
      });
    },
    [setQueryParams]
  );
  const triggerSearch = () => {
    // set the search params in the url, and then refetch data
    const searchParamsToSet: Record<string, any> = {};
    Object.entries(queryParams).forEach(([key, value]) => {
      searchParamsToSet[key] = value;
    });
    setSearchParams(searchParamsToSet);
    // Set timeout helps make sure that the fetch occurs after search params are set
    setTimeout(() => {
      searchForBills();
    }, 100);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerSearch();
    }
  };
  return (
    <Card sx={{ p: 3, mb: 3, backgroundColor: 'transparent' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel> Bill Number</InputLabel>
          <TextField
            onChange={(e) => handleInputChange(BillSearchParams.BILL_NAMES, e.target.value)}
            onKeyDown={handleKeyDown}
            size="small"
            variant="outlined"
            placeholder="SB-746, SB746, etc"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Keywords</InputLabel>
          <TextField
            onChange={(e) => handleInputChange(BillSearchParams.KEYWORDS, e.target.value)}
            onKeyDown={handleKeyDown}
            size="small"
            variant="outlined"
            placeholder="Solar, Wind, Tariffs..."
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Status</InputLabel>
          <Select
            sx={{ height: 40 }}
            defaultValue={BillStatus.ACTIVE}
            onChange={(e) => handleInputChange(BillSearchParams.STATUS, e.target.value)}
          >
            <MenuItem value={BillStatus.ACTIVE}>Active</MenuItem>
            <MenuItem value={BillStatus.INACTIVE}>Inactive</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Authors</InputLabel>
          <TextField
            onChange={(e) => handleInputChange(BillSearchParams.LEAD_AUTHORS, e.target.value)}
            size="small"
            variant="outlined"
            placeholder="Lead author(s)"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel disabled>State</InputLabel>
          <TextField
            // Only supports CA for now
            value={queryParams.state}
            disabled
            size="small"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Latest Date From</InputLabel>
          <TextField
            // TODO: use date picker
            value={queryParams[BillSearchParams.LATEST_DATE_FROM]}
            onChange={(e) => handleInputChange(BillSearchParams.LATEST_DATE_FROM, e.target.value)}
            size="small"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Latest Date To</InputLabel>
          <TextField
            value={queryParams[BillSearchParams.LATEST_DATE_TO]}
            onChange={(e) => handleInputChange(BillSearchParams.LATEST_DATE_TO, e.target.value)}
            size="small"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel disabled>Session</InputLabel>
          <TextField
            // Hardcoded at the moment
            value="2023-2024"
            disabled
            size="small"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
      <Box sx={{ height: 16 }} />
      <Button
        variant="outlined"
        onClick={() => {
          triggerSearch();
        }}
      >
        Search
      </Button>
    </Card>
  );
};
