import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format, parse, startOfDay, subDays, subMonths, subWeeks, subYears } from 'date-fns';
import { map, split, trim } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Timeline, TimelineOptions } from 'vis-timeline/standalone';

import { ProcCommentsModal } from './ProcCommentsModal';
import { ProcDecisionModal } from './ProcDecisionModal';
import { ProcOtherModal } from './ProcOtherModal';
import { ProcRulingModal } from './ProcRulingModal';
import { ProcScopingModal } from './ProcScopingModal';
import {
  CommentGroup,
  DecisionGroup,
  ICommentData,
  IDecisionData,
  IOtherData,
  IRulingData,
  IScopingData,
  OtherGroup,
  RulingGroup,
  ScopingGroup,
} from './types';
import { filterItemsByDateRange, filterItemsByKeywords, getProceedingTimelineItems } from './utils';
import { TimelineLegend } from '../common/TimelineLegend';

enum FilingType {
  COMMENT = 'comment',
  RULING = 'ruling',
  DECISION = 'decision',
  SCOPING = 'scoping',
  OTHER = 'other',
  ALL = 'all',
}

interface IProps {
  comments?: ICommentData[];
  rulings?: IRulingData[];
  decisions?: IDecisionData[];
  scopings?: IScopingData[];
  others?: IOtherData[];
}

const TODAY = startOfDay(new Date());
const ONE_WEEK_AGO = subWeeks(TODAY, 1);
const ONE_MONTH_AGO = subMonths(TODAY, 1);
const THREE_MONTHS_AGO = subMonths(TODAY, 3);
const SIX_MONTHS_AGO = subMonths(TODAY, 6);
const ONE_YEAR_AGO = subYears(TODAY, 1);

const filterByFiledBy = (
  documents: (ICommentData | IRulingData | IDecisionData | IScopingData | IOtherData)[],
  filedByNamesToFilterBy: string[]
): (ICommentData | IRulingData | IDecisionData | IScopingData | IOtherData)[] => {
  return documents.filter((document) => {
    const { filed_by = '' } = document;
    return filedByNamesToFilterBy.some((name) =>
      (filed_by ? filed_by.toLowerCase() : '').includes(name.toLowerCase())
    );
  });
};

const getDefaultFilteredItems = (
  items: (ICommentData | IRulingData | IDecisionData | IScopingData | IOtherData)[],
  latestDateString: string
) => {
  // Default to showing the last 90 days worth of data, from the latest date across all docs
  if (!items || items.length === 0) {
    return [];
  }

  const latestDateParsed = parse(latestDateString, 'yyyy-MM-dd', new Date());
  const NINETY_DAYS_AGO_FROM_LATEST = subDays(latestDateParsed, 90);

  return filterItemsByDateRange({
    items,
    fromDate: format(NINETY_DAYS_AGO_FROM_LATEST, 'yyyy-MM-dd'),
    toDate: format(latestDateParsed, 'yyyy-MM-dd'),
  });
};

const separateAttachmentsFromItems = (
  items: (ICommentData | IRulingData | IDecisionData | IScopingData | IOtherData)[]
) => {
  const attachments = items.filter((item) => item.is_attachment);
  const nonAttachments = items.filter((item) => !item.is_attachment);
  return { attachments, nonAttachments };
};

export const ProceedingTimeline = (props: IProps) => {
  const theme = useTheme();

  const {
    comments: initialComments = [],
    rulings: initialRulings = [],
    decisions: initialDecisions = [],
    scopings: initialScopings = [],
    others: initialOthers = [],
  } = props;
  const timelineRef = useRef(null);

  const LATEST_DATE_FOR_ALL_DOCS = useMemo(() => {
    let latestDate = format(TODAY, 'MM/dd/yyyy');

    const allItems = [
      initialComments,
      initialDecisions,
      initialRulings,
      initialScopings,
      initialOthers,
    ];

    // This assumes that the data is returned in sorted order, ascending
    allItems.forEach((items) => {
      const lastItem = items?.[items.length - 1];
      if (lastItem && lastItem.date > latestDate) {
        latestDate = lastItem.date;
      }
    });

    return latestDate;
  }, [initialComments, initialDecisions, initialRulings, initialScopings, initialOthers]);

  // Filtered data for filings search. Defaults to last 30 days
  const [filteredComments, setFilteredComments] = useState<ICommentData[]>(
    getDefaultFilteredItems(initialComments, LATEST_DATE_FOR_ALL_DOCS) as ICommentData[]
  );
  const [filteredDecisions, setFilteredDecisions] = useState<IDecisionData[]>(
    getDefaultFilteredItems(initialDecisions, LATEST_DATE_FOR_ALL_DOCS) as IDecisionData[]
  );
  const [filteredRulings, setFilteredRulings] = useState<IRulingData[]>(
    getDefaultFilteredItems(initialRulings, LATEST_DATE_FOR_ALL_DOCS) as IRulingData[]
  );
  const [filteredScopings, setFilteredScopings] = useState<IScopingData[]>(
    getDefaultFilteredItems(initialScopings, LATEST_DATE_FOR_ALL_DOCS) as IScopingData[]
  );
  const [filteredOthers, setFilteredOthers] = useState<IOtherData[]>(
    getDefaultFilteredItems(initialOthers, LATEST_DATE_FOR_ALL_DOCS) as IOtherData[]
  );

  const [commentsAttachments, setCommentsAttachments] = useState<ICommentData[]>([]);
  const [commentsNonAttachments, setCommentsNonAttachments] = useState<ICommentData[]>([]);
  const [rulingsAttachments, setRulingsAttachments] = useState<IRulingData[]>([]);
  const [rulingsNonAttachments, setRulingsNonAttachments] = useState<IRulingData[]>([]);
  const [decisionsAttachments, setDecisionsAttachments] = useState<IDecisionData[]>([]);
  const [decisionsNonAttachments, setDecisionsNonAttachments] = useState<IDecisionData[]>([]);
  const [scopingsAttachments, setScopingsAttachments] = useState<IScopingData[]>([]);
  const [scopingsNonAttachments, setScopingsNonAttachments] = useState<IScopingData[]>([]);
  const [othersAttachments, setOthersAttachments] = useState<IOtherData[]>([]);
  const [othersNonAttachments, setOthersNonAttachments] = useState<IOtherData[]>([]);

  useEffect(() => {
    const { attachments, nonAttachments } = separateAttachmentsFromItems(filteredComments);
    setCommentsAttachments(attachments as ICommentData[]);
    setCommentsNonAttachments(nonAttachments as ICommentData[]);

    const { attachments: rulingAttachments, nonAttachments: rulingNonAttachments } =
      separateAttachmentsFromItems(filteredRulings);
    setRulingsAttachments(rulingAttachments as IRulingData[]);
    setRulingsNonAttachments(rulingNonAttachments as IRulingData[]);

    const { attachments: decisionAttachments, nonAttachments: decisionNonAttachments } =
      separateAttachmentsFromItems(filteredDecisions);
    setDecisionsAttachments(decisionAttachments as IDecisionData[]);
    setDecisionsNonAttachments(decisionNonAttachments as IDecisionData[]);

    const { attachments: scopingAttachments, nonAttachments: scopingNonAttachments } =
      separateAttachmentsFromItems(filteredScopings);
    setScopingsAttachments(scopingAttachments as IScopingData[]);
    setScopingsNonAttachments(scopingNonAttachments as IScopingData[]);

    const { attachments: otherAttachments, nonAttachments: otherNonAttachments } =
      separateAttachmentsFromItems(filteredOthers);
    setOthersAttachments(otherAttachments as IOtherData[]);
    setOthersNonAttachments(otherNonAttachments as IOtherData[]);
  }, [filteredComments, filteredRulings, filteredDecisions, filteredScopings, filteredOthers]);

  // From - To dates
  const DEFAULT_FROM_FILING_DATE = format(subDays(LATEST_DATE_FOR_ALL_DOCS, 90), 'MM/dd/yyyy');
  const DEFAULT_TO_FILING_DATE = format(LATEST_DATE_FOR_ALL_DOCS, 'MM/dd/yyyy');
  const [fromFilingDate, setFromFilingDate] = useState(DEFAULT_FROM_FILING_DATE);
  const [toFilingDate, setToFilingDate] = useState(DEFAULT_TO_FILING_DATE);

  // Filed by
  const [filedByInput, setFiledByInput] = useState('');

  // Checkboxes for timeline display
  const [commentsIsChecked, setCommentsIsChecked] = useState(true);
  const [rulingsIsChecked, setRulingsIsChecked] = useState(true);
  const [decisionsIsChecked, setDecisionsIsChecked] = useState(true);
  const [scopingIsChecked, setScopingIsChecked] = useState(true);
  const [othersIsChecked, setOthersIsChecked] = useState(false);

  // Ruling modal state handlers
  const [rulingModalIsOpen, setRulingModalIsOpen] = useState(false);
  const [selectedRuling, setSelectedRuling] = useState<IRulingData | undefined>(undefined);
  const [selectedRulingAttachments, setSelectedRulingAttachments] = useState<
    IRulingData[] | undefined
  >(undefined);

  // Comment modal state handlers
  const [commentsModalIsOpen, setCommentsModalIsOpen] = useState(false);
  const [selectedComments, setSelectedComments] = useState<ICommentData[] | undefined>(undefined);
  const [selectedCommentsAttachments, setSelectedCommentsAttachments] = useState<
    ICommentData[] | undefined
  >(undefined);

  // Scoping modal state handlers
  const [scopingModalIsOpen, setScopingModalIsOpen] = useState(false);
  const [selectedScoping, setSelectedScoping] = useState<IScopingData | undefined>(undefined);
  const [selectedScopingAttachments, setSelectedScopingAttachments] = useState<
    IScopingData[] | undefined
  >(undefined);

  // Decision modal state handlers
  const [decisionModalIsOpen, setDecisionModalIsOpen] = useState(false);
  const [selectedDecision, setSelectedDecision] = useState<IDecisionData | undefined>(undefined);
  const [selectedDecisionAttachments, setSelectedDecisionAttachments] = useState<
    IDecisionData[] | undefined
  >(undefined);

  // Other modal state handlers
  const [otherModalIsOpen, setOtherModalIsOpen] = useState(false);
  const [selectedOther, setSelectedOther] = useState<IOtherData | undefined>(undefined);
  const [selectedOtherAttachments, setSelectedOtherAttachments] = useState<
    IOtherData[] | undefined
  >(undefined);

  // Filings search handlers
  const [currentKeywords, setCurrentKeywords] = useState('');
  const [selectedFilingsSearchOption, setSelectedFilingSearchOption] = useState<string>(
    FilingType.ALL
  );

  const [filingsSearchPanelIsVisible, setFilingsSearchPanelIsVisible] = useState<boolean>(false);

  useEffect(() => {
    let timeline: Timeline;
    const { groups, timelineItems } = getProceedingTimelineItems({
      commentsIsChecked,
      rulingsIsChecked,
      decisionsIsChecked,
      scopingIsChecked,
      othersIsChecked,
      comments: commentsNonAttachments,
      rulings: rulingsNonAttachments,
      decisions: decisionsNonAttachments,
      scopings: scopingsNonAttachments,
      others: othersNonAttachments,
    });

    // Mount timeline component to the DOM once the ref has loaded
    if (timelineRef.current) {
      const options: TimelineOptions = {
        type: 'point',
        showCurrentTime: true,
        horizontalScroll: true,
      };
      timeline = new Timeline(timelineRef.current, timelineItems, groups, options);

      timeline.on('click', (properties) => {
        const itemGroup = properties.group;
        const itemId = properties.item;

        if (!itemId || !itemGroup) {
          return;
        }

        if (itemGroup === CommentGroup.ID) {
          // the itemId is expected to be a stringified array [date, type]
          const [commentDate, commentType] = JSON.parse(itemId);
          // Get all the comments with the same date, type
          const clickedComments = commentsNonAttachments.filter((comment) => {
            const { date, type } = comment;
            return commentDate === date && commentType === type;
          });
          setSelectedComments(clickedComments);
          const clickedCommentsAttachments = commentsAttachments.filter((c) =>
            clickedComments.some((comment) => comment.id === c.parent_doc_id)
          );
          setSelectedCommentsAttachments(clickedCommentsAttachments);
          setCommentsModalIsOpen(true);
        }

        if (itemGroup === RulingGroup.ID) {
          const clickedRuling = initialRulings.find((r) => r.id === itemId);
          setSelectedRuling(clickedRuling);
          const clickedRulingAttachments = rulingsAttachments.filter(
            (r) => r.parent_doc_id === itemId
          );
          setSelectedRulingAttachments(clickedRulingAttachments);
          setRulingModalIsOpen(true);
        }

        if (itemGroup === ScopingGroup.ID) {
          const clickedScoping = initialScopings.find((s) => s.id === itemId);
          setSelectedScoping(clickedScoping);
          const clickedScopingAttachments = scopingsAttachments.filter(
            (s) => s.parent_doc_id === itemId
          );
          setSelectedScopingAttachments(clickedScopingAttachments);
          setScopingModalIsOpen(true);
        }

        if (itemGroup === DecisionGroup.ID) {
          const clickedDecision = initialDecisions.find((d) => d.id === itemId);
          setSelectedDecision(clickedDecision);
          const clickedDecisionAttachments = decisionsAttachments.filter(
            (d) => d.parent_doc_id === itemId
          );
          setSelectedDecisionAttachments(clickedDecisionAttachments);
          setDecisionModalIsOpen(true);
        }

        if (itemGroup === OtherGroup.ID) {
          const clickedOther = initialOthers.find((o) => o.id === itemId);
          setSelectedOther(clickedOther);
          const clickedOtherAttachments = othersAttachments.filter(
            (o) => o.parent_doc_id === itemId
          );
          setSelectedOtherAttachments(clickedOtherAttachments);
          setOtherModalIsOpen(true);
        }
      });
    }

    // Cleanup function to destroy the timeline when the component unmounts
    return () => {
      if (timeline) {
        timeline.destroy();
      }
    };
  }, [
    rulingsIsChecked,
    commentsIsChecked,
    decisionsIsChecked,
    scopingIsChecked,
    othersIsChecked,
    commentsNonAttachments,
    commentsAttachments,
    rulingsNonAttachments,
    rulingsAttachments,
    decisionsNonAttachments,
    decisionsAttachments,
    scopingsNonAttachments,
    scopingsAttachments,
    othersNonAttachments,
    othersAttachments,
    initialComments,
    initialRulings,
    initialScopings,
    initialDecisions,
    initialOthers,
  ]);

  const handleDateFilterButtonClick = (from: Date | string, to: Date | string) => {
    const filterProps = {
      fromDate: format(from, 'yyyy-MM-dd'),
      toDate: format(to, 'yyyy-MM-dd'),
    };
    // TODO generalize this
    setFilteredComments(
      filterItemsByDateRange({
        items: initialComments,
        ...filterProps,
      }) as ICommentData[]
    );
    setFilteredDecisions(
      filterItemsByDateRange({
        items: initialDecisions,
        ...filterProps,
      }) as IDecisionData[]
    );
    setFilteredRulings(
      filterItemsByDateRange({
        items: initialRulings,
        ...filterProps,
      }) as IRulingData[]
    );
    setFilteredScopings(
      filterItemsByDateRange({
        items: initialScopings,
        ...filterProps,
      }) as IScopingData[]
    );
    setFilteredOthers(
      filterItemsByDateRange({
        items: initialOthers,
        ...filterProps,
      }) as IOtherData[]
    );
  };

  const handleTriggerFilingsSearch = () => {
    if (!selectedFilingsSearchOption) {
      return;
    }

    if (!fromFilingDate || !toFilingDate) {
      // For now make sure to/from are filled out
      return;
    }

    // Handle keywords
    let keywordsToFilterBy: string[] = [];
    if (currentKeywords) {
      keywordsToFilterBy = map(split(currentKeywords, ','), trim);
    }

    // Handle to and from dates
    // Dates are in format mm/dd/yyyy, convert to yyyy-MM-dd for comparison
    let fromFilingDateString = '';
    let toFilingDateString = '';
    if (fromFilingDate) {
      const parsedDate = parse(fromFilingDate, 'MM/dd/yyyy', new Date());
      fromFilingDateString = format(parsedDate, 'yyyy-MM-dd');
    }

    if (toFilingDate) {
      const parsedDate = parse(toFilingDate, 'MM/dd/yyyy', new Date());
      toFilingDateString = format(parsedDate, 'yyyy-MM-dd');
    }

    let filedByNamesToFilterBy: string[] = [];
    if (filedByInput) {
      // Lowercase the input
      filedByNamesToFilterBy = map(split(filedByInput, ','), trim);

      filedByNamesToFilterBy = filedByNamesToFilterBy.map((name) => {
        return name ? name.toLowerCase() : '';
      });
    }
    // For the selected filing type, filter out the ones that have the keywords in keywords_v2
    if (
      selectedFilingsSearchOption === FilingType.ALL ||
      selectedFilingsSearchOption === FilingType.COMMENT
    ) {
      let filteredCommentsByKeywords = filterItemsByKeywords({
        items: initialComments,
        keywordsToFilterBy,
      });

      filteredCommentsByKeywords = filterItemsByDateRange({
        items: filteredCommentsByKeywords,
        toDate: toFilingDateString,
        fromDate: fromFilingDateString,
      });

      // If user typed in filed by, then filter by that as well
      if (filedByInput) {
        filteredCommentsByKeywords = filterByFiledBy(
          filteredCommentsByKeywords,
          filedByNamesToFilterBy
        );
      }

      setFilteredComments(filteredCommentsByKeywords as ICommentData[]);
    }

    if (
      selectedFilingsSearchOption === FilingType.ALL ||
      selectedFilingsSearchOption === FilingType.DECISION
    ) {
      let filteredDecisionsByKeywords = filterItemsByKeywords({
        items: initialDecisions,
        keywordsToFilterBy,
      });

      filteredDecisionsByKeywords = filterItemsByDateRange({
        items: filteredDecisionsByKeywords,
        toDate: toFilingDateString,
        fromDate: fromFilingDateString,
      });
      if (filedByInput) {
        filteredDecisionsByKeywords = filterByFiledBy(
          filteredDecisionsByKeywords,
          filedByNamesToFilterBy
        );
      }
      setFilteredDecisions(filteredDecisionsByKeywords as IDecisionData[]);
    }

    if (
      selectedFilingsSearchOption === FilingType.ALL ||
      selectedFilingsSearchOption === FilingType.RULING
    ) {
      let filteredRulingsByKeywords = filterItemsByKeywords({
        items: initialRulings,
        keywordsToFilterBy,
      });

      filteredRulingsByKeywords = filterItemsByDateRange({
        items: filteredRulingsByKeywords,
        toDate: toFilingDateString,
        fromDate: fromFilingDateString,
      });
      if (filedByInput) {
        filteredRulingsByKeywords = filterByFiledBy(
          filteredRulingsByKeywords,
          filedByNamesToFilterBy
        );
      }
      setFilteredRulings(filteredRulingsByKeywords as IRulingData[]);
    }

    if (
      selectedFilingsSearchOption === FilingType.ALL ||
      selectedFilingsSearchOption === FilingType.SCOPING
    ) {
      let filteredScopingsByKeywords = filterItemsByKeywords({
        items: initialScopings,
        keywordsToFilterBy,
      });

      filteredScopingsByKeywords = filterItemsByDateRange({
        items: filteredScopingsByKeywords,
        toDate: toFilingDateString,
        fromDate: fromFilingDateString,
      });
      if (filedByInput) {
        filteredScopingsByKeywords = filterByFiledBy(
          filteredScopingsByKeywords,
          filedByNamesToFilterBy
        );
      }
      setFilteredScopings(filteredScopingsByKeywords as IScopingData[]);
    }
    if (
      selectedFilingsSearchOption === FilingType.ALL ||
      selectedFilingsSearchOption === FilingType.OTHER
    ) {
      let filteredOthersByKeywords = filterItemsByKeywords({
        items: initialOthers,
        keywordsToFilterBy,
      });

      filteredOthersByKeywords = filterItemsByDateRange({
        items: filteredOthersByKeywords,
        toDate: toFilingDateString,
        fromDate: fromFilingDateString,
      });
      if (filedByInput) {
        filteredOthersByKeywords = filterByFiledBy(
          filteredOthersByKeywords,
          filedByNamesToFilterBy
        );
      }
      setFilteredOthers(filteredOthersByKeywords as IOtherData[]);
    }

    const FILING_TYPE_TO_SET_STATE_HANDLER = {
      [FilingType.COMMENT]: setCommentsIsChecked,
      [FilingType.DECISION]: setDecisionsIsChecked,
      [FilingType.RULING]: setRulingsIsChecked,
      [FilingType.SCOPING]: setScopingIsChecked,
      [FilingType.OTHER]: setOthersIsChecked,
    };

    Object.entries(FILING_TYPE_TO_SET_STATE_HANDLER).forEach(
      ([filingType, setCheckboxHandlerFunction]) => {
        // Uncheck all of the timeline options except the one that is selected, if not selecting 'All'
        const isChecked =
          selectedFilingsSearchOption === FilingType.ALL ||
          selectedFilingsSearchOption === filingType;
        setCheckboxHandlerFunction(isChecked);
      }
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleTriggerFilingsSearch();
    }
  };

  return (
    <>
      {selectedScoping?.type === 'application' ? (
        <ProcOtherModal
          modalIsOpen={scopingModalIsOpen}
          setModalIsOpen={setScopingModalIsOpen}
          doc={selectedScoping as IOtherData}
          attachments={selectedScopingAttachments}
        />
      ) : (
        <ProcScopingModal
          modalIsOpen={scopingModalIsOpen}
          setModalIsOpen={setScopingModalIsOpen}
          scoping={selectedScoping}
          attachments={selectedScopingAttachments}
        />
      )}
      <ProcRulingModal
        modalIsOpen={rulingModalIsOpen}
        setModalIsOpen={setRulingModalIsOpen}
        ruling={selectedRuling}
        attachments={selectedRulingAttachments}
      />
      <ProcCommentsModal
        modalIsOpen={commentsModalIsOpen}
        setModalIsOpen={setCommentsModalIsOpen}
        comments={selectedComments}
        attachments={selectedCommentsAttachments}
      />
      <ProcDecisionModal
        modalIsOpen={decisionModalIsOpen}
        setModalIsOpen={setDecisionModalIsOpen}
        decision={selectedDecision}
        attachments={selectedDecisionAttachments}
      />
      <ProcOtherModal
        modalIsOpen={otherModalIsOpen}
        setModalIsOpen={setOtherModalIsOpen}
        doc={selectedOther}
        attachments={selectedOtherAttachments}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between', // Distribute space between the items
          alignItems: 'center', // Center items vertically
          width: '100%', // Full width of the parent
        }}
      >
        <FormGroup row sx={{ marginLeft: '16px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={commentsIsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCommentsIsChecked(event.target.checked);
                }}
              />
            }
            label="Comments"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rulingsIsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setRulingsIsChecked(event.target.checked);
                }}
              />
            }
            label="Rulings"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={decisionsIsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDecisionsIsChecked(event.target.checked);
                }}
              />
            }
            label="Decisions"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={scopingIsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setScopingIsChecked(event.target.checked);
                }}
              />
            }
            label="Scoping"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={othersIsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setOthersIsChecked(event.target.checked);
                }}
              />
            }
            label="Other Filings"
          />
        </FormGroup>
        <TimelineLegend />
      </Box>
      <Box sx={{ height: 18 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left', // Distribute space between the items
          alignItems: 'center', // Center items vertically
          width: '100%', // Full width of the parent
          mx: '26px',
        }}
      >
        <Button
          onClick={() => {
            handleDateFilterButtonClick(ONE_WEEK_AGO, TODAY);
          }}
          variant="outlined"
        >
          Last Week
        </Button>
        <Box sx={{ width: 12 }} />
        <Button
          onClick={() => {
            handleDateFilterButtonClick(ONE_MONTH_AGO, TODAY);
          }}
          variant="outlined"
        >
          Last Month
        </Button>
        <Box sx={{ width: 12 }} />
        <Button
          onClick={() => {
            handleDateFilterButtonClick(THREE_MONTHS_AGO, TODAY);
          }}
          variant="outlined"
        >
          Last 3 Months
        </Button>
        <Box sx={{ width: 12 }} />
        <Button
          onClick={() => {
            handleDateFilterButtonClick(SIX_MONTHS_AGO, TODAY);
          }}
          variant="outlined"
        >
          Last 6 Months
        </Button>
        <Box sx={{ width: 12 }} />
        <Button
          onClick={() => {
            handleDateFilterButtonClick(ONE_YEAR_AGO, TODAY);
          }}
          variant="outlined"
        >
          Last Year
        </Button>
        <Box sx={{ width: 12 }} />
        <Button
          onClick={() => {
            setFilteredComments(initialComments);
            setFilteredDecisions(initialDecisions);
            setFilteredRulings(initialRulings);
            setFilteredScopings(initialScopings);
            setFilteredOthers(initialOthers);
          }}
          variant="outlined"
        >
          All
        </Button>
        <Box sx={{ width: 12 }} />
        <Typography
          fontSize="14px"
          color="#5a2544"
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontWeight: 'bold',
          }}
          onClick={() => {
            setFilingsSearchPanelIsVisible(!filingsSearchPanelIsVisible);
          }}
        >
          More options
        </Typography>
      </Box>
      {filingsSearchPanelIsVisible && (
        <Box sx={{ mx: 3 }}>
          <Box sx={{ height: 24 }} />
          <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Card>
                <Typography
                  sx={{ fontWeight: 'bold', color: theme.palette.primary.main, fontSize: '18px' }}
                >
                  Filings search
                </Typography>
                <Box sx={{ height: 24 }} />
                <Grid container spacing={1}>
                  <Grid item sm={2}>
                    <InputLabel>Filing Type</InputLabel>
                    <Select
                      size="small"
                      labelId="filings-search-opts"
                      id="filings-search-opts"
                      value={selectedFilingsSearchOption}
                      onChange={(e) => setSelectedFilingSearchOption(e.target.value)}
                    >
                      <MenuItem value={FilingType.COMMENT}>Comments</MenuItem>
                      <MenuItem value={FilingType.RULING}>Rulings</MenuItem>
                      <MenuItem value={FilingType.DECISION}>Decisions</MenuItem>
                      <MenuItem value={FilingType.SCOPING}>Scoping</MenuItem>
                      <MenuItem value={FilingType.OTHER}>Other Filings</MenuItem>
                      <MenuItem value={FilingType.ALL}>All</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item sm={2}>
                    <InputLabel>Keywords</InputLabel>
                    <TextField
                      onChange={(e) => {
                        setCurrentKeywords(e.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                      size="small"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                      value={currentKeywords}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <InputLabel>Filed By</InputLabel>
                    <TextField
                      onChange={(e) => {
                        setFiledByInput(e.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                      size="small"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                      value={filedByInput}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <InputLabel>From</InputLabel>
                    <TextField
                      onChange={(e) => {
                        setFromFilingDate(e.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                      size="small"
                      variant="outlined"
                      placeholder="MM/DD/YYYY"
                      fullWidth
                      defaultValue={fromFilingDate}
                      value={fromFilingDate}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <InputLabel>To</InputLabel>
                    <TextField
                      onChange={(e) => {
                        setToFilingDate(e.target.value);
                      }}
                      onKeyDown={handleKeyDown}
                      size="small"
                      variant="outlined"
                      placeholder="MM/DD/YYYY"
                      fullWidth
                      defaultValue={toFilingDate}
                      value={toFilingDate}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <Button
                      onClick={() => handleTriggerFilingsSearch()}
                      sx={{ marginTop: '20px' }}
                      variant="outlined"
                    >
                      Search
                    </Button>

                    <Button
                      onClick={() => {
                        // Reset all the fields in the filing search
                        setCurrentKeywords('');
                        setFiledByInput('');
                        setFromFilingDate(DEFAULT_FROM_FILING_DATE);
                        setToFilingDate(DEFAULT_TO_FILING_DATE);

                        // Reset all the filtered items shown in the timeline
                        setFilteredComments(
                          getDefaultFilteredItems(
                            initialComments,
                            LATEST_DATE_FOR_ALL_DOCS
                          ) as ICommentData[]
                        );
                        setFilteredDecisions(
                          getDefaultFilteredItems(
                            initialDecisions,
                            LATEST_DATE_FOR_ALL_DOCS
                          ) as IDecisionData[]
                        );
                        setFilteredRulings(
                          getDefaultFilteredItems(
                            initialRulings,
                            LATEST_DATE_FOR_ALL_DOCS
                          ) as IRulingData[]
                        );
                        setFilteredScopings(
                          getDefaultFilteredItems(
                            initialScopings,
                            LATEST_DATE_FOR_ALL_DOCS
                          ) as IScopingData[]
                        );
                        setFilteredOthers(
                          getDefaultFilteredItems(
                            initialOthers,
                            LATEST_DATE_FOR_ALL_DOCS
                          ) as IOtherData[]
                        );

                        // Show everything in the timeline
                        [
                          setCommentsIsChecked,
                          setDecisionsIsChecked,
                          setRulingsIsChecked,
                          setScopingIsChecked,
                          setOthersIsChecked,
                        ].forEach((setDataIsCheckedHandler) => {
                          setDataIsCheckedHandler(true);
                        });
                      }}
                      sx={{ marginTop: '20px', marginLeft: '5px' }}
                      variant="outlined"
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
      <Box sx={{ height: 12 }} />
      <Box ref={timelineRef} sx={{ mx: '26px' }} />
    </>
  );
};
