/* eslint-disable no-console */
import { useQuery } from 'react-query';

import { apiClient } from '../../lib/apiClient';
import { ApiError } from '../../lib/types';

interface ITagsListResponseObject {
  placeholder: string;
}

export const useGetAllTagsQuery = () => {
  const getAllTagsUrl = `/api/v1/tags`;
  return useQuery<ITagsListResponseObject[], ApiError>([getAllTagsUrl], {
    enabled: true,
  });
};

export const updateTagsForBookmark = async (tags: string[], bookmarkId: number) => {
  return apiClient.put('/api/v1/tags', {
    json: {
      tags,
      bookmark_id: bookmarkId,
    },
  });
};
