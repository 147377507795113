import { Box, Card, Tab, Tabs, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useState } from 'react';

function SanitizedHTML({ html }: { html: string }) {
  const sanitizedHTML = DOMPurify.sanitize(html);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
}

interface ITabPanelProps {
  value: number;
  index: number;
  children: React.ReactNode;
}

function TabPanel(props: ITabPanelProps) {
  const { value, index, children } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface IProps {
  conciseSummary: string;
  mediumSummary: string;
  longSummary: string;
}

export const BillSummaryPanel = (props: IProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { conciseSummary, mediumSummary, longSummary } = props;

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={(_, newIndex) => setTabIndex(newIndex)}
        aria-label="bill summaries"
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label="short" sx={{ textTransform: 'lowercase' }} />
        <Tab label="medium" sx={{ textTransform: 'lowercase' }} />
        <Tab label="long" sx={{ textTransform: 'lowercase' }} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Card>
          <Typography fontWeight="bold" variant="h6">
            Short Summary
          </Typography>
          <Box sx={{ height: 6 }} />
          <SanitizedHTML html={conciseSummary} />
        </Card>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Card>
          <Typography fontWeight="bold" variant="h6">
            Medium Summary
          </Typography>
          <Box sx={{ height: 6 }} />
          <SanitizedHTML html={mediumSummary} />
        </Card>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <Card>
          <Typography fontWeight="bold" variant="h6">
            Long Summary
          </Typography>
          <Box sx={{ height: 12 }} />
          <SanitizedHTML html={longSummary} />
        </Card>
      </TabPanel>
    </>
  );
};
