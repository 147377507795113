import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import {
  CURRENT_STEP_INDEX,
  ICommentData,
  IDecisionData,
  IOtherData,
  IRulingData,
  IScopingData,
} from './types';
import { ApiError } from '../../lib/types';

// Individual object from /api/v1/proceedings
export interface IProceedingListResponseObject {
  number: string;
  description: string;
  progress: number;
  filed_by: string;
  active: boolean;
  scoping_count: number;
  decisions_count: number;
  rulings_count: number;
  comments_count: number;
}

interface IProceedingDetails {
  service_lists_link: string;
  current_status: string;
  description: string;
  filing_date: string; // datetime
  staff: string; // todo this should be a list of names
  category: string;
  link: string;
}

interface IProceedingLatestSummariesData {
  short: string;
  long: string;
}
interface IProceedingLatestFilingData {
  date: string;
  doc_type: string;
  summaries: IProceedingLatestSummariesData;
}

interface IProceedingData {
  number: string;
  purpose: string;
  headline: string;
  progress_step_index: CURRENT_STEP_INDEX;
  latest_filing: IProceedingLatestFilingData;
  details: IProceedingDetails;
  decisions: IDecisionData[];
  rulings: IRulingData[];
  comments: ICommentData[];
  scopings: IScopingData[];
  others: IOtherData[];
}

export const useProceedingsQuery = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryString = searchParams.toString();

  let getProceedingsUrl = `/api/v1/proceedings`;
  if (queryString) {
    getProceedingsUrl += `?${queryString}`;
  }

  return useQuery<IProceedingListResponseObject[], ApiError>([getProceedingsUrl], {
    enabled: false,
  });
};

export const useProceedingsByProcNumberQuery = (proceedingNumber: string | undefined) => {
  const PROCEEDING_URL = `/api/v1/proceedings/${proceedingNumber}`;
  return useQuery<IProceedingData, ApiError>([PROCEEDING_URL], {
    enabled: Boolean(proceedingNumber),
  });
};
