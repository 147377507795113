import DOMPurify from 'dompurify';

interface IProps {
  html: string;
  replaceNewLine?: boolean;
}
export const SanitizedHTML = (props: IProps) => {
  const { html, replaceNewLine } = props;
  const sanitizedHTML = DOMPurify.sanitize(replaceNewLine ? html.replace(/\n/g, '<br>') : html);
  return <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
};
