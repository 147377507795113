import { Alert, Box, Button, Card, Grid, InputLabel, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthContext } from './AuthContext';

export const SignInPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showSignInError, setShowSignInError] = useState(false);
  const { isAuthenticated } = useAuthContext();
  const { signIn, sessionIsInitializing } = useAuthContext();

  if (sessionIsInitializing) {
    return null;
  }

  if (isAuthenticated) {
    const queryParams = new URLSearchParams(window.location.search);
    const nextUrlPath = queryParams.get('nextUrl');

    if (nextUrlPath) {
      return <Navigate to={nextUrlPath} replace />;
    }

    return <Navigate to="/" replace />;
  }

  return (
    <Grid container justifyContent="center" sx={{ marginTop: 3 }}>
      <Card sx={{ backgroundColor: 'transparent' }}>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              await signIn({
                email: username,
                password,
              });
            } catch {
              setShowSignInError(true);
            }
          }}
        >
          <Typography sx={{ color: '#5a2544', fontWeight: 600, fontSize: '24px' }} variant="h3">
            Login
          </Typography>
          <Box sx={{ height: 14 }} />
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <InputLabel>Email</InputLabel>
              <TextField
                size="small"
                fullWidth
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Password</InputLabel>
              <TextField
                size="small"
                fullWidth
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            {showSignInError && (
              <Grid item xs={12}>
                <Alert severity="error">Could not sign in.</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button type="submit" variant="outlined" color="primary">
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Grid>
  );
};
