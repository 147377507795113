import { Snackbar } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

interface ISnackbarProps {
  snackbarTextColor: string;
  snackbarMessage: string;
  snackbarBackgroundColor: string;
  snackbarIsOpen: boolean;
}

interface IProps extends ISnackbarProps {
  setSnackbarProps: Dispatch<SetStateAction<ISnackbarProps>>;
}

export const BookmarkSnackbar = (props: IProps) => {
  const {
    snackbarTextColor,
    snackbarMessage,
    snackbarBackgroundColor,
    snackbarIsOpen,
    setSnackbarProps,
  } = props;
  return (
    <Snackbar
      sx={{
        '& .MuiSnackbarContent-root': {
          backgroundColor: snackbarBackgroundColor,
          color: snackbarTextColor,
        },
      }}
      autoHideDuration={2500}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={snackbarIsOpen}
      onClose={(_, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        setSnackbarProps((prevState) => {
          return {
            ...prevState,
            snackbarIsOpen: false,
          };
        });
      }}
      message={snackbarMessage}
    />
  );
};
