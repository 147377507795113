import { Box, Typography } from '@mui/material';

interface IProps {
  value: number;
  index: number;
  children: React.ReactNode;
}
export const TabPanel = (props: IProps) => {
  const { value, index, children } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
