import { Box, Typography } from '@mui/material';
import React from 'react';

export const SubmittedDocNotice = () => {
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography fontWeight="bold" variant="h6" bgcolor="yellow">
          NOTE: This document is SUBMITTED and not yet officially filed.
        </Typography>
      </Box>
      <Box sx={{ height: 20 }} />
    </>
  );
};
