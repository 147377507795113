import constate from 'constate';

import { IAllBookmarkedData, ITeamBookmarkData } from './types';

interface IBookmarkProviderProps {
  bookmarkData: IAllBookmarkedData | undefined;
  teamBookmarkData: ITeamBookmarkData | undefined;
}

export function useBookmarkState(props: IBookmarkProviderProps) {
  const {
    bookmarkData,
    teamBookmarkData = { team_member_emails: [], bookmarks_by_team_member: {} },
  } = props;

  return {
    bookmarkedBills: bookmarkData?.bookmarked_bills,
    bookmarkedProceedings: bookmarkData?.bookmarked_proceedings,
    teamBookmarkData,
  };
}

export const [BookmarkProvider, useBookmarkContext] = constate(useBookmarkState);
